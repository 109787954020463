import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography, CircularProgress } from '@material-ui/core';
import { modalFormTheme } from '../Layout/Theme.component';
import { modalFormStyle } from '../Layout/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '558px',
		height: '359px',
		margin: '21px',
	},
	smContainer: {
		width: '558px',
		margin: '21px',
	},
	smContentContainer: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '100%',
		height: '75%',
	},
	contentContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '100%',
		height: '75%',
	},
	confirmationContext: {
		fontFamily: 'Manrope-medium',
		fontSize: '20px !important',
		color: '#292929',
		marginBottom: '15px',
	},
	confirmationMessageText: {
		fontFamily: 'Manrope-medium',
		fontSize: '14px !important',
		color: '#5C5C5C',
	},
	buttonAlignment: {
		marginTop: '30px',
	},
	submitButton: {
		width: 'auto',
		maxWidth: '150px',
		minWidth: '126px',
		height: '36px',
		background: '#3FA543 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #3FA54340',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#3FA543',
		},
	},
	cancelButton: {
		minWidth: '126px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		marginRight: '10px',
		textTransform: 'none',
	},
	warningSubmitButton: {
		width: 'auto',
		maxWidth: '150px',
		minWidth: '126px',
		height: '36px',
		background: '#E36666 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #F442423B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#ED3D3D',
		},
	},
}));

interface ConfirmationModalProps {
	onClose: () => void;
	open: boolean;
	onSubmit: () => void;
	confirmationMessage?: string;
	confirmationContext?: string;
	heading?: string;
	submitButtonLabel?: string;
	isWarningConfirmation?: boolean;
	loading?: boolean;
	cancelButtonLabel?: string;
	size?: string;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
	const {
		open,
		onClose,
		onSubmit,
		confirmationMessage,
		confirmationContext,
		submitButtonLabel,
		isWarningConfirmation = false,
		loading,
		cancelButtonLabel = 'Cancel',
		size = 'lg',
	} = props;
	const classes = modalFormStyle();
	const confirmationModalStyles = useStyles();

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box
					className={
						size === 'lg'
							? confirmationModalStyles.container
							: confirmationModalStyles.smContainer
					}
				>
					<Box
						className={
							size === 'lg'
								? confirmationModalStyles.contentContainer
								: confirmationModalStyles.smContentContainer
						}
					>
						<Typography className={confirmationModalStyles.confirmationContext}>
							{confirmationContext ? confirmationContext : 'Are you sure?'}
						</Typography>
						<Typography
							className={confirmationModalStyles.confirmationMessageText}
						>
							{confirmationMessage
								? confirmationMessage
								: 'Are you sure to continue this process?'}
						</Typography>
					</Box>
					<Box className={confirmationModalStyles.buttonAlignment}>
						<Box className={classes.buttonContainer}>
							<Button
								onClick={onClose}
								className={confirmationModalStyles.cancelButton}
							>
								{cancelButtonLabel || 'Cancel'}
							</Button>
							<Button
								variant='contained'
								className={
									isWarningConfirmation
										? confirmationModalStyles.warningSubmitButton
										: confirmationModalStyles.submitButton
								}
								disabled={loading}
								onClick={onSubmit}
								type='submit'
								startIcon={
									loading && (
										<CircularProgress
											style={{
												height: '20px',
												width: '20px',
												color: '#FFFFFF',
											}}
										/>
									)
								}
							>
								{submitButtonLabel ? submitButtonLabel : 'Yes! Submit'}
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ConfirmationModal;
