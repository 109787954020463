import {
	makeStyles,
} from '@material-ui/core';
export const autocompleteMultiSelectLabelStyle = makeStyles(({
	label : {
		margin : '10px 0 0 14px !important'
	}
}))


export const endDateExtensionStyles =  makeStyles({
	card: {
		width: '400px',
		padding: '20px',
		borderRadius: '8px',
		boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
		textAlign: 'center',
		backgroundColor: '#fff',
		fontFamily: 'Arial, sans-serif',
	},
	iconContainer: {
		backgroundColor: '#d61d1d', // Green background
		borderRadius: '50%',
		width: '50px',
		height: '50px',
		margin: '0 auto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		color: '#fff', // White checkmark
		fontSize: '24px',
	},
	title: {
		fontSize: '24px',
		color: '#f70a0a',
		margin: '20px 0 10px',
	},
	description: {
		fontSize: '14px',
		color: '#000000',
		marginBottom: '20px',
	},
	saveButton: {
		minWidth: '80px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4 0% 0% no-repeat padding-box',
		},
	},
	cancelButton: {
		minWidth: '80px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		marginRight: '10px',
		textTransform: 'none',
	},
	disabledButton: {
		minWidth: '80px',
		height: '36px',
		background: 'lightgrey 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: 'lightgrey 0% 0% no-repeat padding-box',
		},
	},
	editButtonContainer: {
		position: 'absolute',
		right: '6px',
		top: '6px',
		opacity: 1,
		borderRadius: '100px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '24px',
		height: '24px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover ': {
			background: '#4285F4',
			'& $editIcon': {
				color: '#FFFFFF',
			},
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	errorContainer: {
		marginTop: '15px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		padding: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope',
		color: 'red',
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '200px',
		overflowY: 'scroll',
	},
});
