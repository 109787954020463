import React, { useState, useContext } from 'react';
import { useNotify } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { modalFormTheme } from '../../Layout/Theme.component';
import {
	Button,
	Box,
	Dialog,
	Typography,
	ThemeProvider,
	CircularProgress,
} from '@material-ui/core';
import { UserProfileContext } from '../../App';
import dayjs from 'dayjs';
import { CustomDateInput } from '../../SharedComponents/CustomDateInput.component';
import { useUpdateProjectEndDateMutation } from '../../generated/graphql';
import { isEmpty } from 'lodash';
import { END_DATE_SELECTION_INFO, END_DATE_WARNING_MESSAGE } from './constant';
import { endDateExtensionStyles } from './style';


interface Props {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	projectIds: string[];
	onDateChange: (value: string | null) => void;
	minProjectStartDate: string | null;
	selectedProjectDetails: any[];
}

const ProjectEndDateForm = (props: Props) => {
	const {
		open,
		onClose,
		onSuccess,
		projectIds,
		onDateChange,
		minProjectStartDate,
		selectedProjectDetails,
	} = props;
	const { dateFormat } = useContext<any>(UserProfileContext);
	const notify = useNotify();
	const [errors, setErrors] = useState<string[]>([]);
	const styles = endDateExtensionStyles();
	const [updateEndDate, { loading: isEndDateUpdateLoading }] =
		useUpdateProjectEndDateMutation();
	const handleClose = () => {
		setErrors([]);
		onClose();
	};
	const handleSubmit = (formValues: any) => {
		updateEndDate({
			variables: {
				where: { id: { _in: projectIds } },
				_set: { end_date: formValues?.end_date },
			},
		})
			.then((res) => {
				if (!res.errors) {
					notify('Updated Successfully...');
					handleClose();
					onSuccess();
				}
			})
			.catch((err) => {
				notify('Updation failed');
			});
	};
	const ProjectWarning = () => {
		return (
			<>
				<Typography className={styles.description}>
					You are about to change the <strong>End date</strong> of
					{` ${projectIds?.length || 0} `} project(s)!
				</Typography>
			</>
		);
	};

	const checkProjectEndDate = (date: string | null) => {
		setErrors([]);
		const projectListWithErrorEndDates = selectedProjectDetails
			?.filter((project) => dayjs(date).isBefore(dayjs(project?.startDate)))
			.map((project) => `${END_DATE_WARNING_MESSAGE}"${project?.name}"`);
		setErrors(projectListWithErrorEndDates || []);
	};
	return (
		<Dialog open={open} onClose={handleClose} disableBackdropClick>
			<Box className={styles.card}>
				<h2 className={styles.title}>Info!</h2>
				{<ProjectWarning />}
				<Typography className={styles.description}>
					{END_DATE_SELECTION_INFO}
				</Typography>
				<ThemeProvider theme={modalFormTheme}>
					<Box>
						<Form onSubmit={handleSubmit}>
							{({ handleSubmit, invalid, values, form }) => (
								<form
									onSubmit={handleSubmit}
									onChange={() => {
										setErrors([]);
									}}
								>
									<Box>
										<Box display='flex' width='100%' justifyContent={'center'}>
											<Box>
												<Field
													name='end_date'
													validate={(value) => {
														if (!value) return 'Invalid Date';
														if (dayjs(value).isValid() === true) {
															return undefined;
														} else {
															return 'Invalid Date';
														}
													}}
												>
													{(props) => (
														<Box width='160px'>
															<CustomDateInput
																minDate={minProjectStartDate}
																name={props.input.name}
																initialValue={props.input.value || null}
																onChange={(value) => {
																	if (!value) {
																		return;
																	}
																	props.input.onChange(value);
																	checkProjectEndDate(
																		dayjs(value).format('YYYY-MM-DD')
																	);
																	onDateChange(
																		dayjs(value).format('YYYY-MM-DD') || null
																	);
																}}
																dateFormat={dateFormat}
																isLeaveAndHolidaysEnabled={true}
															/>
														</Box>
													)}
												</Field>
											</Box>
										</Box>
										<Box mt={2}>
											<Button
												onClick={() => handleClose()}
												className={`${styles.cancelButton}`}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid || isEndDateUpdateLoading || !isEmpty(errors)
														? `${styles.disabledButton}`
														: `${styles.saveButton}`
												}
												disabled={
													invalid || isEndDateUpdateLoading || !isEmpty(errors)
												}
												type='submit'
												startIcon={
													isEndDateUpdateLoading && (
														<CircularProgress
															style={{
																height: '20px',
																width: '20px',
																color: '#4285F4',
															}}
														/>
													)
												}
											>
												Update
											</Button>
										</Box>
									</Box>
								</form>
							)}
						</Form>
						{errors.length > 0 && (
							<>
								<div className={styles.errorContainer}>
									{errors.length > 0 &&
										errors.map((value, i) => <div key={i}>{value}</div>)}
								</div>
							</>
						)}
					</Box>
				</ThemeProvider>
			</Box>
		</Dialog>
	);
};

export default ProjectEndDateForm;
