import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	Checkbox,
	IconButton,
	InputAdornment,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Theme,
	ThemeProvider,
	Tooltip,
	Typography,
	makeStyles,
	TextField as TextComponent,
	CardHeader,
	CircularProgress,
	TextField,
} from '@material-ui/core';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ErrorTooltip, dateTimePickerTheme } from '../Style';

import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import {
	useGetAbsenteeReportQuery,
	useGetActiveUsersByLimitQuery,
	useGetLocationsByOrgIdQuery,
	useGetOrgLeaveTypesQuery,
} from '../../../generated/graphql';
import dayjs from 'dayjs';
import { head, isEmpty, orderBy } from 'lodash';
import { Form } from 'react-final-form';
import {
	InsightReportTableGlobalStyles,
	autocompleteStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import { CustomTableCell } from '../../../SharedComponents/CustomTableCell.component';
import { UserProfileContext } from '../../../App';
import {
	exportToCsv,
	formatEmployeeId,
	getPrefix,
} from '../../../Utils/string.util';
import isBetween from 'dayjs/plugin/isBetween';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
	ABSENTEE_REPORT_STATUS_DROPDOWN_OPTIONS,
	START_DATE_ERROR,
} from '../Constant';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';
import { getAllDatesInRange } from '../../../Utils/date-time.util';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: '17px',
		marginTop: '10px',
	},
	autoCompleteInputContainer: {
		minWidth: '170px',
		marginRight: '8px',
	},
	exportButtonDiv: {
		marginTop: '25px',
		marginLeft: '8px',
	},
	exportButton: {
		marginTop: '24px',
		marginRight: '10px',
		marginLeft: '10px',
		minWidth: '60px',
		height: '34px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		marginTop: '24px',
		marginRight: '10px',
		marginLeft: '10px',
		minWidth: '60px',
		height: '34px',
		background: 'lightgrey 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '75px',
	},
	nameCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '100px',
	},
	startDate: {
		width: '188px',
		marginLeft: '20px',
		marginTop: '14px',
	},
	endDate: {
		width: '188px',
		marginTop: '14px',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
		marginTop: '-2px',
	},
	editIcon: {
		width: '12px',
		height: '12px',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '50px',
	},
	tableContainer: {
		maxHeight: '400px',
		overflowY: 'auto',
		minHeight: '200px',
	},
	loading: {
		fontSize: '17px',
		fontFamily: 'Manrope-medium',
		marginRight: '5px',
	},
}));
interface option {
	id: any;
	name?: String | null | undefined;
}
interface Attendance {
	empId: string;
	resourceName: string | null | undefined;
	email: string;
	location: string;
	type: string;
	date: any;
	hours: string;
	leaveStatus: string | undefined;
	leaveReason: string | undefined;
	userType: string | undefined;
}
export const AbsenteeReport = () => {
	dayjs.extend(isBetween);
	const {
		orgId: organizationId,
		dateFormat,
		noOfLocations,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		attendanceConsiderableTimesheetStatus,
	} = useContext<any>(UserProfileContext);

	const [filterStartDate, setFilterStartDate] = useState<string>(
		dayjs().subtract(1, 'day').format('YYYY-MM-DD')
	);
	const [filterEndDate, setFilterEndDate] = useState<string>(
		dayjs().subtract(1, 'day').format('YYYY-MM-DD')
	);
	const [attendanceTableList, setAttendanceTableList] = useState<
		any[] | undefined
	>();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<option[]>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<
		String[]
	>([]);
	const [resourceList, setresourceList] = useState<option[]>([]);
	const [startDateError, setStartDateError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [selectedLocationOptionValue, setSelectedLocationOptionValue] =
		useState<option[]>([]);
	const [selectedLocationIdList, setSelectedLocationIdList] = useState<
		String[]
	>([]);
	const [locationDropDown, setLocationDropDown] = useState<option[]>([]);

	const [selectedLeaveTypeOptionValue, setSelectedLeaveTypeOptionValue] =
		useState<option[]>([]);

	const [selectedLeaveTypeIdList, setSelectedLeaveTypeIdList] = useState<
		String[]
	>([]);
	const [leaveTypeDropDown, setLeaveTypeDropDown] = useState<option[]>([]);

	const [selectedStatusTypeOptionValue, setSelectedStatusTypeOptionValue] =
		useState<option[]>([
			{ id: 'All', name: 'All' },
			...ABSENTEE_REPORT_STATUS_DROPDOWN_OPTIONS,
		]);
	const statusDropDown = ABSENTEE_REPORT_STATUS_DROPDOWN_OPTIONS;
	const ellipsis = ellipsisStyle();
	const classes = useStyles();
	const styles = modalFormStyle();
	const dropDownStyle = autocompleteStyle();
	const commonStyle = InsightReportTableGlobalStyles();

	const { data: resource } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});

	const { data: orgLocations } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
		fetchPolicy: 'network-only',
	});
	const { data: orgLeaveTypeList } = useGetOrgLeaveTypesQuery({
		fetchPolicy: 'network-only',
	});
	// Function to create the location filter for the query based on the filters
	const getLocationFilter = () => {
		if (noOfLocations && noOfLocations > 0) {
			if (isEmpty(selectedLocationOptionValue)) {
				return [];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue?.find((option) => option?.id === 'All')
			) {
				return [{}];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _is_null: true },
						},
					},
					{
						contractors: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
					{
						contractors: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},
					{
						contractors: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},
					{
						employee: {
							location_id: { _is_null: true },
						},
					},
					{
						contractors: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
					{
						contractors: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
		}
		return [{}];
	};

	const getLeaveTypeFilter = () => {
		if (isEmpty(selectedLeaveTypeOptionValue)) {
			return {};
		}
		if (
			selectedLeaveTypeOptionValue &&
			selectedLeaveTypeOptionValue?.find((option) => option?.id === 'All')
		) {
			return {};
		}
		return {
			leave_type_id: { _in: selectedLeaveTypeIdList },
		};
	};

	const { data: employeeAttendanceData, loading } = useGetAbsenteeReportQuery({
		variables: {
			attendance: {
				_and: [
					{ date: { _gte: filterStartDate, _lte: filterEndDate } },
					{ in_time: { _is_null: false } },
					{ out_time: { _is_null: false } },
				],
			},
			leave: {
				_and: [
					getLeaveTypeFilter(),
					{
						_or: [
							{ from_date: { _gte: filterStartDate, _lte: filterEndDate } },
							{ to_date: { _gte: filterStartDate, _lte: filterEndDate } },
							{
								_and: [
									{ from_date: { _lte: filterStartDate } },
									{ to_date: { _gte: filterEndDate } },
								],
							},
						],
					},
				],
			},
			timesheet: {
				date: { _gte: filterStartDate, _lte: filterEndDate },
				working_hours: { _is_null: false },
				timesheet_submission: {
					timesheet_status: {
						value: { _in: attendanceConsiderableTimesheetStatus },
					},
				},
			},
			user: {
				id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: { _in: [] },
				is_active: { _eq: true },
				_or: getLocationFilter(),
			},
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (isEmpty(orgLeaveTypeList?.org_leave_types)) {
			return;
		}
		const optionList = orgLeaveTypeList?.org_leave_types?.map((value) => {
			return {
				id: value?.id || '--',
				name: `${value?.leave_type} (${value?.org_leave_group?.label})` || '--',
			};
		});
		const sortedLeaveTypeOption = orderBy(optionList, ['name'], ['asc']);
		setLeaveTypeDropDown([...(sortedLeaveTypeOption || [])]);
	}, [orgLeaveTypeList]);

	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getLeaveTypeFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedLeaveTypeOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getStatusTypeFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedStatusTypeOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getStatusFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedLocationOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getFormattedEmpId = useCallback(
		(
			id: string | number | null | undefined,
			prefixName: string | undefined,
			prefixValue: string | null | undefined
		) => {
			const prefix = prefixValue
				? getPrefix(prefixName, prefixValue) || ''
				: '';
			return `${prefix}${
				formatEmployeeId(id || '', empIdLength, isFixedEmpIdLengthEnabled) ||
				'--'
			}`;
		},
		[empIdLength, isFixedEmpIdLengthEnabled]
	);
	useEffect(() => {
		if (
			!employeeAttendanceData?.user ||
			isEmpty(selectedStatusTypeOptionValue)
		) {
			setAttendanceTableList([]);
			return;
		}
		setIsLoading(true);
		const dateArray = getAllDatesInRange(filterStartDate, filterEndDate);
		const users = employeeAttendanceData?.user || [];
		const timesheetArray = employeeAttendanceData?.timesheet || [];
		const leaveArray = employeeAttendanceData?.employee_leaving_mapping || [];
		const attendanceArray = employeeAttendanceData?.attendance || [];
		const isAbsentOptionAvailable = selectedStatusTypeOptionValue.filter(
			(option) => option.id === 'absent'
		);
		const isLeaveOptionAvailable = selectedStatusTypeOptionValue.filter(
			(option) => option.id === 'leave'
		);
		const formattedUsers = users.map((user) => {
			const isContractor = user?.user_type === 'contract_employee';
			const employee = user?.employee || {};
			const userType = isContractor ? 'Contractor' : 'Employee';
			const contractor = head(user?.contractors) || {};
			const employeeId = isContractor
				? contractor.contractor_id
					? getFormattedEmpId(
							contractor.contractor_id,
							contractor.contractor_prefix?.name,
							contractor.contractor_prefix?.name
					  )
					: '--'
				: employee.emp_id
				? getFormattedEmpId(
						employee.emp_id,
						employee.employee_prefix?.name,
						contractor.contractor_prefix?.name
				  )
				: '--';

			const department = isContractor
				? contractor.department?.name || '--'
				: employee.department?.name || '--';
			const location = isContractor
				? contractor.org_location?.name || '--'
				: employee.org_location?.name || '--';
			const reportingTo = isContractor
				? contractor.reportee?.full_name || '--'
				: employee.reportsTo?.full_name || '--';
			const reportingToEmail = isContractor
				? contractor.reportee?.email || '--'
				: employee.reportsTo?.email || '--';

			const leaveList = leaveArray
				.filter((leave) => leave.employee?.user?.id === user.id)
				.flatMap((leave) =>
					getAllDatesInRange(leave?.from_date, leave?.to_date).map((date) => ({
						date,
						name: leave?.org_leave_type?.leave_type,
					}))
				);

			const attendanceAbsentList = attendanceArray
				.filter((attendance) => attendance.user?.id === user.id)
				.map((attendance) => attendance.date);

			const timesheetList = timesheetArray
				.filter((timesheet) => timesheet.user_id === user.id)
				.map((timesheet) => timesheet.date);

			const userAttendanceData = dateArray
				.map((date) => {
					const leave = leaveList.find((leaveItem) => leaveItem.date === date);
					if (leave && !isEmpty(isLeaveOptionAvailable)) {
						return {
							empId: employeeId,
							resource: user.full_name,
							resourceEmail: user?.email,
							userType: userType,
							department,
							type: leave?.name,
							date: date ? dayjs(date).format(dateFormat) : '--',
							location,
							reportingTo,
							reportingToEmail,
						};
					}
					if (
						!isEmpty(isAbsentOptionAvailable) &&
						!attendanceAbsentList.includes(date) &&
						!timesheetList.includes(date)
					) {
						return {
							empId: employeeId || '--',
							resource: user.full_name || '--',
							resourceEmail: user?.email || '--',
							userType: userType || '--',
							department,
							type: 'Absent',
							date: date ? dayjs(date).format(dateFormat) : '--',
							location,
							reportingTo,
							reportingToEmail,
						};
					}
					return null;
				})
				.filter(Boolean); // Remove null values

			return userAttendanceData;
		});

		setAttendanceTableList(formattedUsers.flat());
		setIsLoading(false);
	}, [
		employeeAttendanceData,
		filterStartDate,
		filterEndDate,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		getFormattedEmpId,
		selectedStatusTypeOptionValue,
		dateFormat
	]);

	useEffect(() => {
		if (!resource) {
			return;
		}
		const response = resource?.user?.map((user) => {
			return {
				id: user?.id,
				name: user?.name,
			};
		});
		const defaultResourceIdList = response?.map(
			(resource: { id: string }) => resource?.id
		);
		setresourceList(response);
		setSelectedResourceIdList(defaultResourceIdList);
		setSelectedResourceOptionValues([{ id: 'All', name: 'All' }, ...response]);
	}, [resource]);

	useEffect(() => {
		if (isEmpty(orgLocations?.org_location)) {
			return;
		}
		const locationOptionList = orgLocations?.org_location?.map((value) => {
			return {
				id: value?.id || '--',
				name: value?.name || '--',
			};
		});
		const defaultLocationIdList = locationOptionList?.map(
			(resource: { id: string; name: string }) => resource?.id
		);
		const sortedLocationOption = orderBy(locationOptionList, ['name'], ['asc']);

		setSelectedLocationIdList(defaultLocationIdList || []);
		setSelectedLocationOptionValue([
			{ id: 'All', name: 'All' },
			...(locationOptionList || []),
			{ id: 'Unassigned', name: 'Unassigned' },
		]);
		setLocationDropDown([
			...(sortedLocationOption || []),
			{ id: 'Unassigned', name: 'Unassigned' },
		]);
	}, [orgLocations]);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const generateExport = () => {
		if (isEmpty(attendanceTableList)) {
			return;
		}
		const columnHeadings = [
			'RESOURCE ID',
			'RESOURCE NAME',
			'EMAIL',
			'RESOURCE TYPE',
			'DEPARTMENT',
			'STATUS',
			'DATE',
			...(noOfLocations && noOfLocations > 0 ? ['LOCATION'] : []),
			'REPORTING TO',
			'REPORTING TO EMAIL',
		];
		const exportRows: any =
			(attendanceTableList &&
				attendanceTableList.map((attendance) => {
					return [...Object.values(attendance)];
				})) ||
			[];
		const attendanceExportData = [columnHeadings, ...exportRows];
		exportToCsv(
			`Absentee_Report_From_${dayjs(filterStartDate).format(
				dateFormat
			)}_To_${dayjs(filterEndDate).format(dateFormat)}.csv`,
			attendanceExportData
		);
	};
	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<CardHeader
					style={{ marginLeft: '20px' }}
					title='Attendance: Absentee report'
					titleTypographyProps={{ variant: 'h6' }}
				/>

				<Box display='flex'>
					<Box marginTop={'4px'}>
						<Form onSubmit={() => {}}>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box className={classes.header}>
											<Box marginTop={'20px'}>
												<ThemeProvider theme={dateTimePickerTheme}>
													<Box display='flex'>
														<ErrorTooltip
															title={startDateError ? START_DATE_ERROR : ''}
															placement='top'
															arrow={true}
														>
															<Box width='188px' marginLeft='20px'>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<KeyboardDatePicker
																		variant='inline'
																		onChange={(date: any) => {
																			setPage(0);
																			setStartDateError(false);
																			if (
																				dayjs(date).isAfter(
																					dayjs(filterEndDate)
																				)
																			) {
																				setStartDateError(true);
																			} else {
																				setStartDateError(false);
																			}
																			setFilterStartDate(
																				dayjs(date).format('YYYY-MM-DD')
																			);
																		}}
																		autoOk={true}
																		value={filterStartDate}
																		format={dateFormat}
																		label='Start date'
																		error={startDateError}
																		InputLabelProps={{ shrink: true }}
																		InputProps={{
																			endAdornment: (
																				<InputAdornment position='start'>
																					<IconButton>
																						<EventIcon />
																					</IconButton>
																				</InputAdornment>
																			),
																		}}
																	/>
																</MuiPickersUtilsProvider>
															</Box>
														</ErrorTooltip>
														<Box width='188px'>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<KeyboardDatePicker
																	variant='inline'
																	onChange={(date: any) => {
																		setPage(0);
																		setStartDateError(false);
																		if (
																			dayjs(date).isBefore(
																				dayjs(filterStartDate)
																			)
																		) {
																			setStartDateError(true);
																		} else {
																			setStartDateError(false);
																		}
																		setFilterEndDate(
																			dayjs(date).format('YYYY-MM-DD')
																		);
																	}}
																	value={filterEndDate}
																	autoOk={true}
																	format={dateFormat}
																	label='End date'
																	InputLabelProps={{ shrink: true }}
																	InputProps={{
																		endAdornment: (
																			<InputAdornment position='end'>
																				<IconButton>
																					<EventIcon />
																				</IconButton>
																			</InputAdornment>
																		),
																	}}
																/>
															</MuiPickersUtilsProvider>
														</Box>
													</Box>
												</ThemeProvider>
											</Box>
											<Box display='flex'>
												<Box display='block' width='170px'>
													<Box className={styles.label}>Resource</Box>
													<Box className={classes.autoCompleteInputContainer}>
														<Autocomplete
															multiple
															limitTags={0}
															value={
																selectedResourceOptionValue &&
																selectedResourceOptionValue?.length > 0
																	? selectedResourceOptionValue
																	: []
															}
															selectOnFocus={true}
															disablePortal
															fullWidth={false}
															onChange={(event, value, reason) => {
																setPage(0);
																if (!value) {
																	return;
																}
																const selectedIds = value
																	?.filter((resource) => resource.id !== 'All')
																	.map((resource) => resource?.id);
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'select-option'
																) {
																	setSelectedResourceOptionValues([
																		{ id: 'All', name: 'All' },
																		...resourceList,
																	]);
																	const allResourceIds = resourceList?.map(
																		(resource) => resource?.id
																	);
																	setSelectedResourceIdList(allResourceIds);

																	return;
																}
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'remove-option' &&
																	resourceList?.length !== selectedIds?.length
																) {
																	const currentOptions = value?.filter(
																		(resource) => resource?.id !== 'All'
																	);
																	setSelectedResourceOptionValues(
																		currentOptions
																	);
																	const currentIds = currentOptions?.map(
																		(resource) => resource?.id
																	);
																	setSelectedResourceIdList(currentIds);

																	return;
																}
																if (
																	selectedIds?.length ===
																		resourceList?.length &&
																	reason === 'select-option'
																) {
																	setSelectedResourceOptionValues([
																		{ id: 'All', name: 'All' },
																		...resourceList,
																	]);
																	const responseIds = resourceList?.map(
																		(resource) => resource?.id
																	);
																	setSelectedResourceIdList(responseIds);

																	return;
																}
																if (
																	selectedResourceOptionValue.find(
																		(option) => option?.id === 'All'
																	) &&
																	reason === 'remove-option'
																) {
																	setSelectedResourceIdList([]);
																	setSelectedResourceOptionValues([]);

																	return;
																}
																const selectedId = value?.map(
																	(resource) => resource?.id
																);
																setSelectedResourceIdList(selectedId);

																setSelectedResourceOptionValues(value);
															}}
															options={
																resourceList && resourceList?.length > 0
																	? [
																			{ id: 'All', name: 'All' },
																			...resourceList,
																	  ]
																	: []
															}
															disableCloseOnSelect={true}
															getLimitTagsText={getResourceFilterLimits}
															ChipProps={{ style: { display: 'none' } }}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<TextComponent {...params} label='' />
																</ThemeProvider>
															)}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected}
																		color='primary'
																	/>
																	<Tooltip
																		title={`${option?.name || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${ellipsis.ellipsis}`}
																			style={{ fontSize: '12px' }}
																		>
																			{`${option?.name || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															)}
															getOptionLabel={(option) => `${option?.name}`}
															getOptionSelected={(option, value) =>
																option.id === value.id
															}
														/>
													</Box>
												</Box>
											</Box>
											{noOfLocations && noOfLocations > 0 && (
												<Box display='block' width='170px' marginLeft={'10px'}>
													<Box className={styles.label}> Location</Box>
													<Box className={classes.autoCompleteInputContainer}>
														<Autocomplete
															multiple
															limitTags={0}
															value={
																selectedLocationOptionValue &&
																selectedLocationOptionValue?.length > 0
																	? selectedLocationOptionValue
																	: []
															}
															selectOnFocus={true}
															disablePortal
															fullWidth={false}
															onChange={(event, value, reason) => {
																setPage(0);
																if (!value) {
																	return;
																}
																const selectedIds = value
																	?.filter(
																		(location) =>
																			location?.id !== 'All' &&
																			location?.id !== 'Unassigned'
																	)
																	.map((location) => location?.id);
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'select-option'
																) {
																	setSelectedLocationOptionValue([
																		{ id: 'All', name: 'All' },
																		...locationDropDown,
																	]);
																	const allLocationIds = locationDropDown?.map(
																		(location) => location?.id
																	);
																	setSelectedLocationIdList(allLocationIds);
																	return;
																}
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'remove-option' &&
																	locationDropDown?.length !==
																		selectedIds?.length
																) {
																	const currentOptions = value?.filter(
																		(location) => location?.id !== 'All'
																	);
																	setSelectedLocationOptionValue(
																		currentOptions
																	);
																	const currentIds = currentOptions?.map(
																		(resource) => resource?.id
																	);
																	setSelectedLocationIdList(currentIds);

																	return;
																}
																const selectedIdsWithoutUnassigned =
																	selectedLocationIdList?.filter(
																		(location) => location !== 'Unassigned'
																	);
																if (
																	selectedIds?.length === 0 &&
																	selectedIdsWithoutUnassigned.length === 0 &&
																	reason === 'select-option'
																) {
																	setSelectedLocationOptionValue([
																		{ id: 'Unassigned', name: 'Unassigned' },
																	]);
																	const locationIds: any =
																		locationDropDown?.map(
																			(location) =>
																				location?.id === 'Unassigned'
																		);
																	setSelectedLocationIdList(locationIds);
																	return;
																}
																const selectedAllIds = value?.map(
																	(location) => location?.id
																);
																if (
																	selectedAllIds?.length ===
																		locationDropDown?.length &&
																	reason === 'select-option'
																) {
																	setSelectedLocationOptionValue([
																		{ id: 'All', name: 'All' },
																		...locationDropDown,
																	]);
																	const responseIds = locationDropDown?.map(
																		(location) => location?.id
																	);
																	setSelectedLocationIdList(responseIds);

																	return;
																}
																if (
																	selectedLocationOptionValue.find(
																		(option) => option?.id === 'All'
																	) &&
																	reason === 'remove-option'
																) {
																	setSelectedLocationIdList([]);
																	setSelectedLocationOptionValue([]);

																	return;
																}
																const selectedId = value?.map(
																	(location) => location?.id
																);
																setSelectedLocationIdList(selectedId);
																setSelectedLocationOptionValue(value);
															}}
															options={
																locationDropDown && locationDropDown?.length > 0
																	? [
																			{ id: 'All', name: 'All' },
																			...locationDropDown,
																	  ]
																	: []
															}
															disableCloseOnSelect={true}
															getLimitTagsText={getStatusFilterLimits}
															ChipProps={{ style: { display: 'none' } }}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<TextComponent
																		{...params}
																		label=''
																		placeholder={''}
																	/>
																</ThemeProvider>
															)}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected}
																		color='primary'
																	/>
																	<Tooltip
																		title={`${option?.name || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${ellipsis.ellipsis}`}
																		>
																			{`${option?.name || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															)}
															getOptionLabel={(option) => `${option?.name}`}
															getOptionSelected={(option, value) =>
																option.id === value.id
															}
														/>
													</Box>
												</Box>
											)}

											<Box display='block' width='170px' marginLeft={'10px'}>
												<Box className={styles.label}> Status</Box>
												<Autocomplete
													multiple
													limitTags={0}
													value={
														selectedStatusTypeOptionValue &&
														selectedStatusTypeOptionValue?.length > 0
															? selectedStatusTypeOptionValue
															: []
													}
													selectOnFocus={true}
													disablePortal
													fullWidth={false}
													onChange={(event, value, reason) => {
														setPage(0);
														if (!value) {
															return;
														}
														const selectedIds = value
															?.filter((status) => status.id !== 'All')
															.map((status) => status?.id);
														if (
															value.find((option) => option.id === 'All') &&
															reason === 'select-option'
														) {
															setSelectedStatusTypeOptionValue([
																{ id: 'All', name: 'All' },
																...statusDropDown,
															]);
															return;
														}
														if (
															value.find((option) => option.id === 'All') &&
															reason === 'remove-option' &&
															statusDropDown?.length !== selectedIds?.length
														) {
															const currentOptions = value?.filter(
																(status) => status?.id !== 'All'
															);
															setSelectedStatusTypeOptionValue(currentOptions);
															return;
														}
														if (
															selectedIds?.length === statusDropDown?.length &&
															reason === 'select-option'
														) {
															setSelectedStatusTypeOptionValue([
																{ id: 'All', name: 'All' },
																...statusDropDown,
															]);

															return;
														}
														if (
															selectedStatusTypeOptionValue.find(
																(option) => option?.id === 'All'
															) &&
															reason === 'remove-option'
														) {
															setSelectedStatusTypeOptionValue([]);

															return;
														}
														setSelectedStatusTypeOptionValue(value);
													}}
													options={
														statusDropDown && statusDropDown?.length > 0
															? [{ id: 'All', name: 'All' }, ...statusDropDown]
															: []
													}
													disableCloseOnSelect={true}
													getLimitTagsText={getStatusTypeFilterLimits}
													ChipProps={{ style: { display: 'none' } }}
													renderInput={(params) => (
														<ThemeProvider
															theme={customAutoCompleteSearchBarTheme}
														>
															<TextField
																{...params}
																label=''
																placeholder={''}
															/>
														</ThemeProvider>
													)}
													renderOption={(option, { selected }) => (
														<>
															<Checkbox
																icon={
																	<CheckBoxOutlineBlankIcon fontSize='small' />
																}
																checkedIcon={<CheckBoxIcon fontSize='small' />}
																style={{ marginRight: 8 }}
																checked={selected}
																color='primary'
															/>
															<Tooltip
																title={`${option?.name || '- -'}`}
																placement='right'
															>
																<Typography className={`${ellipsis.ellipsis}`}>
																	{`${option?.name || '- -'}`}
																</Typography>
															</Tooltip>
														</>
													)}
													getOptionLabel={(option) => `${option?.name}`}
													getOptionSelected={(option, value) =>
														option.id === value.id
													}
												/>
											</Box>

											<Box display='block' width='170px' marginLeft={'10px'}>
												<Box className={styles.label}> Leave Type</Box>
												<Autocomplete
													multiple
													limitTags={0}
													value={
														selectedLeaveTypeOptionValue &&
														selectedLeaveTypeOptionValue?.length > 0
															? selectedLeaveTypeOptionValue
															: []
													}
													selectOnFocus={true}
													disablePortal
													fullWidth={false}
													onChange={(event, value, reason) => {
														setPage(0);
														if (!value) {
															return;
														}
														const selectedIds = value
															?.filter((status) => status.id !== 'All')
															.map((status) => status?.id);
														if (
															value.find((option) => option.id === 'All') &&
															reason === 'select-option'
														) {
															setSelectedLeaveTypeOptionValue([
																{ id: 'All', name: 'All' },
																...leaveTypeDropDown,
															]);
															const allStatusIds = leaveTypeDropDown?.map(
																(status) => status?.id
															);
															setSelectedLeaveTypeIdList(allStatusIds);

															return;
														}
														if (
															value.find((option) => option.id === 'All') &&
															reason === 'remove-option' &&
															leaveTypeDropDown?.length !== selectedIds?.length
														) {
															const currentOptions = value?.filter(
																(status) => status?.id !== 'All'
															);
															setSelectedLeaveTypeOptionValue(currentOptions);
															const currentIds = currentOptions?.map(
																(resource) => resource?.id
															);
															setSelectedLeaveTypeIdList(currentIds);

															return;
														}
														if (
															selectedIds?.length ===
																leaveTypeDropDown?.length &&
															reason === 'select-option'
														) {
															setSelectedLocationOptionValue([
																{ id: 'All', name: 'All' },
																...leaveTypeDropDown,
															]);
															const responseIds = leaveTypeDropDown?.map(
																(status) => status?.id
															);
															setSelectedLeaveTypeIdList(responseIds);

															return;
														}
														if (
															selectedLeaveTypeOptionValue.find(
																(option) => option?.id === 'All'
															) &&
															reason === 'remove-option'
														) {
															setSelectedLeaveTypeIdList([]);
															setSelectedLeaveTypeOptionValue([]);

															return;
														}
														const selectedId = value?.map(
															(status) => status?.id
														);
														setSelectedLeaveTypeIdList(selectedId);
														setSelectedLeaveTypeOptionValue(value);
													}}
													options={
														leaveTypeDropDown && leaveTypeDropDown?.length > 0
															? [
																	{ id: 'All', name: 'All' },
																	...leaveTypeDropDown,
															  ]
															: []
													}
													disableCloseOnSelect={true}
													getLimitTagsText={getLeaveTypeFilterLimits}
													ChipProps={{ style: { display: 'none' } }}
													renderInput={(params) => (
														<ThemeProvider
															theme={customAutoCompleteSearchBarTheme}
														>
															<TextField
																{...params}
																label=''
																placeholder={''}
															/>
														</ThemeProvider>
													)}
													renderOption={(option, { selected }) => (
														<>
															<Checkbox
																icon={
																	<CheckBoxOutlineBlankIcon fontSize='small' />
																}
																checkedIcon={<CheckBoxIcon fontSize='small' />}
																style={{ marginRight: 8 }}
																checked={selected}
																color='primary'
															/>
															<Tooltip
																title={`${option?.name || '- -'}`}
																placement='right'
															>
																<Typography className={`${ellipsis.ellipsis}`}>
																	{`${option?.name || '- -'}`}
																</Typography>
															</Tooltip>
														</>
													)}
													getOptionLabel={(option) => `${option?.name}`}
													getOptionSelected={(option, value) =>
														option.id === value.id
													}
												/>
											</Box>
											<Box className={classes.exportButtonDiv}>
												<ExportButtonWithLoader
													generateCSV={generateExport}
													isDisabled={isEmpty(attendanceTableList)}
												/>
											</Box>
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>
				<CardContent>
					<Box className={classes.tableContainer} minHeight=''>
						{isLoading || loading ? (
							<>
								<Box className={classes.noData}>
									<Box className={classes.loading}>Loading</Box>
									<CircularProgress />
								</Box>
							</>
						) : (
							<>
								{attendanceTableList && attendanceTableList?.length > 0 ? (
									<TableContainer>
										<Table stickyHeader>
											<TableHead>
												<TableRow>
													<CustomTableCell className={commonStyle.headerCell}>
														RESOURCE ID
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														RESOURCE NAME
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														RESOURCE EMAIL
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														DEPARTMENT
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														STATUS
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														DATE
													</CustomTableCell>
													{noOfLocations && noOfLocations > 0 && (
														<CustomTableCell className={commonStyle.headerCell}>
															LOCATION
														</CustomTableCell>
													)}
													<CustomTableCell className={commonStyle.headerCell}>
														REPORTING TO
													</CustomTableCell>
													<CustomTableCell className={commonStyle.headerCell}>
														REPORTING TO EMAIL
													</CustomTableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												{attendanceTableList &&
													attendanceTableList?.length > 0 &&
													attendanceTableList
														?.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((data) => {
															return (
																<TableRow hover={true}>
																	<CustomTableCell>
																		<Tooltip title={data?.empId || '--'}>
																			<Typography
																				className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.empId || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>

																	<CustomTableCell>
																		<Tooltip
																			title={
																				data?.resource ? data?.resource : '--'
																			}
																		>
																			<Typography
																				className={`${classes.nameCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.resource ? data?.resource : '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip
																			title={
																				data?.resource
																					? data?.resourceEmail
																					: '--'
																			}
																		>
																			<Typography
																				className={`${classes.nameCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.resource
																					? data?.resourceEmail
																					: '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip title={data?.department || '--'}>
																			<Typography
																				className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.department || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip title={''}>
																			<Typography
																				className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.type || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip title={data?.date || '--'}>
																			<Typography
																				className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.date || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	{noOfLocations && noOfLocations > 0 && (
																		<CustomTableCell>
																			<Tooltip title={data?.location || '--'}>
																				<Typography
																					className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{data?.location || '--'}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																	)}
																	<CustomTableCell>
																		<Tooltip title={data?.reportingTo || '--'}>
																			<Typography
																				className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.reportingTo || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																	<CustomTableCell>
																		<Tooltip
																			title={data?.reportingToEmail || '--'}
																		>
																			<Typography
																				className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{data?.reportingToEmail || '--'}
																			</Typography>
																		</Tooltip>
																	</CustomTableCell>
																</TableRow>
															);
														})}
											</TableBody>
										</Table>
										<Box display='flex' justifyContent='flex-end'>
											<TablePagination
												rowsPerPageOptions={[5, 10, 25]}
												count={attendanceTableList?.length || 0}
												rowsPerPage={rowsPerPage}
												page={page}
												onPageChange={handleChangePage}
												onChangeRowsPerPage={handleChangeRowsPerPage}
											/>
										</Box>
									</TableContainer>
								) : (
									<Box className={classes.noData}>
										<Typography className={commonStyle.noDataLabel}>
											No data found
										</Typography>
									</Box>
								)}
							</>
						)}
					</Box>
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
