import gql from 'graphql-tag';

export const ADD_ORG_HOLIDAY = gql`
	mutation addOrgHoliday($data: org_holidays_insert_input!) {
		insert_org_holidays_one(object: $data) {
			name
			description
			is_restricted
			org_id
			date
			year
		}
	}
`;

export const GET_HOLIDAYS = gql`
	query getHolidays(
		$filter: org_holidays_bool_exp = {}
		$optionalHolidayFilter: user_optional_holiday_mapping_bool_exp = {}
	) {
		org_holidays(where: $filter) {
			id
			name
			date
			description
			is_restricted
			org_id
			year
			location_id
			org_location {
				name
			}
		}
		user_optional_holiday_mapping(where: $optionalHolidayFilter) {
			id
			org_holiday {
				name
				id
				date
				is_restricted
				org_location {
					id
					name
				}
			}
		}
	}
`;

export const UPDATE_ORG_HOLIDAY = gql`
	mutation updateHoliday($holidayId: uuid!, $data: org_holidays_set_input!) {
		update_org_holidays_by_pk(pk_columns: { id: $holidayId }, _set: $data) {
			name
			org_id
		}
	}
`;

export const GET_ORGANISATION = gql`
	query getOrganisationDetails($organisationId: uuid!) {
		organization(where: { id: { _eq: $organisationId } }) {
			id
			name
			address
			timezone
			currency_id
			start_week
			weekend
			working_hours
			financial_year_end_date
			financial_year_end_month
			financial_year_start_date
			financial_year_start_month
			org_supported_currencies {
				currency_id
			}
		}
	}
`;

export const GET_ORGANISATION_LEAVE_YEAR = gql`
	query getOrganisationLeaveYearDetails($organisationId: uuid!) {
		organization(where: { id: { _eq: $organisationId } }) {
			id
			leave_calendar_year_end_date
			leave_calendar_year_end_month
			leave_calendar_year_start_date
			leave_calendar_year_start_month
		}
	}
`;

export const UPDATE_ORGANISATION = gql`
	mutation updateOrgasationCurrency(
		$data: organization_set_input!
		$organisationId: uuid!
	) {
		update_organization_by_pk(
			pk_columns: { id: $organisationId }
			_set: $data
		) {
			id
		}
	}
`;

export const UPDATE_ORG_SUPPORTED_CURRENCIES = gql`
	mutation updateOrganisationSupportedCurrencies(
		$organisationId: uuid!
		$data: [org_supported_currencies_insert_input!] = {}
	) {
		delete_org_supported_currencies(
			where: { org_id: { _eq: $organisationId } }
		) {
			affected_rows
		}
		insert_org_supported_currencies(objects: $data) {
			affected_rows
		}
	}
`;

export const GET_ORGANISATION_SHIFT = gql`
	query getOrganisationShift {
		org_shift {
			id
			name
			start_time
			end_time
			currency_id
			allowance
			is_active
			is_default
		}
	}
`;

export const ADD_ORGANISATION_SHIFT = gql`
	mutation AddOrganisationShift($data: org_shift_insert_input!) {
		insert_org_shift_one(object: $data) {
			id
			name
		}
	}
`;

export const UPDATE_ORGANISATION_SHIFT = gql`
	mutation updateOrganisationShift(
		$organisationShiftId: uuid!
		$data: org_shift_set_input!
	) {
		update_org_shift(
			where: { is_default: { _eq: true } }
			_set: { is_default: false }
		) {
			affected_rows
		}
		update_org_shift_by_pk(
			pk_columns: { id: $organisationShiftId }
			_set: $data
		) {
			id
			name
		}
	}
`;

export const GET_USERS = gql`
	query getOrgUsers($userFilter: user_bool_exp = {}) {
		user(where: $userFilter) {
			id
			first_name
			last_name
			middle_name
			email
			phone_number
			user_type
			is_active
			created_at
			full_name
			role_mappings {
				role_id
				role {
					name
				}
			}
		}
	}
`;
export const ADD_USER = gql`
	mutation AddUser($data: user_insert_input!) {
		insert_user_one(object: $data) {
			first_name
			role_mappings {
				role {
					name
				}
			}
		}
	}
`;

export const UPDATE_USER = gql`
	mutation updateUser(
		$userId: uuid!
		$data: user_set_input!
		$role_mapping: role_mapping_insert_input!
	) {
		delete_role_mapping(where: { user_id: { _eq: $userId } }) {
			affected_rows
		}
		update_user_by_pk(pk_columns: { id: $userId }, _set: $data) {
			id
			email
		}
		insert_role_mapping_one(object: $role_mapping) {
			role_id
			role {
				name
			}
		}
	}
`;

export const UPSERT_ROLE = gql`
	mutation UpsertRole($data: role_insert_input!) {
		insert_role_one(
			object: $data
			on_conflict: {
				constraint: PK_ab841b6a976216a286c10c117f1
				update_columns: [name, description]
			}
		) {
			name
			id
			description
		}
	}
`;

export const UPSERT_ROLE_PERMISSIONS = gql`
	mutation UpsertRolePermissions($rolePermissions: RolePermissionsInput!) {
		upsertRolePermissions(rolePermissions: $rolePermissions) {
			role_id
			permissions
		}
	}
`;
export const UPSERT_DEPARTMENT = gql`
	mutation upsertDepartment($department: department_insert_input!) {
		insert_department_one(
			object: $department
			on_conflict: {
				update_columns: [name, description]
				constraint: PK_58d261117d7d8ccd61e5948f5f8
			}
		) {
			id
		}
	}
`;
export const UPSERT_DESIGNATION = gql`
	mutation upsertDesignation($designation: designation_insert_input!) {
		insert_designation_one(
			object: $designation
			on_conflict: {
				update_columns: [title, description]
				constraint: PK_8be765bddf82a056b85f80f89ff
			}
		) {
			id
		}
	}
`;
export const UPSERT_JOB_LEVEL = gql`
	mutation upsertJobLevel($job_level: job_level_insert_input!) {
		insert_job_level_one(
			object: $job_level
			on_conflict: {
				update_columns: [level, description]
				constraint: PK_ed59be60beedf898c48242bb3e7
			}
		) {
			id
		}
	}
`;
export const UPSERT_SKILL = gql`
	mutation upsertSKill($skill: skill_master_insert_input!) {
		insert_skill_master_one(
			object: $skill
			on_conflict: {
				update_columns: [name, description]
				constraint: PK_3ff59967e2596ee6a9d24656ffc
			}
		) {
			id
		}
	}
`;

export const GET_KANBAN_BOARDS = gql`
	query getKanbanBoards {
		kanban_board(distinct_on: name) {
			id
			name
			type
		}
	}
`;

export const GET_KANBAN_COLUMNS = gql`
	query getKanbanColumns($kanbanBoardId: uuid!) {
		kanban_column(
			where: { board_id: { _eq: $kanbanBoardId } }
			order_by: { ordinal: asc }
		) {
			id
			name
			ordinal
			state
		}
	}
`;

export const GET_KANBAN_BOARDS_STATES = gql`
	query getKanbanBoardStates($kanbanBoardType: String!) {
		master_settings(where: { name: { _eq: "kanban_config" } }) {
			states: value(path: $kanbanBoardType)
		}
	}
`;

export const UPSERT_TASK = gql`
	mutation upsertTask($task: checklist_items_insert_input!) {
		insert_checklist_items_one(
			object: $task
			on_conflict: {
				constraint: checklists_pkey
				update_columns: [name, description]
			}
		) {
			id
		}
	}
`;

export const UPSERT_KANBAN_COLUMN = gql`
	mutation upsertTaskKanbanColumn($kanban: kanban_column_insert_input!) {
		insert_kanban_column_one(
			object: $kanban
			on_conflict: {
				constraint: kanban_column_pkey
				update_columns: [board_id, name, description, state]
			}
		) {
			id
		}
	}
`;

export const GET_COLUMN_STATE = gql`
	query getStateTypes($path: String) {
		master_settings(where: { name: { _eq: "kanban_config" } }) {
			id
			name
			value(path: $path)
		}
	}
`;

export const UPDATE_KANBAN_COLUMN = gql`
	mutation updateKanbanColumn(
		$kanbanColumnId: uuid!
		$data: kanban_column_set_input!
	) {
		update_kanban_column_by_pk(
			pk_columns: { id: $kanbanColumnId }
			_set: $data
		) {
			id
			name
		}
	}
`;

export const ADD_KANBAN_COLUMN = gql`
	mutation addKanbanColumn($data: kanban_column_insert_input!) {
		insert_kanban_column_one(object: $data) {
			id
		}
	}
`;

export const DELETE_KANBAN_COLUMN = gql`
	mutation deleteKanbanColumn($kanbanColumnId: uuid!) {
		delete_kanban_column_by_pk(id: $kanbanColumnId) {
			id
		}
	}
`;

export const GET_ORG_LEAVE_GROUPS = gql`
	query getLeaveGroups($orgId: uuid!, $employeeName: String) {
		org_leave_group(distinct_on: label, where: { org_id: { _eq: $orgId } }) {
			id
			label
			value
			is_default
			is_holidays_and_weekends_excluded
			applicable_from
			start_date
			end_date
			org_leave_types {
				id
				leave_type
				leave_increments
				interval
				default_amount
			}
			employees(
				order_by: { user: { first_name: asc } }
				where: {
					user: {
						is_active: { _eq: true }
						_or: [
							{ first_name: { _ilike: $employeeName } }
							{ last_name: { _ilike: $employeeName } }
						]
					}
				}
			) {
				user {
					first_name
					last_name
					full_name
					email
				}
			}
			employees_aggregate(where: { user: { is_active: { _eq: true } } }) {
				aggregate {
					count
				}
			}
			leave_group_dept_mappings {
				id
				department {
					id
					name
				}
			}
		}
	}
`;
export const UPDATE_CHECKLIST_ITEMS = gql`
	mutation updateCheckListItems(
		$taskId: uuid!
		$data: checklist_items_set_input!
	) {
		update_checklist_items_by_pk(pk_columns: { id: $taskId }, _set: $data) {
			id
			name
		}
	}
`;

export const UPDATE_KANBAN_ITEMS = gql`
	mutation update_kanban_column(
		$board_id: uuid!
		$data: kanban_column_set_input!
	) {
		update_kanban_column_by_pk(pk_columns: { id: $board_id }, _set: $data) {
			id
			name
		}
	}
`;

export const UPDATE_LEAVE_POLICY = gql`
	mutation updateLeavePolicy(
		$leaveGroupId: uuid!
		$data: org_leave_group_set_input!
	) {
		update_org_leave_group_by_pk(
			pk_columns: { id: $leaveGroupId }
			_set: $data
		) {
			id
		}
	}
`;

export const ADD_LEAVE_POLICY = gql`
	mutation addLeavePolicy($data: org_leave_group_insert_input!) {
		insert_org_leave_group_one(object: $data) {
			id
		}
	}
`;

export const UPSERT_LEAVE_TYPE = gql`
	mutation upsertLeaveType($data: org_leave_types_insert_input!) {
		insert_org_leave_types_one(
			object: $data
			on_conflict: {
				constraint: org_leave_types_pkey
				update_columns: [
					interval
					default_amount
					is_LOP
					is_carry_forward
					is_leave_sandwich_type
					is_negative_bal_allowed
					leave_increments
					leave_group_id
					leave_type
					carry_forward_max_limit
				]
			}
		) {
			id
		}
	}
`;

export const GET_DEPARTMENT_OPTIONS = gql`
	query getDepartmentOptions {
		department(order_by: { name: asc }) {
			id
			name
			employees_aggregate(where: { user: { is_active: { _eq: true } } }) {
				aggregate {
					count
				}
			}
		}
	}
`;

export const UPDATE_EMPLOYEES_LEAVE_POLICY = gql`
	mutation mapEmployeesWithLeavePolicies(
		$departmentIds: [uuid!]!
		$data: employee_set_input!
		$removedDepartmentIds: [uuid!]!
		$removeEmployeeLeaveGroup: employee_set_input!
	) {
		removeEmployeeShift: update_employee(
			where: { department_id: { _in: $removedDepartmentIds } }
			_set: $removeEmployeeLeaveGroup
		) {
			returning {
				id
			}
		}
		update_employee(
			where: { department_id: { _in: $departmentIds } }
			_set: $data
		) {
			returning {
				id
			}
		}
	}
`;

export const DELETE_LEAVE_TYPE = gql`
	mutation deleteLeaveType($leaveTypeId: uuid!) {
		delete_org_leave_types_by_pk(id: $leaveTypeId) {
			id
		}
	}
`;

export const DELETE_LEAVE_POLICY = gql`
	mutation deleteLeavePolicy($leaveGroupId: uuid!) {
		delete_org_leave_types(where: { leave_group_id: { _eq: $leaveGroupId } }) {
			affected_rows
		}
		update_org_leave_group_by_pk(
			pk_columns: { id: $leaveGroupId }
			_set: { deleted_at: "now()" }
		) {
			id
		}
	}
`;

export const UPDATE_LEAVE_POLICY_WITH_MARK_AS_DEFAULT = gql`
	mutation updateLeavePolicyWithMarkDefault(
		$leaveGroupId: uuid!
		$data: org_leave_group_set_input!
		$defaultLeaveGroupId: uuid!
	) {
		update_org_leave_group(
			where: { id: { _eq: $defaultLeaveGroupId } }
			_set: { is_default: false }
		) {
			affected_rows
		}
		update_org_leave_group_by_pk(
			pk_columns: { id: $leaveGroupId }
			_set: $data
		) {
			id
		}
	}
`;

export const UPDATE_ORGANIZATION_NOTICE_PERIOD_DAYS = gql`
	mutation updateOrganizationNoticePeriodDays(
		$id: uuid!
		$noticePeriod: organization_set_input!
	) {
		update_organization_by_pk(pk_columns: { id: $id }, _set: $noticePeriod) {
			notice_period_days
		}
	}
`;

export const ENABLE_UPDATE_EMPLOYEE_ID_LENGTH = gql`
	mutation EnableUpdateEmployeeIdLength(
		$id: uuid!
		$enable_fixed_employee_id_length: Boolean
	) {
		update_organization_by_pk(
			pk_columns: { id: $id }
			_set: {
				enable_fixed_employee_id_length: $enable_fixed_employee_id_length
			}
		) {
			id
		}
	}
`;

export const UPDATE_EMPLOYEE_ID_LENGTH = gql`
	mutation UpdateEmployeeIdLength($id: uuid!, $employee_id_length: numeric!) {
		update_organization_by_pk(
			pk_columns: { id: $id }
			_set: { employee_id_length: $employee_id_length }
		) {
			id
		}
	}
`;

export const GET_ORGANIZATION_HOLIDAY_DATE = gql`
	query getOrganiztionHolidayYear {
		org_holidays_aggregate {
			aggregate {
				max {
					date
				}
				min {
					date
				}
			}
		}
	}
`;

export const UPDATE_ORGANIZATION_PROBATION_DAYS = gql`
	mutation updateOrganizationProbationDays(
		$id: uuid!
		$probationPeriod: organization_set_input!
	) {
		update_organization_by_pk(pk_columns: { id: $id }, _set: $probationPeriod) {
			id
		}
	}
`;

export const UPDATE_KANBAN_TEMPLATE_DEFAULT = gql`
	mutation updateDefaultKanban($object: [kanban_board_insert_input!]!) {
		insert_kanban_board(
			objects: $object
			on_conflict: { constraint: kanban_board_pkey, update_columns: is_default }
		) {
			returning {
				id
			}
		}
	}
`;

export const GET_PRIMARY_USER = gql`
	query getKanbanBoardPrimaryUser($boardId: uuid!) {
		kanban_board_invited_users(
			where: {
				is_primary: { _eq: true }
				board_id: { _eq: $boardId }
				user: { is_active: { _eq: true } }
			}
		) {
			user {
				first_name
				last_name
				full_name
				id
			}
			id
			board_id
			kanban_board {
				name
			}
		}
	}
`;

export const INSERT_KANBAN_BOARD = gql`
	mutation insertKanbanBoard($data: kanban_board_insert_input!) {
		insert_kanban_board_one(object: $data) {
			id
		}
	}
`;

export const UPDATE_KANBAN_BOARD_primary_USER = gql`
	mutation updateKanbanBoardPrimaryUser(
		$invitedUser: kanban_board_invited_users_set_input!
		$id: uuid!
	) {
		update_kanban_board_invited_users_by_pk(
			pk_columns: { id: $id }
			_set: $invitedUser
		) {
			user_id
			is_primary
		}
	}
`;

export const DELETE_ORG_HOLIDAY = gql`
	mutation deleteOrgHoliday($orgHolidayId: uuid!) {
		delete_org_holidays_by_pk(id: $orgHolidayId) {
			id
		}
	}
`;

export const UPSERT_MASTER_PROJECT_TASK = gql`
	mutation UpsertMasterProjectTask(
		$data: [master_project_task_insert_input!] = {}
	) {
		insert_master_project_task(
			on_conflict: {
				constraint: org_project_tasks_pkey
				update_columns: [title, is_billable]
			}
			objects: $data
		) {
			affected_rows
		}
	}
`;

export const DELETE_MASTER_PROJECT_TASK = gql`
	mutation softDeleteMasterProjectTask($masterProjectTaskId: uuid!) {
		update_master_project_task(
			where: { id: { _eq: $masterProjectTaskId } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
	}
`;

export const GET_ORG_TASK_MAPPINGS = gql`
	query getOrgTaskMappings {
		org_tasks(order_by: { title: asc }) {
			id
			title
			is_billable
			task_category
			departments: org_task_mappings {
				id
				department {
					id
					name
				}
			}
		}
	}
`;

export const UPSERT_ORG_TASK = gql`
	mutation upsertOrgTask($data: org_tasks_insert_input = {}) {
		insert_org_tasks_one(
			object: $data
			on_conflict: {
				constraint: org_tasks_pkey
				update_columns: [title, is_billable, task_category]
			}
		) {
			id
		}
	}
`;

export const DELETE_ORG_TASK = gql`
	mutation softDeleteOrgTask($orgTaskId: uuid!) {
		delete_org_task_mappings(where: { task_id: { _eq: $orgTaskId } }) {
			affected_rows
		}
		update_org_tasks(
			where: { id: { _eq: $orgTaskId } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
	}
`;

export const UPSERT_ORG_TASK_MAPPINGS = gql`
	mutation upsertOrgTaskMappings(
		$data: [org_task_mappings_insert_input!] = {}
		$departmentIdsToDelete: [uuid!]!
		$orgTaskId: uuid!
	) {
		delete_org_task_mappings(
			where: {
				_and: {
					department_id: { _in: $departmentIdsToDelete }
					task_id: { _eq: $orgTaskId }
				}
			}
		) {
			affected_rows
		}
		insert_org_task_mappings(
			objects: $data
			on_conflict: {
				constraint: org_task_mappings_pkey
				update_columns: [task_id, department_id]
			}
		) {
			affected_rows
		}
	}
`;

export const INSERT_ORG_TASK = gql`
	mutation addOrgTask($data: org_tasks_insert_input = {}) {
		insert_org_tasks_one(object: $data) {
			id
		}
	}
`;

export const GET_ORG_SHIFT_EMPLOYEES = gql`
	query getShiftEmployees($orgShiftId: uuid!, $searchedName: String = "") {
		user(
			order_by: { first_name: asc }
			where: {
				is_active: { _eq: true }
				first_name: { _is_null: false, _ilike: $searchedName }
				_or: [
					{ employee: { org_shift_id: { _eq: $orgShiftId } } }
					{ contractors: { org_shift_id: { _eq: $orgShiftId } } }
				]
			}
		) {
			full_name
			email
		}
	}
`;

export const ORG_SHIFT_EMPLOYEES_COUNT = gql`
	query getShiftEmployeesCount {
		org_shift {
			id
			name
			employees_aggregate {
				aggregate {
					count
				}
			}
			contractors_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;

export const GET_TIMESHEET_SETTINGS_DATA = gql`
	query getTimesheetSettings($orgId: uuid!) {
		timesheet_settings(where: { org_id: { _eq: $orgId } }) {
			id
			freeze_date
			threshold_value
			is_note_mandatory
			weekly_reminder_time
			weekly_reminder_day
			disable_after_submit
		}
	}
`;

export const UPDATE_EMPLOYEE_ORG_SHIFT = gql`
	mutation mapEmployeesWithOrgShift(
		$departmentIds: [uuid!]!
		$setEmployeeShift: employee_set_input!
		$removedDepartmentIds: [uuid!]!
		$removeEmployeeShift: employee_set_input!
		$removeContractorShift: contractor_set_input!
		$setContractorShift: contractor_set_input = {}
	) {
		removeEmployeeShift: update_employee(
			where: { department_id: { _in: $removedDepartmentIds } }
			_set: $removeEmployeeShift
		) {
			returning {
				id
			}
		}
		removeContractorShift: update_contractor(
			where: { department_id: { _in: $removedDepartmentIds } }
			_set: $removeContractorShift
		) {
			returning {
				id
			}
		}
		update_employee(
			where: { department_id: { _in: $departmentIds } }
			_set: $setEmployeeShift
		) {
			returning {
				id
			}
		}
		update_contractor(
			where: { department_id: { _in: $departmentIds } }
			_set: $setContractorShift
		) {
			returning {
				id
			}
		}
	}
`;
export const UPSERT_TIMESHEET_SETTINGS = gql`
	mutation upsertTimesheetSettings(
		$settingsData: timesheet_settings_insert_input!
	) {
		insert_timesheet_settings_one(
			object: $settingsData
			on_conflict: {
				constraint: timesheet_settings_pkey
				update_columns: [
					freeze_date
					is_note_mandatory
					threshold_value
					weekly_reminder_day
					weekly_reminder_time
					disable_after_submit
				]
			}
		) {
			id
		}
	}
`;

export const getOrgShiftDepartmentMappings = gql`
	query getOrgShiftMappings {
		org_shift {
			id
			name
			departments: shift_dept_mappings {
				id
				department {
					id
					name
				}
			}
		}
	}
`;

export const UPSERT_ORG_SHIFT_MAPPINGS = gql`
	mutation upsertOrgShiftMappings(
		$data: [shift_dept_mappings_insert_input!] = {}
		$departmentIdsToDelete: [uuid!]!
	) {
		delete_shift_dept_mappings(
			where: { department_id: { _in: $departmentIdsToDelete } }
		) {
			affected_rows
		}
		insert_shift_dept_mappings(
			objects: $data
			on_conflict: {
				constraint: shift_dept_mappings_pkey
				update_columns: [org_shift_id, department_id]
			}
		) {
			affected_rows
		}
	}
`;

export const DELETE_ORG_SHIFT = gql`
	mutation deleteOrgShift($orgShiftId: uuid = "") {
		delete_org_shift(where: { id: { _eq: $orgShiftId } }) {
			affected_rows
		}
	}
`;

export const LEAVE_GROUP_DEPT_MAPPINGS = gql`
	mutation upsertLeaveGroupDeptMappings(
		$data: [leave_group_dept_mappings_insert_input!] = {}
		$departmentIdsToDelete: [uuid!]!
	) {
		delete_leave_group_dept_mappings(
			where: { department_id: { _in: $departmentIdsToDelete } }
		) {
			affected_rows
		}
		insert_leave_group_dept_mappings(
			objects: $data
			on_conflict: {
				update_columns: [org_leave_group_id, department_id]
				constraint: leave_group_dept_mappings_pkey
			}
		) {
			affected_rows
		}
	}
`;

export const UPDATE_DATE_FORMAT = gql`
	mutation updateDateFormat($data: organization_set_input!, $orgId: uuid!) {
		update_organization_by_pk(pk_columns: { id: $orgId }, _set: $data) {
			id
		}
	}
`;

export const UPDATE_EMPLOYEE_LEAVE_TYPE_MAPPING = gql`
	mutation updateEmployeeLeaveTypeMapping(
		$employeeIds: [uuid!]!
		$employeeLeaveTypeMapping: [employee_leave_type_mappings_insert_input!] = {}
	) {
		delete_employee_leave_type_mappings(
			where: { employee_id: { _in: $employeeIds } }
		) {
			affected_rows
		}
		insert_employee_leave_type_mappings(objects: $employeeLeaveTypeMapping) {
			affected_rows
		}
	}
`;

export const UPSERT_ORG_LOCATION = gql`
	mutation upsertOrgLocation($orgLocation: org_location_insert_input!) {
		insert_org_location_one(
			object: $orgLocation
			on_conflict: {
				constraint: org_location_pkey
				update_columns: [name, timezone, address, country_id, is_default]
			}
		) {
			id
		}
	}
`;

export const DELETE_ORG_LOCATION = gql`
	mutation deleteOrgLocation($orgLocationId: uuid!) {
		delete_org_location(where: { id: { _eq: $orgLocationId } }) {
			returning {
				id
			}
		}
	}
`;

export const UPDATE_ORG_LOCATION_DEFAULT = gql`
	mutation updateLocationDefault {
		update_org_location(
			_set: { is_default: false }
			where: { is_default: { _eq: true } }
		) {
			returning {
				id
			}
		}
	}
`;

export const GET_LOCATION_USERS = gql`
	query getOrgLocationUsers($locationId: uuid!, $name: String!) {
		user(
			where: {
				_or: [
					{ employee: { location_id: { _eq: $locationId } } }
					{ contractors: { location_id: { _eq: $locationId } } }
				]
				full_name: { _ilike: $name }
			}
			order_by: { first_name: asc }
		) {
			first_name
			last_name
			full_name
			id
			email
		}
	}
`;

export const GET_LOCATION_COUNT = gql`
	query getOrgLocationUserCount {
		org_location {
			id
			name
			org_holidays_aggregate {
				aggregate {
					count
				}
			}
			employees_aggregate {
				aggregate {
					count
				}
			}
			contractors_aggregate {
				aggregate {
					count
				}
			}
			assets_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;
export const GET_ORG_DATE_SETTINGS_BY_ID = gql`
	query getDateFormatSettingsById($id: uuid!) {
		organization_by_pk(id: $id) {
			date_format
		}
	}
`;

export const GET_EDITABLE_COLUMNS_OPTIONS = gql`
	query getEditableColumns {
		master_settings(where: { name: { _eq: "editable_columns" } }) {
			options: value
		}
	}
`;

export const GET_ORG_EDITABLE_COLUMNS = gql`
	query getOrgEditableColumn($orgId: uuid!) {
		organization(where: { id: { _eq: $orgId } }) {
			enable_emp_ss
			editable_columns
		}
	}
`;

export const UPDATE_ORG_EDITABLE_COLUMNS = gql`
	mutation updateEditableColumns(
		$orgId: uuid!
		$data: organization_set_input = {}
	) {
		update_organization(where: { id: { _eq: $orgId } }, _set: $data) {
			affected_rows
		}
	}
`;

export const GET_ORG_EMP_ID_PREFIXES = gql`
	query getOrgEmpIdPrefixes($orgId: uuid!) {
		organization(where: { id: { _eq: $orgId } }) {
			emp_id_prefix
			contractor_id_prefix
		}
	}
`;

export const UPDATE_ORG_EMP_ID_PREFIXES = gql`
	mutation updateEmployeeIdPrefix(
		$orgId: uuid!
		$data: organization_set_input = {}
	) {
		update_organization(where: { id: { _eq: $orgId } }, _set: $data) {
			affected_rows
		}
	}
`;

export const GET_SKILL = gql`
	query getSkill($name: String) {
		skill_master(where: { name: { _ilike: $name } }) {
			id
			name
			description
		}
	}
`;
export const GET_ORG_LOCATIONS_FOR_HOLIDAY = gql`
	query getLocationsByOrgId($orgId: uuid) {
		org_location(where: { org_id: { _eq: $orgId } }, order_by: { name: asc }) {
			id
			name
		}
	}
`;

export const UPDATE_PROJECT_STATUS = gql`
	mutation upsertProjectStatus($projectStatus: project_status_insert_input!) {
		insert_project_status_one(
			object: $projectStatus
			on_conflict: {
				constraint: project_status_pkey
				update_columns: [description, label, value]
			}
		) {
			id
		}
	}
`;

export const GET_PROJECT_STATUS_OPTIONS = gql`
	query getOrganisationProjectStatusOptions {
		master_settings(where: { name: { _eq: "project_status" } }) {
			value
		}
	}
`;

export const DELETE_PROJECT_STATUS = gql`
	mutation deleteProjectStatus($projectStatusId: uuid!) {
		delete_project_status(where: { id: { _eq: $projectStatusId } }) {
			returning {
				id
			}
		}
	}
`;

export const GET_PROJECT_STATUS_COUNT = gql`
	query projectStatusCount {
		project_status {
			label
			id
			projects_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;

export const UPSERT_USER_TEAM = gql`
	mutation upsertUserTeam($data: [user_team_insert_input!] = {}) {
		insert_user_team(
			on_conflict: { constraint: user_team_pkey, update_columns: name }
			objects: $data
		) {
			returning {
				id
				name
			}
		}
	}
`;

export const GET_USER_TEAM_MAPPINGS = gql`
	query getUserTeamMappings {
		user_team_mapping {
			id
			user_id
			team_id
		}
	}
`;

export const UPSERT_USER_TEAM_MAPPING = gql`
	mutation upsertUserTeamMapping(
		$userTeamMappings: [user_team_mapping_insert_input!]!
		$deleteUserMappingIds: [uuid!] = {}
	) {
		delete_user_team_mapping(where: { id: { _in: $deleteUserMappingIds } }) {
			affected_rows
		}
		insert_user_team_mapping(
			objects: $userTeamMappings
			on_conflict: {
				constraint: user_team_mapping_user_id_team_id_org_id_key
				update_columns: [user_id, team_id]
			}
		) {
			affected_rows
		}
	}
`;

export const DELETE_USER_TEAM = gql`
	mutation deleteUserTeam($userTeamId: uuid!) {
		delete_user_team_by_pk(id: $userTeamId) {
			id
		}
	}
`;

export const DELETE_USER_TEAM_MAPPINGS = gql`
	mutation deleteUserTeamMappings($userTeamMappings: [uuid!]!) {
		delete_user_team_mapping(where: { id: { _in: $userTeamMappings } }) {
			affected_rows
		}
	}
`;

export const GET_EMAIL_NOTIFICATION_SETTINGS = gql`
	query getEmailNotificationList($orgId: uuid!) {
		email_notification(
			where: { org_id: { _eq: $orgId } }
			order_by: { name: asc }
		) {
			id
			name
			value
			is_enabled
			receivers
			description
			is_recipient_editable
			email_type {
				value
				label
			}
		}
		organization(where: { id: { _eq: $orgId } }) {
			id
			is_email_enabled
		}
	}
`;

export const GET_ROLES = gql`
	query getOrgRoles($roleId: [uuid!]!) {
		role(where: { id: { _in: $roleId } }) {
			id
			name
		}
	}
`;

export const UPDATE_EMAIL_NOTIFICATION = gql`
	mutation updateEmailNotiification(
		$id: uuid!
		$data: email_notification_set_input!
	) {
		update_email_notification_by_pk(pk_columns: { id: $id }, _set: $data) {
			id
			is_enabled
			name
		}
	}
`;

export const UPDATE_ORGANIZATION_EMAIL_ENABLED = gql`
	mutation updateOrganisationEmailEnabled(
		$data: organization_set_input!
		$organisationId: uuid!
	) {
		update_organization_by_pk(
			pk_columns: { id: $organisationId }
			_set: $data
		) {
			id
			is_email_enabled
		}
	}
`;
export const UPDATE_EMAIL_RECEIVERS_LIST = gql`
	mutation updateEmailReceiversList($receiverList: jsonb!, $id: uuid!) {
		update_email_notification_by_pk(
			pk_columns: { id: $id }
			_set: { receivers: $receiverList }
		) {
			id
		}
	}
`;

export const GET_RECIPIENT_LIST = gql`
	query getRecipientList {
		role(order_by: { name: asc }) {
			id
			name
		}
	}
`;

export const UPDATE_ORG_USR_CUSTOM_PIC_ENABLE = gql`
	mutation updateOrgUsrCustomPicEnable(
		$orgId: uuid!
		$enableUsrCustomPic: Boolean = false
	) {
		update_organization(
			where: { id: { _eq: $orgId } }
			_set: { enable_usr_custom_pic: $enableUsrCustomPic }
		) {
			affected_rows
		}
	}
`;
export const UPDATE_OVERTIME_ENABLE = gql`
	mutation updateOvertimeEnable($orgId: uuid!, $enableOvertime: Boolean) {
		update_organization(
			where: { id: { _eq: $orgId } }
			_set: { enable_overtime: $enableOvertime }
		) {
			affected_rows
		}
	}
`;

export const UPDATE_OVERTIME_APPROVERS = gql`
	mutation updateTimesheetApproverList(
		$organisationId: uuid!
		$data: [timesheet_overtime_approvers_insert_input!] = {}
	) {
		delete_timesheet_overtime_approvers(
			where: { org_id: { _eq: $organisationId } }
		) {
			affected_rows
		}
		insert_timesheet_overtime_approvers(objects: $data) {
			affected_rows
		}
	}
`;

export const GET_OVERTIME_APPROVERS = gql`
	query getOvertimeApprovers($orgId: uuid!) {
		organization_by_pk(id: $orgId) {
			enable_overtime
		}
		timesheet_overtime_approvers(where: { org_id: { _eq: $orgId } }) {
			id
			user_id
			org_id
		}
	}
`;
export const GET_DAY_TYPE_DATA = gql`
	query getTrackDayTypeData {
		timesheet_settings {
			track_day_type
			id
		}
		timesheet_day_type(order_by: { name: asc }) {
			id
			name
			description
		}
	}
`;
export const UPSERT_DAY_TYPE = gql`
	mutation upsertDayType($object: timesheet_day_type_insert_input = {}) {
		insert_timesheet_day_type_one(
			object: $object
			on_conflict: {
				constraint: timesheet_day_type_pkey
				update_columns: [name, description]
			}
		) {
			id
		}
	}
`;
export const UPDATE_DAY_TYPE_ENABLE = gql`
	mutation updateTrackDayType($id: uuid!, $enableDayType: Boolean) {
		update_timesheet_settings(
			where: { id: { _eq: $id } }
			_set: { track_day_type: $enableDayType }
		) {
			affected_rows
		}
	}
`;
export const GET_TIMESHEET_DATA_BASED_ON_DAY_TYPE = gql`
	query getTimesheetAggregateByDayTypeId($dayTypeId: uuid!) {
		timesheet_aggregate(where: { day_type_id: { _eq: $dayTypeId } }) {
			aggregate {
				count
			}
		}
	}
`;
export const DELETE_DAY_TYPE = gql`
	mutation deleteDayType($id: uuid!) {
		delete_timesheet_day_type(where: { id: { _eq: $id } }) {
			returning {
				id
			}
		}
	}
`;

export const UPDATE_TIMESHEET_SETTINGS = gql`
	mutation updateTimesheetSettings(
		$orgId: uuid!
		$data: timesheet_settings_set_input = {}
	) {
		update_timesheet_settings(where: { org_id: { _eq: $orgId } }, _set: $data) {
			affected_rows
		}
	}
`;

export const GET_TIMESHEET_SETTING = gql`
	query getTimesheetConfig {
		timesheet_settings {
			id
			enable_draft
			enable_weekly_entry
		}
	}
`;

export const GET_PROJECT_CATEGORY_COUNT = gql`
	query projectCategoryCount($id: uuid!) {
		project_category_by_pk(id: $id) {
			name
			id
			type
			projects_aggregate {
				aggregate {
					count
				}
			}
			project_tasks_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;

export const DELETE_PROJECT_CATEGORY = gql`
	mutation deleteProjectCategory($projectCategoryId: uuid!) {
		delete_project_category(where: { id: { _eq: $projectCategoryId } }) {
			returning {
				id
			}
		}
	}
`;

export const UPSERT_PROJECT_CATEGORY = gql`
	mutation upsertProjectCategory(
		$projectCategory: project_category_insert_input!
	) {
		insert_project_category_one(
			object: $projectCategory
			on_conflict: {
				constraint: project_category_pkey
				update_columns: [name, type]
			}
		) {
			id
		}
	}
`;

export const GET_PROJECT_LABEL_COUNT = gql`
	query projectLabelCount($id: uuid!) {
		project_tags_by_pk(id: $id) {
			name
			id
			description
			projects_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;

export const DELETE_PROJECT_LABEL = gql`
	mutation deleteProjectTags($projectLabelId: uuid!) {
		delete_project_tags(where: { id: { _eq: $projectLabelId } }) {
			returning {
				id
			}
		}
	}
`;

export const UPSERT_PROJECT_LABEL = gql`
	mutation upsertProjectLabel($projectTags: project_tags_insert_input!) {
		insert_project_tags_one(
			object: $projectTags
			on_conflict: { constraint: tag_pkey, update_columns: [name, description] }
		) {
			id
		}
	}
`;

export const SKILL_LEVEL_VALUE = gql`
	query getSkillLevelValue {
		master_settings(where: { name: { _eq: "skill_level" } }) {
			name
			value
		}
	}
`;
export const UPSERT_EMPLOYEE_PREFIX = gql`
	mutation upsertEmployeePrefix($prefixData: employee_prefix_insert_input!) {
		insert_employee_prefix_one(
			object: $prefixData
			on_conflict: {
				update_columns: [name, description]
				constraint: employee_prefix_pkey
			}
		) {
			id
		}
	}
`;
export const UPSERT_CONTRACTOR_PREFIX = gql`
	mutation upsertContractorPrefix(
		$prefixData: contractor_prefix_insert_input!
	) {
		insert_contractor_prefix_one(
			object: $prefixData
			on_conflict: {
				update_columns: [name, description]
				constraint: contractor_prefix_pkey
			}
		) {
			id
		}
	}
`;

export const ORG_LEAVE_TYPES = gql`
	query orgLeaveTypes($id: uuid!) {
		org_leave_group_by_pk(id: $id) {
			org_leave_types {
				id
				leave_type
			}
		}
	}
`;

export const DELETE_ORG_LEAVE_POLICY = gql`
	mutation deleteOrgLeavePolicy($leaveGroupId: uuid!, $leaveTypeIds: [uuid!]!) {
		update_employee(
			where: {
				employee_status: { value: { _eq: "inactive" } }
				user: { is_active: { _eq: false } }
				leave_group_id: { _eq: $leaveGroupId }
			}
			_set: { leave_group_id: null }
		) {
			affected_rows
		}
		update_leave_group_dept_mappings(
			where: { org_leave_group_id: { _eq: $leaveGroupId } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_employee_leave_type_mappings(
			where: { leave_type_id: { _in: $leaveTypeIds } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_employee_leaving_mapping(
			where: { leave_type_id: { _in: $leaveTypeIds } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_accrued_leave(
			where: { leave_type_id: { _in: $leaveTypeIds } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_org_leave_types(
			where: { leave_group_id: { _eq: $leaveGroupId } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}

		update_org_leave_group_by_pk(
			pk_columns: { id: $leaveGroupId }
			_set: { deleted_at: "now()" }
		) {
			id
		}
	}
`;
export const GET_HOLIDAYS_FOR_SETTINGS = gql`
	query getHolidaysForSettings($filter: org_holidays_bool_exp = {}) {
		org_holidays(where: $filter) {
			id
			name
			date
			description
			is_restricted
			org_id
			year
			location_id
			org_location {
				name
			}
		}
	}
`;

export const updateLeaveSetting = gql`
	mutation updateLeave($orgId: uuid!, $value: Boolean!) {
		update_organization(
			where: { id: { _eq: $orgId } }
			_set: { enable_leave: $value }
		) {
			affected_rows
			returning {
				enable_leave
			}
		}
	}
`;
export const GET_EMPLOYEE_ID_LENGTH = gql`
	query getEmployeeIdLength($organizationId: uuid!) {
		organization_by_pk(id: $organizationId) {
			enable_fixed_employee_id_length
			employee_id_length
		}
	}
`;

export const GET_TIMESHEET_CALCULATION_STATUS = gql`
	query getTimesheetCalculationStatus($orgId: uuid!) {
		organization_by_pk(id: $orgId) {
			timesheet_calculation_status
		}
		timesheet_status(
			where: {
				org_id: { _eq: $orgId }
				value: { _in: ["submitted", "approved"] }
			}
		) {
			label
			value
			id
		}
	}
`;
export const UPDATE_TIMESHEET_CALCULATION_STATUS = gql`
	mutation updateTimesheetCalculationStatus(
		$orgId: uuid!
		$data: organization_set_input!
	) {
		update_organization(where: { id: { _eq: $orgId } }, _set: $data) {
			affected_rows
		}
	}
`;

export const GET_ATTENDANCE_REGULARIZATION_SETTINGS = gql`
	query getAttendanceRegularizationSettings($organizationId: uuid!) {
		organization_by_pk(id: $organizationId) {
			enable_attendance_regularization
		}
	}
`;
export const UPDATE_ATTENDANCE_REGULARIZATION_SETTINGS = gql`
	mutation updateAttendanceRegularizationSettings(
		$orgId: uuid!
		$data: organization_set_input!
	) {
		update_organization(where: { id: { _eq: $orgId } }, _set: $data) {
			affected_rows
		}
	}
`;
export const UPSERT_PROJECT_RESOURCE_TAG = gql`
	mutation upsertProjectResourceTag(
		$projectTags: project_resource_tag_insert_input!
	) {
		insert_project_resource_tag_one(
			object: $projectTags
			on_conflict: {
				constraint: project_resource_tag_pkey
				update_columns: [name, description]
			}
		) {
			id
		}
	}
`;

export const GET_PROJECT_RESOURCE_TAG_COUNT = gql`
	query getProjectResourceTagCount($id: uuid!) {
		project_resource_tag_by_pk(id: $id) {
			project_resource_mappings_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;
export const DELETE_PROJECT_RESOURCE_TAG = gql`
	mutation deleteProjectResourceTag($tagId: uuid!) {
		delete_project_resource_tag(where: { id: { _eq: $tagId } }) {
			returning {
				id
			}
		}
	}
`;

export const GET_USER_EMAIL_MAPPING = gql`
	query getUserEmailMapping($groupId: uuid!) {
		user_email_group_mapping(
			where: {
				group_id: { _eq: $groupId }
				emailGroupUser: { is_active: { _eq: true } }
			}
			order_by: { emailGroupUser: { full_name: asc } }
		) {
			id
			group_id
			user_id
			emailGroupUser {
				id
				full_name
			}
		}
		user_email_group_mapping_aggregate(
			where: {
				group_id: { _eq: $groupId }
				emailGroupUser: { is_active: { _eq: true } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

export const ADD_USER_EMAIL_GROUP_MAPPING = gql`
	mutation addInvitedEmailGroupUser(
		$addedUser: user_email_group_mapping_insert_input!
	) {
		insert_user_email_group_mapping_one(
			object: $addedUser
			on_conflict: { constraint: user_email_group_mapping_pkey }
		) {
			id
		}
	}
`;

export const DELETE_USER_EMAIL_MAPPING = gql`
	mutation deleteUserEmailMapping($id: uuid!) {
		delete_user_email_group_mapping_by_pk(id: $id) {
			id
		}
	}
`;

export const DELETE_USER_EMAIL_GROUP = gql`
	mutation deleteUserEmailGroup($id: uuid!) {
		delete_user_email_group_by_pk(id: $id) {
			id
		}
	}
`;
export const GET_USER_EMAIL_GROUP = gql`
	query getUserEmailGroup($name: String!) {
		user_email_group(
			order_by: { name: asc }
			where: { name: { _ilike: $name } }
		) {
			id
			name
			description
			user_email_group_mappings_aggregate(
				where: { emailGroupUser: { is_active: { _eq: true } } }
			) {
				aggregate {
					count
				}
			}
		}
	}
`;

export const GET_SETTINGS_CUSTOM_FIELD = gql`
	query getSettingsCustomField($module: String!) {
		custom_field(
			where: {
				master_sub_module: { value: { _eq: $module } }
				is_active: { _eq: true }
			}
			order_by: { index: asc }
		) {
			id
			label
			index
			validation
			options
			type
			is_active
			sub_module_id
			master_sub_module {
				label
				value
				id
			}
		}
		lastIndexCustomField: custom_field_aggregate {
			aggregate {
				max {
					index
				}
			}
		}
	}
`;

export const UPSERT_CUSTOM_FIELDS = gql`
	mutation upsertCustomFields($customField: custom_field_insert_input!) {
		insert_custom_field_one(
			object: $customField
			on_conflict: {
				constraint: custom_field_pkey
				update_columns: [label, type, options, sub_module_id, index]
			}
		) {
			id
		}
	}
`;

export const GET_SUB_MODULES = gql`
	query getSubModules {
		master_sub_modules {
			id
			label
			value
		}
	}
`;

export const DELETE_CUSTOM_FIELDS = gql`
	mutation deleteCustomFields($id: uuid!) {
		delete_custom_field_by_pk(id: $id) {
			id
		}
	}
`;

export const GET_SETTINGS_CUSTOM_FIELD_DATA = gql`
	query getSettingsCustomFieldData(
		$name: String!
		$moduleId: uuid_comparison_exp = {}
	) {
		custom_field(
			where: {
				label: { _ilike: $name }
				sub_module_id: $moduleId
				is_active: { _eq: true }
			}
			order_by: { label: asc }
		) {
			id
			label
			index
			validation
			options
			type
			is_active
			sub_module_id
			master_sub_module {
				label
				value
				id
			}
		}
		lastIndexCustomField: custom_field_aggregate {
			aggregate {
				max {
					index
				}
			}
		}
	}
`;

export const GET_NON_NULL_CUSTOM_FIELD = gql`
	query getNonNullCustomField(
		$employeeCustomField: employee_bool_exp = {}
		$contractorCustomField: contractor_bool_exp = {}
		$projectCustomField: project_bool_exp = {}
	) {
		employee_aggregate(where: $employeeCustomField) {
			aggregate {
				count
			}
		}
		contractor_aggregate(where: $contractorCustomField) {
			aggregate {
				count
			}
		}
		project_aggregate(where: $projectCustomField) {
			aggregate {
				count
			}
		}
	}
`;

export const CUSTOM_FIELD_TYPE = gql`
	query customFieldType {
		master_settings(where: { name: { _eq: "custom_field_type" } }) {
			name
			value
		}
	}
`;
export const GET_ATTENDANCE_LOG_SETTINGS = gql`
	query getAttendanceLogSettings($organizationId: uuid!) {
		organization_by_pk(id: $organizationId) {
			enable_checkin_checkout
		}
	}
`;
export const UPDATE_ATTENDANCE_LOG_SETTINGS = gql`
	mutation updateAttendanceLogSettings(
		$orgId: uuid!
		$data: organization_set_input!
	) {
		update_organization(where: { id: { _eq: $orgId } }, _set: $data) {
			affected_rows
		}
	}
`;