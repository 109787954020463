import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab } from '@material-ui/core';
import { SelectedMonthDates } from './Attendance.model';
import {
	useGetAttendanceRegularizationSettingsQuery,
	useGetNumberOfReporteesQuery,
	useGetPendingAttendanceRequestCountQuery,
} from '../../generated/graphql';
import { Dayjs } from 'dayjs';
import Attendance from './Attendance.component';
import {
	useLeavePendingCountQuery,
	useMyPendingCountQuery,
} from '../../generated/graphql';
import { isEmpty } from 'lodash';

import no_data_found from '../../assets/no_data_found.png';
import LeaveApproval from './LeaveApproval.component';
import MyLeaves from './MyLeaves.component';
import PendingApproval from './PendingApproval.component';
import AttendanceHistory from './AttendanceHistory.component';
import AttendanceRequestPendingApproval from './AttendanceRequestApproval.component';
import { UserProfileContext } from '../../App';
import AttendanceAdminApproval from './AttendanceApprovalForAdmin.component';

const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const CustomTab = withStyles((theme: any) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: '10px',
		marginLeft: theme.spacing(1.8),
		fontFamily: 'Manrope-Bold',
		fontSize: '12px !important',
		letterSpacing: '0px',
		color: '#292929',
		'&$selected': {
			color: '#292929',
			fontFamily: 'Manrope-Bold',
		},
	},
}))((props: any) => <Tab disableRipple {...props} />);

const CustomTabPanel = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		marginTop: '10px',
		marginRight: '23px',
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
}));

interface LeaveDate {
	date: string;
	halfDay: number;
	reason: string;
	leave_type: string;
}
interface Props {
	refetch: () => void;
	userId: string;
	date: Dayjs[];
	currentMonth: SelectedMonthDates;
	isManagerView?: boolean;
	userLocationId?: string | null;
	isReportingManagerView?: boolean | undefined;
	leaveData?: LeaveDate[];
	showAttendanceList?: boolean;
	isUserView: boolean;
}

export const AttendanceContainer = (props: Props) => {
	const {
		refetch,
		userId,
		date,
		currentMonth,
		isManagerView,
		isReportingManagerView,
		userLocationId,
		leaveData,
		showAttendanceList,
		isUserView = false,
	} = props;
	const { orgId } = useContext<any>(UserProfileContext);
	const classes = useStyles();
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [count, setCount] = useState<number>(0);
	const [myCount, setMyCount] = useState<number>(0);

	const { data: attendanceRegularizationSettings } =
		useGetAttendanceRegularizationSettingsQuery({
			variables: {
				organizationId: orgId,
			},
		});

	const isAttendanceRegularizationEnabled =
		attendanceRegularizationSettings?.organization_by_pk
			?.enable_attendance_regularization || false;

	const { data: leavePendingCount, refetch: refetchPendingCount } =
		useLeavePendingCountQuery({
			variables: {
				userId,
			},
			fetchPolicy: 'network-only',
		});

	const { data: myPendingCount, refetch: refetchCount } =
		useMyPendingCountQuery({
			variables: {
				userId,
			},
			fetchPolicy: 'network-only',
		});

	const { data: attendanceCount, refetch: refetchAttendanceRequestCount } =
		useGetPendingAttendanceRequestCountQuery({
			variables: {
				userId,
			},
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		let initialValue = 0;
		if (isEmpty(leavePendingCount?.employee)) {
			setCount(0);
			return;
		}
		const response = leavePendingCount?.employee?.reduce(
			(previousValue: any, currentValue: any) => {
				let value =
					currentValue?.employee_leaving_mappings_aggregate?.aggregate?.count ||
					0;
				return (initialValue += value);
			},
			0
		);
		setCount(response);
	}, [leavePendingCount]);

	useEffect(() => {
		let initialValue = 0;
		if (isEmpty(myPendingCount?.employee)) {
			setMyCount(0);
			return;
		}
		const response = myPendingCount?.employee?.reduce(
			(previousValue: any, currentValue: any) => {
				let value =
					currentValue?.employee_leaving_mappings_aggregate?.aggregate?.count ||
					0;
				return (initialValue += value);
			},
			0
		);
		setMyCount(response);
	}, [myPendingCount]);

	const { data: reportees } = useGetNumberOfReporteesQuery({
		variables: {
			userId: userId,
		},
		fetchPolicy: 'network-only',
	});

	const handleChange = (event: any, newValue: number) => {
		setSelectedTabIndex(newValue);
	};

	const getReportingMangerBasedTabIndex = () => {
		return reportees?.user_aggregate?.aggregate?.count &&
			reportees?.user_aggregate?.aggregate?.count > 0
			? true
			: false;
	};

	return (
		<Box className={classes.root}>
			<>
				{!isAttendanceRegularizationEnabled ? (
					<>
						{!isManagerView && !isReportingManagerView ? (
							// My View without regularization
							<>
								<CustomTabs value={selectedTabIndex} onChange={handleChange}>
									<CustomTab key={0} label={'My Attendance'} />
									<CustomTab key={1} label={'My Leave History'} />
									{getReportingMangerBasedTabIndex() && (
										<CustomTab key={2} label={`Leave Approval(${count})`} />
									)}
								</CustomTabs>
								<CustomTabPanel value={selectedTabIndex} index={0}>
									<Attendance
										refetch={() => {
											refetch();
											refetchAttendanceRequestCount();
										}}
										userId={userId}
										date={date}
										currentMonth={currentMonth}
										isManagerView={isManagerView}
										isReportingManagerView={isReportingManagerView}
										userLocationId={userLocationId}
										leaveData={leaveData}
									/>
								</CustomTabPanel>
								<CustomTabPanel value={selectedTabIndex} index={1}>
									<MyLeaves
										userId={userId}
										userLocationId={userLocationId}
										isReportingManagerView={isReportingManagerView}
										isUserView={isUserView}
									/>
								</CustomTabPanel>
								{getReportingMangerBasedTabIndex() ? (
									<CustomTabPanel value={selectedTabIndex} index={2}>
										<LeaveApproval
											userId={userId}
											refetch={() => refetchPendingCount()}
											isReportingManagerView={true}
										/>
									</CustomTabPanel>
								) : null}
							</>
						) : (
							//Manager View without regularization
							<>
								<CustomTabs value={selectedTabIndex} onChange={handleChange}>
									<CustomTab key={0} label={'Attendance'} />
									<CustomTab key={1} label={'Leave History'} />

									<CustomTab
										key={2}
										label={`Pending Leave Approval(${myCount})`}
									/>
								</CustomTabs>
								<CustomTabPanel value={selectedTabIndex} index={0}>
									{showAttendanceList ? (
										<Attendance
											refetch={() => {
												refetch();
												refetchAttendanceRequestCount();
											}}
											userId={userId}
											date={date}
											currentMonth={currentMonth}
											isManagerView={isManagerView}
											isReportingManagerView={isReportingManagerView}
											userLocationId={userLocationId}
											leaveData={leaveData}
										/>
									) : (
										<Box textAlign='center'>
											<img src={no_data_found} alt='no_data_found' />
										</Box>
									)}
								</CustomTabPanel>
								<CustomTabPanel value={selectedTabIndex} index={1}>
									<MyLeaves
										userId={userId}
										userLocationId={userLocationId}
										isReportingManagerView={isReportingManagerView}
										isUserView={isUserView}
									/>
								</CustomTabPanel>

								<CustomTabPanel value={selectedTabIndex} index={2}>
									<PendingApproval
										userId={userId}
										userLocationId={userLocationId}
										refetch={() => refetchCount()}
										isReportingManagerView={isReportingManagerView}
									/>
								</CustomTabPanel>
							</>
						)}
					</>
				) : (
					<>
						{isManagerView || isReportingManagerView ? (
							//Manger View with regularization
							<>
								<CustomTabs value={selectedTabIndex} onChange={handleChange}>
									<CustomTab key={0} label={'Attendance'} />
									<CustomTab key={1} label='Attendance Request History' />

									<CustomTab
										key={2}
										label={`Attendance Approval(${
											attendanceCount?.userRequestCount?.aggregate?.count || 0
										})`}
									/>
									<CustomTab key={3} label={'Leave History'} />

									<CustomTab
										key={4}
										label={`Pending Leave Approval(${myCount})`}
									/>
								</CustomTabs>

								<CustomTabPanel value={selectedTabIndex} index={0}>
									{showAttendanceList ? (
										<Attendance
											refetch={() => {
												refetch();
												refetchAttendanceRequestCount();
											}}
											userId={userId}
											date={date}
											currentMonth={currentMonth}
											isManagerView={isManagerView}
											isReportingManagerView={isReportingManagerView}
											userLocationId={userLocationId}
											leaveData={leaveData}
										/>
									) : (
										<Box textAlign='center'>
											<img src={no_data_found} alt='no_data_found' />
										</Box>
									)}
								</CustomTabPanel>
								<CustomTabPanel value={selectedTabIndex} index={1}>
									<AttendanceHistory userId={userId} />
								</CustomTabPanel>

								<CustomTabPanel value={selectedTabIndex} index={2}>
									<AttendanceAdminApproval
										userId={userId}
										refetch={() => refetchAttendanceRequestCount()}
									/>
								</CustomTabPanel>

								<CustomTabPanel value={selectedTabIndex} index={3}>
									<MyLeaves
										userId={userId}
										userLocationId={userLocationId}
										isReportingManagerView={isReportingManagerView}
										isUserView={isUserView}
									/>
								</CustomTabPanel>

								<CustomTabPanel value={selectedTabIndex} index={4}>
									<PendingApproval
										userId={userId}
										userLocationId={userLocationId}
										refetch={() => refetchCount()}
										isReportingManagerView={isReportingManagerView}
									/>
								</CustomTabPanel>
							</>
						) : (
							//My View with regularization
							<>
								<CustomTabs value={selectedTabIndex} onChange={handleChange}>
									<CustomTab key={0} label={'My Attendance'} />
									<CustomTab key={1} label='Attendance Request History' />
									{getReportingMangerBasedTabIndex() ? (
										<CustomTab
											key={2}
											label={`Attendance Approval(${
												attendanceCount?.reporteeRequestCount?.aggregate
													?.count || 0
											})`}
										/>
									) : null}
									<CustomTab
										key={getReportingMangerBasedTabIndex() ? 3 : 2}
										label={'My Leave History'}
									/>
									{getReportingMangerBasedTabIndex() ? (
										<CustomTab key={4} label={`Leave Approval(${count})`} />
									) : null}
								</CustomTabs>

								<CustomTabPanel value={selectedTabIndex} index={0}>
									<Attendance
										refetch={() => {
											refetch();
											refetchAttendanceRequestCount();
										}}
										userId={userId}
										date={date}
										currentMonth={currentMonth}
										isManagerView={isManagerView}
										isReportingManagerView={isReportingManagerView}
										userLocationId={userLocationId}
										leaveData={leaveData}
									/>
								</CustomTabPanel>
								<CustomTabPanel value={selectedTabIndex} index={1}>
									<AttendanceHistory userId={userId} />
								</CustomTabPanel>
								{getReportingMangerBasedTabIndex() ? (
									<CustomTabPanel value={selectedTabIndex} index={2}>
										<AttendanceRequestPendingApproval
											userId={userId}
											refetch={() => refetchAttendanceRequestCount()}
										/>
									</CustomTabPanel>
								) : null}
								<CustomTabPanel
									value={selectedTabIndex}
									index={getReportingMangerBasedTabIndex() ? 3 : 2}
								>
									<MyLeaves
										userId={userId}
										userLocationId={userLocationId}
										isReportingManagerView={isReportingManagerView}
										isUserView={isUserView}
									/>
								</CustomTabPanel>
								{getReportingMangerBasedTabIndex() ? (
									<CustomTabPanel value={selectedTabIndex} index={4}>
										<LeaveApproval
											userId={userId}
											refetch={() => refetchPendingCount()}
											isReportingManagerView={true}
										/>
									</CustomTabPanel>
								) : null}
							</>
						)}
					</>
				)}
			</>
		</Box>
	);
};

export default AttendanceContainer;
