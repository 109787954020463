import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { Attendance, Props } from './Attendance.model';
import { range } from 'lodash';

interface Timesheet {
	date: string;
	working_hours: string;
}

export interface TimesheetList {
	timesheet: Timesheet[];
}

export const calculateWorkedHours = (workingHoursLogged: Props) => {
	const workedMillis: number = workingHoursLogged?.attendance
		.map((hour: Attendance) => {
			const inTime = dayjs(`${hour?.date} ${hour?.in_time}`);
			const outTime = dayjs(`${hour?.date} ${hour?.out_time}`);
			return outTime.diff(inTime);
		})
		.reduce((sum: number, total: number) => sum + total);
	const workedHours = dayjs.duration(workedMillis).asHours();
	const formattedWorkedHours = Math.floor(workedHours * 100) / 100;
	return formattedWorkedHours;
};

export const calculatedTimesheetHours = (timesheetList: TimesheetList) => {
	const workedMillis = timesheetList?.timesheet
		?.filter((timesheet) => dayjs(timesheet?.date).isSameOrBefore(dayjs()))
		?.map((timeSheet: Timesheet) => timeSheet?.working_hours?.split(':'))
		.map((hoursAndMinutes: string[]) =>
			dayjs.duration({
				hours: Number(hoursAndMinutes[0]),
				minutes: Number(hoursAndMinutes[1]),
				seconds: Number(hoursAndMinutes[2]),
			})
		)
		.reduce(
			(total: Duration, durations: Duration) => total.add(durations),
			dayjs.duration({
				hours: 0,
				minutes: 0,
				seconds: 0,
			})
		);
	const workedHours = workedMillis.asHours();
	const formattedWorkedHours = Math.floor(workedHours * 100) / 100;
	return formattedWorkedHours || 0;
};

type UserLeaveDay = {
	date: string;
	halfDay: number;
	reason: string;
	leave_type: string;
	isExcluded?: boolean;
};
export const calculatedLeaveCountInSummaryInLeaveData = (
	currentMonth: { startDate: string; endDate: string },
	data:
		| {
				leave_count: string;
				from_date: string;
				to_date: string;
				is_half_day: boolean;
				reason: string;
				org_leave_type: {
					leave_type: string;
					is_leave_sandwich_type: boolean;
				};
		  }[]
		| undefined
) => {
	interface UserLeaveDay {
		date: string;
		halfDay: number;
		reason?: string;
		leave_type?: string;
		isExcluded?: boolean;
	}

	const userLeaveDays: (UserLeaveDay[] | undefined)[] | undefined = data?.map(
		(item: {
			leave_count: string;
			from_date: string;
			to_date: string;
			is_half_day: boolean;
			reason: string;
			org_leave_type: {
				leave_type: string;
				is_leave_sandwich_type: boolean;
			};
		}) => {
			if (
				item?.from_date === item?.to_date &&
				item?.from_date >= currentMonth?.startDate &&
				item?.to_date <= currentMonth?.endDate
			) {
				const userLeaveDay: UserLeaveDay = {
					date: item?.from_date,
					halfDay: item?.is_half_day ? 0.5 : 1,
				};

				if (item?.reason) {
					userLeaveDay.reason = item?.reason;
				}

				if (item?.org_leave_type && item?.org_leave_type?.leave_type) {
					userLeaveDay.leave_type = item?.org_leave_type?.leave_type;
				}

				if (item?.org_leave_type) {
					userLeaveDay.isExcluded =
						item?.org_leave_type?.is_leave_sandwich_type;
				}

				return userLeaveDay;
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date >= currentMonth?.startDate &&
				item?.from_date <= currentMonth?.endDate &&
				item?.to_date <= currentMonth?.endDate &&
				item?.to_date >= currentMonth?.startDate
			) {
				return range(
					dayjs(item.from_date).date() - 1,
					dayjs(item.to_date).date()
				).map((value) => {
					return {
						date: dayjs(item?.from_date)
							.date(value + 1)
							.format('YYYY-MM-DD'),
						halfDay: 1,
						reason: item?.reason,
						leave_type: item?.org_leave_type?.leave_type,
						isExcluded: item?.org_leave_type?.is_leave_sandwich_type,
					};
				});
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date <= currentMonth?.startDate &&
				item?.to_date >= currentMonth?.startDate &&
				item?.to_date <= currentMonth?.endDate
			) {
				return range(
					0,
					dayjs(item.to_date).diff(dayjs(currentMonth?.startDate), 'day') + 1
				).map((value) => {
					const currentDate = dayjs(currentMonth?.startDate).add(value, 'day');
					return {
						date: currentDate.format('YYYY-MM-DD'),
						halfDay: 1,
						reason: item?.reason,
						leave_type: item?.org_leave_type?.leave_type,
						isExcluded: item?.org_leave_type?.is_leave_sandwich_type,
					};
				});
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date >= currentMonth?.startDate &&
				item?.from_date <= currentMonth?.endDate &&
				item?.to_date >= currentMonth?.endDate
			) {
				return range(
					dayjs(item.from_date).date() - 1,
					dayjs(currentMonth?.endDate).date()
				).map((value) => {
					return {
						date: dayjs(item.from_date)
							.date(value + 1)
							.format('YYYY-MM-DD'),
						halfDay: 1,
						reason: item?.reason,
						leave_type: item?.org_leave_type?.leave_type,
						isExcluded: item?.org_leave_type?.is_leave_sandwich_type,
					};
				});
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date <= currentMonth?.startDate &&
				item?.to_date >= currentMonth?.endDate
			) {
				return range(
					dayjs(currentMonth?.startDate).date() - 1,
					dayjs(currentMonth?.endDate).date()
				).map((value) => {
					return {
						date: dayjs(currentMonth?.startDate)
							.date(value + 1)
							.format('YYYY-MM-DD'),
						halfDay: 1,
						reason: item?.reason,
						leave_type: item?.org_leave_type?.leave_type,
						isExcluded: item?.org_leave_type?.is_leave_sandwich_type,
					};
				});
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date >= currentMonth?.startDate &&
				item?.from_date <= currentMonth?.endDate &&
				item?.to_date >= currentMonth?.startDate &&
				item?.to_date >= currentMonth?.endDate
			) {
				return range(
					dayjs(currentMonth?.startDate).date() - 1,
					dayjs(currentMonth?.endDate).date()
				).map((value) => {
					return {
						date: dayjs(item.from_date)
							.date(value + 1)
							.format('YYYY-MM-DD'),
						halfDay: 1,
						reason: item?.reason,
						leave_type: item?.org_leave_type?.leave_type,
						isExcluded: item?.org_leave_type?.is_leave_sandwich_type,
					};
				});
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date >= currentMonth?.endDate &&
				item?.to_date >= currentMonth?.endDate
			) {
				return;
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date <= currentMonth?.startDate &&
				item?.to_date <= currentMonth?.startDate
			) {
				return;
			}
		}
	) as (UserLeaveDay[] | undefined)[] | undefined;

	return userLeaveDays;
};

export const calculatedLeaveCountInSummary = (
	currentMonth: { startDate: string; endDate: string },
	data:
		| {
				leave_count: string;
				from_date: string;
				to_date: string;
				is_half_day: boolean;
		  }[]
		| undefined
) => {
	const userLeaveDays:
		| (
				| {
						date: string;
						halfDay: number;
				  }[]
				| {
						date: string;
						halfDay: number;
				  }
				| undefined
		  )[]
		| undefined = data?.map(
		(item: {
			leave_count: string;
			from_date: string;
			to_date: string;
			is_half_day: boolean;
		}) => {
			if (
				item?.from_date === item?.to_date &&
				item?.from_date >= currentMonth?.startDate &&
				item?.to_date <= currentMonth?.endDate
			) {
				return {
					date: item?.from_date,
					halfDay: item?.is_half_day ? 0.5 : 1,
				};
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date >= currentMonth?.startDate &&
				item?.from_date <= currentMonth?.endDate &&
				item?.to_date <= currentMonth?.endDate &&
				item?.to_date >= currentMonth?.startDate
			) {
				return range(
					dayjs(item.from_date).date() - 1,
					dayjs(item.to_date).date()
				).map((value) => {
					return {
						date: dayjs(item.from_date)
							.date(value + 1)
							.format('YYYY-MM-DD'),
						halfDay: 1,
					};
				});
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date <= currentMonth?.startDate &&
				item?.to_date >= currentMonth?.startDate &&
				item?.to_date <= currentMonth?.endDate
			) {
				return range(
					dayjs(currentMonth?.startDate).date() - 1,
					dayjs(item.to_date).date()
				).map((value) => {
					return {
						date: dayjs(item.from_date)
							.date(value + 1)
							.format('YYYY-MM-DD'),
						halfDay: 1,
					};
				});
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date >= currentMonth?.startDate &&
				item?.from_date <= currentMonth?.endDate &&
				item?.to_date >= currentMonth?.endDate
			) {
				return range(
					dayjs(item.from_date).date() - 1,
					dayjs(currentMonth?.endDate).date()
				).map((value) => {
					return {
						date: dayjs(item.from_date)
							.date(value + 1)
							.format('YYYY-MM-DD'),
						halfDay: 1,
					};
				});
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date <= currentMonth?.startDate &&
				item?.to_date >= currentMonth?.endDate
			) {
				return range(
					dayjs(currentMonth?.startDate).date() - 1,
					dayjs(currentMonth?.endDate).date()
				).map((value) => {
					return {
						date: dayjs(item.from_date)
							.date(value + 1)
							.format('YYYY-MM-DD'),
						halfDay: 1,
					};
				});
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date >= currentMonth?.startDate &&
				item?.from_date <= currentMonth?.endDate &&
				item?.to_date >= currentMonth?.startDate &&
				item?.to_date >= currentMonth?.endDate
			) {
				return range(
					dayjs(currentMonth?.startDate).date() - 1,
					dayjs(currentMonth?.endDate).date()
				).map((value) => {
					return {
						date: dayjs(item.from_date)
							.date(value + 1)
							.format('YYYY-MM-DD'),
						halfDay: 1,
					};
				});
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date >= currentMonth?.endDate &&
				item?.to_date >= currentMonth?.endDate
			) {
				return;
			}
			if (
				item?.from_date !== item?.to_date &&
				item?.from_date <= currentMonth?.startDate &&
				item?.to_date <= currentMonth?.startDate
			) {
				return;
			}
		}
	);
	return userLeaveDays;
};

export const APPROVE_LEAVE_MESSAGE = 'Ready to approve';
export const REJECT_LEAVE_MESSAGE = 'Ready to reject';
export const START_DATE_ERROR =
	'Start date should not be greater than end date';
export const LEAVE_MESSAGE = 'Selected Days is greater than available balance';
export const leaveReportHeader = [
	'RESOURCE ID',
	'RESOURCE NAME',
	'EMAIL',
	'RESOURCE TYPE',
	'REPORTING TO',
	'REPORTING TO EMAIL',
	'DEPARTMENT',
];
export const LEAVE_BALACE_EXPORT_CLOUMNS = [
	'LEAVE TYPE',
	'OP:BALANCE',
	'ACCRUED',
	'APPLIED',
	'BALANCE',
];

export const Weekend = 'Weekend';
export const Absent = 'Absent';
export const holidayText = 'Holiday';
export const Present = 'Present';
export const Leave = 'Leave';
export const calendarText = 'Calendar';
export const halfDay = '(Half a day)';
export const LEAVE_STATUS_INFO =
	'The leave type will be displayed here if an employee has taken a leave irrespective of their attendance.';

export const ATTENDANCE_EXPORT_DROPDOWN_OPTIONS = [
	'All',
	'Active Users',
	'Inactive Users',
];
export const ATTENDANCE_PENDING_STATUS_MESSAGE =
	'Attendance request is pending for approval';

export const DATE_FORMAT_FOR_ATTENDANCE_REGULARIZATION = 'YYYY-MM-DD';

export const LEAVE_BALANCE_EXPORT_VALUES = {
	basic_report: 'basic_leave_balance_report',
	detailed_report: 'detailed_leave_balance_report',
};

export const LEAVE_BALANCE_EXPORT_OPTIONS = [
	{
		id: LEAVE_BALANCE_EXPORT_VALUES.basic_report,
		name: 'Basic leave balance report',
	},
	{
		id: LEAVE_BALANCE_EXPORT_VALUES.detailed_report,
		name: 'Detailed leave balance report',
	},
];

export const BASIC_LEAVE_BALANCE_REPORT_INFO = 'The basic leave balance report includes only basic information, such as employee details, leave type, balance, etc.'
export const DETAILED_LEAVE_BALANCE_REPORT_INFO = 'The detailed leave balance report includes additional information, such as accrued count, applied count, balance, etc.'	
export const FAILED_TO_ADD_REJECTION_NOTES = "Failed to add rejection notes"

export const ABSENTEE_REPORT_STATUS_DROPDOWN_OPTIONS = [
	{id: 'absent',name:'Absent'},
	{id: 'leave',name:'Leave'}

]