import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { compact, flatten, head, isEmpty, orderBy, range, sum } from 'lodash';
import dayjs from 'dayjs';
import {
	Attendance,
	SelectedMonthDates,
	LeaveBalanceProps,
	LeaveCountProps,
} from './Attendance.model';
import {
	useGetAttendanceQuery,
	useGetLeaveBalanceQuery,
	useGetEmployeeLeaveQuery,
	useGetEmployeeJoiningDateQuery,
	useCurrentMonthAttendanceLeaveInAttendanceSummaryQuery,
	useCurrentMonthAttendanceLeaveInfoQuery,
	useGetOrganisationLeaveCalenderYearQuery,
} from '../../generated/graphql';
import { calculateCurrentLeaveCalenderYear } from '../../Reports/Constant';
import { UserProfileContext } from '../../App';
import LeaveSummary from './LeaveSummary.component';
import CheckIn from './CheckIn.component';
import Calender from './Calender.component';
import AttendanceContainer from './AttendanceContainer.component';

import {
	calculateWorkedHours,
	calculatedLeaveCountInSummaryInLeaveData,
	calculatedTimesheetHours,
} from './Constant';
import { Style } from './Style';
import useExcludeLeaveDates from './useExcludeLeaveDates';

interface Props {
	employeeId: string;
	attendance?: string[];
}
interface LeaveDate {
	date: string;
	halfDay: number;
	reason: string;
	leave_type: string;
	isExcluded: boolean;
}
const MyAttendance = (props: Props) => {
	const { employeeId } = props;
	const {
		id: userId,
		locationId: userLocationId,
		orgId: organisationId,
		attendanceConsiderableTimesheetStatus,
	} = useContext<any>(UserProfileContext);
	const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
	const endDate = dayjs().endOf('month').format('YYYY-MM-DD');
	const [todaysAttendance, setTodaysAttendance] = useState<Attendance>({});
	const [currentMonth, setCurrentMonth] = useState({
		startDate,
		endDate,
	});
	const date = range(dayjs(currentMonth?.startDate).endOf('month').date()).map(
		(value) => dayjs(currentMonth?.startDate).date(value + 1)
	);
	const [currentLeaveYear, setCurrentLeaveYear] = useState<SelectedMonthDates>({
		startDate: '',
		endDate: '',
	});

	const [timesheetHours, setTimesheetHours] = useState<number>(0);

	const [leaveDatesWithReason, setLeaveDatesWithReason] = useState<LeaveDate[]>(
		[]
	);
	const leaveDates = useExcludeLeaveDates(
		leaveDatesWithReason,
		currentMonth.startDate,
		currentMonth.endDate,
		userLocationId,
		userId
	);
	const [leaveApprover, setLeaveApprover] = useState('');

	const [organizationLeaves, setOrganizationLeaves] =
		useState<LeaveBalanceProps[]>();
	const [workHoursLogged, setWorkedHourslogged] = useState<number>(0);

	const { data: orgLeaves, refetch: refetchLeaveBalance } =
		useGetLeaveBalanceQuery({
			variables: {
				employeeId,
				startDate: currentLeaveYear?.startDate,
				endDate: currentLeaveYear?.endDate,
			},
			fetchPolicy: 'network-only',
			skip: !employeeId || isEmpty(currentLeaveYear),
		});

	const { refetch: refetchLeaveList } = useGetEmployeeLeaveQuery({
		variables: {
			userId,
			financialStartDate: currentLeaveYear?.startDate,
			financialEndDate: currentLeaveYear?.endDate,
			employeeLeaveStatusId: {},
			employeeId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: attendanceAndLeaveInSummary, refetch: refetchWorkHours } =
		useCurrentMonthAttendanceLeaveInAttendanceSummaryQuery({
			variables: {
				userId,
				orgId: organisationId,
				startDate: currentMonth?.startDate,
				endDate: currentMonth?.endDate,
				statusList: attendanceConsiderableTimesheetStatus,
			},
			fetchPolicy: 'network-only',
		});

	const { data: leaveSummaryData, refetch: refetchLeaveSummary } =
		useCurrentMonthAttendanceLeaveInfoQuery({
			variables: {
				userId,
				startDate: currentMonth?.startDate,
				endDate: currentMonth?.endDate,
			},
			fetchPolicy: 'network-only',
		});

	const { refetch: refetchJoiningYear } = useGetEmployeeJoiningDateQuery({
		variables: { userId: employeeId },
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		if (!attendanceAndLeaveInSummary || !leaveSummaryData) {
			return;
		}
		const leaveCountData = leaveSummaryData
			? head(leaveSummaryData?.approvedLeaveCount)?.employee_leaving_mappings
				? head(leaveSummaryData?.approvedLeaveCount)?.employee_leaving_mappings
				: []
			: [];

		const userLeaveDays = calculatedLeaveCountInSummaryInLeaveData(
			currentMonth,
			leaveCountData
		);

		const leaveDaysArray = flatten(userLeaveDays);

		const leaveDays = compact(leaveDaysArray);

		const leaveDataArray: LeaveDate[] = leaveDays?.map((leaveDay) => ({
			date: leaveDay?.date,
			halfDay: leaveDay?.halfDay,
			reason: leaveDay?.reason || '',
			leave_type: leaveDay?.leave_type || '',
			isExcluded: leaveDay?.isExcluded ?? false,
		}));

		setLeaveDatesWithReason(leaveDataArray);
	}, [attendanceAndLeaveInSummary, currentMonth, leaveSummaryData]);

	const leaveCount = sum(leaveDates?.map((e: any) => e?.halfDay));

	const { data: organisationLeaveCalenderYear } =
		useGetOrganisationLeaveCalenderYearQuery({
			variables: { organisationId },
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		if (!attendanceAndLeaveInSummary) {
			setLeaveApprover('');
			return;
		}
		if (attendanceAndLeaveInSummary?.leaveApprove) {
			const approver =
				attendanceAndLeaveInSummary?.leaveApprove?.user_type === 'employee'
					? `${
							head(attendanceAndLeaveInSummary?.leaveApprove?.employees)
								?.reportsTo?.full_name || '--'
					  }`
					: `${
							head(attendanceAndLeaveInSummary?.leaveApprove?.contractors)
								?.reportee?.full_name || '--'
					  }`;
			setLeaveApprover(approver);
		}
		if (!attendanceAndLeaveInSummary) {
			setWorkedHourslogged(0);
			return;
		}
		const workedHours =
			attendanceAndLeaveInSummary?.attendance &&
			attendanceAndLeaveInSummary?.attendance?.length > 0
				? calculateWorkedHours(attendanceAndLeaveInSummary)
				: 0;
		setWorkedHourslogged(workedHours);

		if (!attendanceAndLeaveInSummary?.timesheet) {
			return;
		}
		const timesheetHours = calculatedTimesheetHours(
			attendanceAndLeaveInSummary
		);
		setTimesheetHours(timesheetHours);
	}, [attendanceAndLeaveInSummary, leaveApprover]);

	useEffect(() => {
		if (
			!(
				organisationLeaveCalenderYear &&
				organisationLeaveCalenderYear?.organization_by_pk
			)
		) {
			return;
		}
		const leaveCalenderData = organisationLeaveCalenderYear?.organization_by_pk;
		if (!leaveCalenderData) {
			return;
		}
		const calculatedDate = calculateCurrentLeaveCalenderYear({
			leave_calendar_year_start_month:
				leaveCalenderData.leave_calendar_year_start_month,
			leave_calendar_year_start_date:
				leaveCalenderData?.leave_calendar_year_start_date,
			leave_calendar_year_end_date:
				leaveCalenderData?.leave_calendar_year_end_date,
			leave_calendar_year_end_month:
				leaveCalenderData?.leave_calendar_year_end_month,
		});
		if (!calculatedDate) {
			return;
		}
		setCurrentLeaveYear({
			startDate: calculatedDate?.startDate || '',
			endDate: calculatedDate?.endDate || '',
		});
	}, [organisationLeaveCalenderYear]);

	const classes = Style();
	const { data: attendance, refetch: refetchAttendance } =
		useGetAttendanceQuery({
			variables: {
				userId,
				date: dayjs().format('YYYY-MM-DD'),
			},
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		if (isEmpty(orgLeaves?.org_leave_group)) {
			return;
		}
		const response = head(orgLeaves?.org_leave_group)?.org_leave_types?.map(
			(value) => {
				const appliedLeaveCount = value?.employee_leaving_mappings?.reduce(
					(sum: number, number: LeaveCountProps) => {
						return number?.leave_count + sum;
					},
					0
				);
				const accruedLeaveCount =
					value?.accrued_leave_type?.length > 0
						? value?.accrued_leave_type?.reduce((sum, number) => {
								return number?.count + sum;
						  }, 0)
						: 0;
				const openingBalance =
					value?.employee_leave_type_mappings?.length > 0
						? head(value?.employee_leave_type_mappings)?.opening_balance
						: '--';
				return {
					id: value?.id,
					name: value?.leave_type,
					defaultAmount: openingBalance,
					accruedLeaveCount: accruedLeaveCount,
					applied: Number(appliedLeaveCount) || 0,
					balance:
						accruedLeaveCount + openingBalance - Number(appliedLeaveCount) || 0,
					renewalDate:
						head(value?.employee_leave_type_mappings)?.renewal_date || null,
					accruedId: head(value?.accrued_leave_type)?.id || null,
					leaveTypeId: head(value?.employee_leave_type_mappings)?.id || null,
				};
			}
		);
		const sortedResponse = orderBy(response, ['name'], ['asc']);
		setOrganizationLeaves(sortedResponse);
	}, [orgLeaves, currentLeaveYear]);

	useEffect(() => {
		if (!attendance || !(attendance?.attendance?.length > 0)) {
			setTodaysAttendance({});
			return;
		}
		const todaysAttendance = head(attendance.attendance);
		setTodaysAttendance({
			in_time: todaysAttendance?.in_time,
			out_time: todaysAttendance?.out_time || '',
			date: todaysAttendance?.date,
			id: todaysAttendance?.id,
		});
	}, [attendance]);

	return (
		<Box>
			<Box display='flex' marginTop='14px'>
				<Box className={classes.summaryContainer}>
					{organizationLeaves && organizationLeaves?.length > 0 ? (
						<Box>
							<LeaveSummary
								refetch={() => {}}
								organizationLeaves={organizationLeaves}
							/>
						</Box>
					) : (
						<Box>
							<Box className={classes.summaryTitle}>Leave Summary</Box>
							<Box className={classes.noData}>
								No leave types assigned.
								<br />
								Please contact admin
							</Box>
						</Box>
					)}
				</Box>
				<Box>
					<Box className={classes.attendanceContainer}>
						<CheckIn
							refetchAttendance={refetchAttendance}
							refetchWorkHours={() => {
								refetchWorkHours();
								refetchLeaveSummary();
							}}
							todaysAttendance={todaysAttendance}
						/>
					</Box>
					<Box className={classes.calenderContainer}>
						<Calender
							currentMonth={currentMonth}
							setCurrentMonth={(startDate: string, endDate: string) => {
								setCurrentMonth({
									startDate,
									endDate,
								});
							}}
							userId={userId}
							userLocationId={userLocationId}
							leaveApprover={leaveApprover || '-'}
							workHoursLogged={workHoursLogged}
							currentFinancialYear={currentLeaveYear}
							orgId={organisationId}
							timesheetHours={timesheetHours}
							currentLeaveCount={leaveCount}
							employeeId={employeeId}
							refetchLeaveBalance={() => {
								refetchJoiningYear();
								refetchLeaveList();
								refetchLeaveBalance();
							}}
							leaveData={leaveDates || []}
							isManagerView={false}
							isReportingManagerView={false}
						/>
					</Box>
				</Box>
			</Box>
			<Box>
				<AttendanceContainer
					refetch={() => {
						refetchWorkHours();
						refetchLeaveSummary();
						refetchAttendance();
					}}
					date={date}
					userId={userId}
					userLocationId={userLocationId}
					currentMonth={currentMonth}
					isManagerView={false}
					isReportingManagerView={false}
					leaveData={leaveDates || []}
					isUserView={true}
				/>
			</Box>
		</Box>
	);
};

export default MyAttendance;
