import {
	Box,
	Button,
	Dialog,
	ThemeProvider,
	Typography,
	Switch,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
	TextInput,
	SelectInput,
	ReferenceInput,
	required,
	useNotify,
	useRefresh,
	maxLength,
	number,
} from 'react-admin';
import { Form } from 'react-final-form';
import React, { useEffect, useState } from 'react';
import {
	ORG_LOCATION_CREATION_TEXT,
	ORG_LOCATION_UPDATION_TEXT,
	WARNING_MESSAGE,
} from './constant';
import { modalFormStyle } from '../../../Layout/styles';
import { useQuery as useApolloQuery } from '@apollo/client';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { GET_ORG_TIMEZONE_OPTIONS } from './TimeZone.gql';
import KanbanWarningModal from '../Kanban/kanbanWarning.component';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import {
	useUpsertOrgLocationMutation,
	useUpdateLocationDefaultMutation,
} from '../../../generated/graphql';
import { orderBy } from 'lodash';
import ButtonWithLoader from '../../../SharedComponents/Btn/ButtonWithLoader';

export interface Address {
	postal_code: string;
	address_line_1: string;
	address_line_2: string;
}

export interface LocationProp {
	address?: Address;
	country_id?: string;
	id?: string;
	is_default?: boolean;
	name?: string;
	timezone?: string;
	geo_id?: string;
	org_id?: string;
}

interface AddLocationFormProps {
	open: boolean;
	onClose: () => void;
	locationData?: LocationProp;
}

const AddLocationForm = (props: AddLocationFormProps) => {
	const { open, onClose, locationData } = props;
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [isDefault, setIsDefault] = useState(false);
	const [timezone, setTimezone] = useState<string | null>(
		locationData?.timezone || null
	);
	const classes = modalFormStyle();
	const notify = useNotify();
	const userefresh = useRefresh();

	const { data: organisationTimezoneOptions } = useApolloQuery(
		GET_ORG_TIMEZONE_OPTIONS
	);
	useEffect(() => {
		if (!locationData) {
			return;
		}
		setTimezone(locationData?.timezone || null);
	}, [locationData]);

	const [upsertOrgLocation, { loading: isLocationLoading }] =
		useUpsertOrgLocationMutation();
	const [updateLocationDefault] = useUpdateLocationDefaultMutation();

	const onSave = (formValue: LocationProp) => {
		if (isDefault) {
			updateLocationDefault({})
				.then((data) => {})
				.catch((error: any) => {});
		}
		const { is_default, org_id, ...restFormValues } = formValue;
		upsertOrgLocation({
			variables: {
				orgLocation: {
					...restFormValues,
					timezone,
					is_default: isDefault ? isDefault : locationData?.is_default,
				},
			},
		}).then((data) => {
			notify(
				formValue?.id ? ORG_LOCATION_UPDATION_TEXT : ORG_LOCATION_CREATION_TEXT
			);
			userefresh();
			setTimezone(null);
			onClose();
			setIsDefault(false);
		});
	};

	const handleOk = () => {
		setIsWarningModalShown(false);
		setTimezone(null);
		setIsDefault(true);
	};

	const handleModalClose = () => {
		setIsWarningModalShown(false);
		setTimezone(null);
		setIsDefault(false);
	};

	const getTimezone = () => {
		const response =
			organisationTimezoneOptions?.master_settings[0]?.value.find(
				(value: any) => value?.id === timezone
			);
		return response ? { id: response?.id, name: response?.name } : null;
	};

	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={onClose}
					aria-labelledby='dialog-titleonModalClose'
					aria-describedby='dialog-description'
				>
					<Box className={classes.container}>
						<Box className={classes.headerContainer}>
							<Box className={classes.heading}>
								{locationData?.id ? 'EDIT LOCATION' : 'ADD LOCATION'}
							</Box>
							<CloseIcon onClick={onClose} className={classes.closeIcon} />
						</Box>
						<Form onSubmit={onSave} initialValues={locationData}>
							{({ handleSubmit, invalid, pristine }) => {
								const isSaveDisabled =
									(pristine && !isDefault) || invalid || isLocationLoading;
								return (
									<form onSubmit={handleSubmit}>
										<Box>
											<Typography className={classes.label}>
												Location Name *
											</Typography>
											<TextInput
												source='name'
												validate={required()}
												fullWidth={true}
												label={''}
											/>
											<Typography className={classes.label}>
												Address1
											</Typography>
											<TextInput
												source='address.address_line_1'
												id='address_line_1'
												fullWidth={true}
												label={''}
											/>
											<Typography className={classes.label}>
												Address2
											</Typography>
											<TextInput
												source='address.address_line_2'
												id='address_line_2'
												fullWidth={true}
												label={''}
											/>
											<Typography className={classes.label}>
												Timezone
											</Typography>
											<AutocompleteSearch
												placeholder={'Search timezone'}
												option={orderBy(
													organisationTimezoneOptions?.master_settings[0]
														?.value,
													['name'],
													['asc']
												)}
												onChange={setTimezone}
												value={getTimezone()}
												name={'timezone'}
												validates={false}
											/>
											<Typography className={classes.label}>Country</Typography>
											<ReferenceInput
												source='country_id'
												id='country'
												reference='geo_list'
												sort={{ field: 'name', order: 'ASC' }}
												label=''
											>
												<SelectInput optionText='name' />
											</ReferenceInput>
											<Typography className={classes.label}>Zipcode</Typography>
											<TextInput
												source='address.postal_code'
												id='postal_code'
												label=''
												validate={[number(), maxLength(6)]}
											/>
											{locationData && locationData?.id && (
												<Box display='flex'>
													<Box className={classes.label} marginTop='4px'>
														Mark this Location as default
													</Box>
													<Box>
														<Switch
															color='primary'
															name='is_default'
															disabled={locationData?.is_default}
															checked={locationData?.is_default || isDefault}
															onChange={(event: any) => {
																if (isDefault) {
																	setIsDefault(false);
																	setIsWarningModalShown(false);
																	return;
																}
																setIsWarningModalShown(true);
															}}
														/>
													</Box>
												</Box>
											)}
										</Box>
										<Box className={classes.floatingButtonContainer}>
											<Button
												onClick={onClose}
												className={classes.cancelButton}
											>
												Cancel
											</Button>
											<ButtonWithLoader
												isDisabled={isSaveDisabled}
												title='Save'
												isLoading={isLocationLoading}
											/>
										</Box>
									</form>
								);
							}}
						</Form>
					</Box>
				</Dialog>
			</ThemeProvider>
			{
				<KanbanWarningModal
					open={isWarningModalShown}
					onClose={handleModalClose}
					onConfirm={handleOk}
					warningMessage={WARNING_MESSAGE}
				/>
			}
		</>
	);
};

export default AddLocationForm;
