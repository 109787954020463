import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import {
	Box,
	Tooltip,
	Button,
	ThemeProvider,
	TextField as TextComponent,
	makeStyles,
	CircularProgress,
	IconButton,
} from '@material-ui/core';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEmpty } from 'lodash';

import { searchBarTheme } from '../../../Layout/Theme.component';
import { modalFormStyle } from '../../../Layout/styles';
import { DataGridTheme } from '../pmsTheme';
import VisionForm from './VisionForm.component';
import {
	useDeletePmsVisionMutation,
	useGetVisionsForExportQuery,
	useGetVisionsQuery,
} from '../../../generated/graphql';
import { pmsDatagridStyle } from '../pms.styles';
import { VisionFormInitialValue } from '../pms.module';
import {
	exportToCsv,
	preventSubsequentClick,
} from '../../../Utils/string.util';
import {
	DELETE_ALERT_MSG,
	UNABLE_TO_DELETE_MSG,
	VISION_DELETE_FAILURE_MSG,
	VISION_DELETE_SUCCESS_MSG,
	VISION_EXPORT_COLUMNS,
	WHITE_CIRCULAR_PROGRESS_LOADER,
} from './constant';
import { SearchIcon } from '../../../assets/SvgIcons';
import WarningModal from '../../../SharedComponents/WarningModal.component';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import Loader from '../SharedComponents/Loader.component';
const dataGridStyle = makeStyles(() => ({
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		minWidth: '70px',
		height: '34px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		'&:hover': {
			background: 'lightgrey',
		},
	},
	text: {
		fontFamily: 'Manrope-medium',
		fontSize: '11px',
		cursor: 'pointer',
		fontWeight: 400,
	},
	actionButtonWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '12px',
	},
}));

const CustomSearchRender = (props: any) => {
	const { searchText, setSearchedText } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Visions'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => {
						setSearchedText(event?.target?.value);
					}}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

function VisionList() {
	const notify = useNotify();
	// STATE
	const [isVisionFormShown, setIsVisionFormShown] = useState(false);
	const [isEditMutation, setIsEditMutation] = useState(false);

	const [searchedText, setSearchedText] = useState<string>('');
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [page, setPage] = useState<number>(0);
	const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);

	const [visionData, setVisionData] = useState<
		{ name: string; description: string }[]
	>([]);
	const [row, setRow] = useState<VisionFormInitialValue>({});
	const [sortFilter, setSortFilter] = useState<any[]>([{ name: 'asc' }]);
	const [isWarningModalShown, setIsWarningModalShown] =
		useState<boolean>(false);
	const [isDeleteDialogModalShown, setIsDeleteDialogModalShown] =
		useState<boolean>(false);
	const [visionId, setVisionId] = useState<string | null>(null);

	const formStyles = modalFormStyle();
	const dataGridStyles = dataGridStyle();
	const listStyles = pmsDatagridStyle();

	// API CALLS
	const {
		data: visions,
		loading: isVisionLoading,
		refetch: refetchVisions,
	} = useGetVisionsQuery({
		variables: {
			limit: rowsPerPage,
			offset: rowsPerPage * page,
			name: `%${searchedText}%`,
			order: sortFilter,
		},
		fetchPolicy: 'network-only',
	});

	// Mutation
	const [deleteVision, { loading: isDeleteOperationLoading }] =
		useDeletePmsVisionMutation();

	// EXPORT
	const { data: exportVisionList } = useGetVisionsForExportQuery({
		variables: {
			name: `%${searchedText}%`,
			order: sortFilter,
		},
		fetchPolicy: 'network-only',
	});

	// Handlers
	const checkMapping = (
		selectedVisionId: string,
		mappedAppraisalCount: number
	) => {
		if (mappedAppraisalCount) {
			setIsDeleteDialogModalShown(true);
			return;
		}
		setVisionId(selectedVisionId);
		setIsWarningModalShown(true);
	};
	const handleDeleteVision = () => {
		deleteVision({
			variables: {
				visionId,
			},
		})
			.then((data: any) => {
				notify(VISION_DELETE_SUCCESS_MSG, 'warning');
				setVisionId(null);
				setIsWarningModalShown(false);
				refetchVisions();
			})
			.catch((error: any) => {
				notify(VISION_DELETE_FAILURE_MSG, 'warning');
				setVisionId(null);
				setIsWarningModalShown(false);
			});
	};
	useEffect(() => {
		if (!visions) return;
		const data = visions.pms_pms_vision.map((vision) => ({
			name: vision.name,
			description: vision?.description || '- -',
			mappedAppraisalCount:
				vision?.pms_appraisal_vision_mappings_aggregate?.aggregate?.count || 0,
			action: {
				...vision,
				mappedAppraisalCount:
					vision?.pms_appraisal_vision_mappings_aggregate?.aggregate?.count ||
					0,
			},
		}));
		setVisionData(data);
	}, [visions]);

	const VISION_COLUMNS = [
		{
			name: 'name',
			label: 'VISION',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'description',
			label: 'DESCRIPTION',
			options: {
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'action',
			label: 'ACTION',
			options: {
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const {
						id: selectedVisionId,
						mappedAppraisalCount,
						...restValues
					} = value;
					return (
						<Box className={dataGridStyles.actionButtonWrapper}>
							<Box className={listStyles.listEditIconContainer}>
								<IconButton
									className={listStyles.listEditIconButton}
									onClick={() => {
										setRow({
											id: selectedVisionId,
											...restValues,
										});
										setIsEditMutation(true);
										setIsVisionFormShown(true);
									}}
								>
									<EditIcon className={listStyles.editIcon} />
								</IconButton>
							</Box>
							<Box className={listStyles.listEditIconContainer}>
								<IconButton
									className={`${listStyles.listEditIconButton} ${listStyles.deleteIconButton}`}
									onClick={() =>
										checkMapping(selectedVisionId, mappedAppraisalCount)
									}
								>
									<DeleteIcon className={listStyles.editIcon} />
								</IconButton>
							</Box>
						</Box>
					);
				},
			},
		},
	];

	const handleExport = () => {
		if (isEmpty(exportVisionList?.pms_pms_vision)) {
			return;
		}

		const visionData =
			exportVisionList?.pms_pms_vision?.map((vision) => {
				return [vision?.name || '--', vision?.description || '--'];
			}) || [];

		const exportData = [VISION_EXPORT_COLUMNS, ...[...(visionData || [])]];
		exportToCsv('Vision.csv', exportData);
	};

	const disableButton = () => {
		setButtonDisabled(false);
	};
	const preventClick = preventSubsequentClick(disableButton, 1000);

	const onExportClick = () => {
		if (!isButtonDisabled) {
			setButtonDisabled(true);
			handleExport();
			preventClick();
		}
	};
	const options: MUIDataTableOptions = {
		filter: false,
		print: false,
		rowsPerPage: rowsPerPage,
		download: false,
		page: page,
		serverSide: true,
		rowsPerPageOptions: [5, 10, 20, 30],
		search: false,
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectableRowsHeader: false,
		count: visions?.pms_pms_vision_aggregate.aggregate?.count || 0,
		textLabels: {
			body: {
				noMatch: isVisionLoading?
				(<Loader/>
				):(
					'No data found'
				)
			},
		},
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
		},
		onTableChange: (action: string, tableState: any) => {
			tableState.data.length === 0 ? setPage(0) : setPage(tableState.page);
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				default:
					return;
			}
		},
		onColumnSortChange: (changedColumn: string, direction: string) => {
			switch (changedColumn) {
				case 'name': {
					setSortFilter([{ name: direction }]);
					break;
				}

				default: {
					setSortFilter([]);
				}
			}
		},
	};

	return (
		<div>
			<div>
				<div>
					<Box
						display='flex'
						justifyContent='space-between'
						marginBottom='20px'
						marginTop='20px'
					>
						<Box display='flex' alignItems='center' sx={{ gridGap: '8px' }}>
							<Box>
								<CustomSearchRender
									searchText={searchedText}
									setSearchedText={setSearchedText}
								/>
							</Box>
							{isVisionLoading && (
								<Box>
									<CircularProgress size={25} />
								</Box>
							)}
						</Box>
						<Box display='flex' sx={{ gridGap: '8px' }}>
							<Box>
								<Button
									className={
										isEmpty(visionData) || isButtonDisabled
											? dataGridStyles.disabledButton
											: dataGridStyles.exportButton
									}
									endIcon={<ArrowUpwardIcon />}
									onClick={onExportClick}
									disabled={isEmpty(visionData)}
									startIcon={
										isButtonDisabled && (
											<CircularProgress
												style={{
													height: '20px',
													width: '20px',
													color: '#FFFFFF',
												}}
											/>
										)
									}
								>
									Export
								</Button>
							</Box>
							<Box>
								<Button
									variant='contained'
									color='primary'
									className={formStyles.saveButton}
									startIcon={<AddIcon />}
									onClick={() => {
										setIsEditMutation(false);
										setIsVisionFormShown(true);
									}}
								>
									Create Vision
								</Button>
							</Box>
						</Box>
					</Box>
					<div>
						<ThemeProvider theme={DataGridTheme}>
							<MUIDataTable
								title='Visions'
								data={visionData}
								columns={VISION_COLUMNS}
								options={options}
							/>
						</ThemeProvider>

						<VisionForm
							open={isVisionFormShown}
							initialValues={row}
							onClose={() => {
								setRow({});
								setIsEditMutation(false);
								setIsVisionFormShown(false);
							}}
							onSuccess={() => {
								setRow({});
								setIsEditMutation(false);
								refetchVisions();
								setIsVisionFormShown(false);
							}}
							isEditMutation={isEditMutation}
						/>
						<DeleteModal
							open={isWarningModalShown}
							onClose={() => {
								setVisionId(null);
								setIsWarningModalShown(false);
							}}
							confirmationMessage={DELETE_ALERT_MSG}
							onDelete={() => handleDeleteVision()}
							isLoading={isDeleteOperationLoading}
							loaderColor={WHITE_CIRCULAR_PROGRESS_LOADER}
						/>
						<WarningModal
							open={isDeleteDialogModalShown}
							onClose={() => {
								setIsDeleteDialogModalShown(false);
							}}
							warningMessage={UNABLE_TO_DELETE_MSG}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default VisionList;
