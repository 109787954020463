import React, { useState, useEffect, useContext } from 'react';
import {
	Button,
	Avatar,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	ThemeProvider,
	TableRow,
	withStyles,
	createStyles,
	makeStyles,
	Checkbox,
	Box,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import { SelectInput } from 'react-admin';
import { Loading } from 'ra-ui-materialui';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
	useApproveLeaveMutation,
	useDeleteAppliedLeaveMutation,
	useEmployeeLeaveStatusQuery,
	useGetAppliedLeavesForApprovalQuery,
	useGetEmployeeDetailsByUserIdQuery,
	useGetLeaveSettingsSubscription,
	useGetOrganisationLeaveCalenderYearQuery,
	useInsertLeaveSubmissionNotesMutation,
} from '../../generated/graphql';
import LeaveApproveRejectModal from './LeaveApproveRejectModal.component';
import { modalFormTheme } from '../../Layout/Theme.component';
import { head, isEmpty, orderBy, uniq } from 'lodash';
import { Style } from './Style';
import {
	LeaveProps,
	EmployeeLeavingMapping,
	SelectedMonthDates,
	ApplyLeaveFormValues,
} from './Attendance.model';
import { DropdownOption } from '../../SharedComponents/model';
import { datagridStyle, ellipsisStyle } from '../../Layout/styles';
import dayjs from 'dayjs';
import {
	APPROVE_LEAVE_MESSAGE,
	REJECT_LEAVE_MESSAGE,
	FAILED_TO_ADD_REJECTION_NOTES,
} from './Constant';
import no_data_found from '../../assets/no_data_found.png';
import { userStatusOptions } from '../constant';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ApplyLeaveForm from './ApplyLeaveForm.component';
import { calculateCurrentLeaveCalenderYear } from '../../Reports/Constant';
import { UserProfileContext } from '../../App';
import WarningConfirmationModal from '../../SharedComponents/WarningConfirmationModal.component';
import { ENABLE_OVERTIME_CONFIRMATION } from '../Settings/constant';

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

interface Props {
	userId: string;
	refetch: () => void;
	isReportingManagerView: boolean | undefined;
}

const inlineStyle = makeStyles({
	dropdown: {
		background: '#ffffff',
		alignItems: 'left',
	},
	container: {
		'&:hover': {
			'& $listEditIconButton': {
				display: 'flex',
			},
			'& $deleteIconContainer': {
				display: 'flex',
			},
		},
	},
	label: {
		font: 'normal normal bold 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '10px !important',
	},
	saveButton: {
		height: '36px',
		background: '#05950a 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#05950a',
		},
	},
	tableBody: {
		width: '10px',
	},
	listEditIconContainer: {
		display: 'flex',
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '4px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	deleteIconContainer: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '4px',
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
});

const LeaveApproval = (props: Props) => {
	const { userId, refetch, isReportingManagerView } = props;
	const inlineCustomStyle = inlineStyle();
	const { orgId } = useContext<any>(UserProfileContext);
	const [submittedLeaves, setSubmittedLeaves] = useState<LeaveProps[]>();
	const [isApproveRejectModalOpen, setIsApproveRejectModalOpen] =
		useState(false);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [leaveSubmittedId, setLeaveSubmittedId] = useState<string[]>([]);
	const [selectedUserId, setSelectedUserId] = useState<any>([]);
	const [leaveStatusId, setLeaveStatusId] = useState('');
	const [selectedLeaveIdForDelete, setSelectedLeaveIdForDelete] = useState<
		string | null
	>();
	const [leaveStatusDropDown, setLeaveStatusDropDown] =
		useState<DropdownOption[]>();
	const [employeeStatus, setEmployeeStatus] = useState<string | boolean>('All');
	const [selectedLeaveDetails, setSelectedLeaveDetails] =
		useState<ApplyLeaveFormValues | null>();
	const [currentLeaveYear, setCurrentLeaveYear] = useState<SelectedMonthDates>({
		startDate: '',
		endDate: '',
	});

	const [selectedResourceUserId, setSelectedResourceUserId] = useState('');

	const style = Style();
	const styles = ellipsisStyle();
	const notify = useNotify();
	const datagridStyles = datagridStyle();

	const { data: leaveSettings } = useGetLeaveSettingsSubscription({
		variables: {
			orgId,
		},
		fetchPolicy: 'network-only',
	});

	const enableLeave = leaveSettings?.organization_by_pk?.enable_leave ?? false;

	const { data: employee } = useGetEmployeeDetailsByUserIdQuery({
		variables: { userId: userId },
		skip: !userId,
	});
	const { data: selectedEmployeeDetails } = useGetEmployeeDetailsByUserIdQuery({
		variables: { userId: selectedResourceUserId },
		skip: !selectedResourceUserId,
	});

	const selectedResourceLocationId =
		head(selectedEmployeeDetails?.employee)?.location_id || null;

	const { data: organisationLeaveCalenderYear } =
		useGetOrganisationLeaveCalenderYearQuery({
			variables: { organisationId: orgId },
			fetchPolicy: 'network-only',
		});
	const [approveLeaveMutation, { loading: approveLeaveLoader }] =
		useApproveLeaveMutation();
	const [deleteAppliedLeaveMutation, { loading: handleDeleteLeaveLoader }] =
		useDeleteAppliedLeaveMutation();
	const {
		data: submittedLeave,
		refetch: refetchSubmittedLeave,
		loading: submittedLeaveLoading,
	} = useGetAppliedLeavesForApprovalQuery({
		variables: {
			userId,
			statusId: leaveStatusId === 'All' ? {} : { _eq: leaveStatusId },
			employeeStatus:
				employeeStatus === 'All'
					? {}
					: employeeStatus === false
					? { is_active: { _eq: false } }
					: { is_active: { _eq: true } },
			startDate: currentLeaveYear?.startDate,
			endDate: currentLeaveYear?.endDate,
		},
		fetchPolicy: 'network-only',
		skip: !employee || !userId || !currentLeaveYear,
	});

	const { data: leaveStatus } = useEmployeeLeaveStatusQuery({});
	const [insertLeaveSubmissionNotes] = useInsertLeaveSubmissionNotesMutation();

	useEffect(() => {
		if (isEmpty(submittedLeave?.employee)) {
			setSubmittedLeaves([]);
			return;
		}

		const response = submittedLeave?.employee
			?.filter((value) => value?.employee_leaving_mappings?.length > 0)
			.map((data) => ({
				...data,
				employee_leaving_mappings: data?.employee_leaving_mappings.map(
					(leave) => {
						return {
							leaveStatus:
								leave?.employee_leave_status?.label === 'Submitted'
									? 'Pending'
									: leave?.employee_leave_status?.label,
							fromDate: leave?.from_date,
							toDate: leave?.to_date,
							leaveCount: leave?.leave_count,
							reason: leave?.reason,
							leaveType: leave?.org_leave_type?.leave_type,
							leaveTypeId: leave?.org_leave_type?.id,
							id: leave?.id,
							userId: data?.user?.id,
							isHalfDay: leave?.is_half_day,
							leaveStatusValue: leave?.employee_leave_status?.value,
							isNegativeBalanceAllowed:
								leave?.org_leave_type?.is_negative_bal_allowed || false,
							note: leave?.employee_leave_rejection_notes[0]?.note || '- -',
						};
					}
				),
			}));
		setSubmittedLeaves(response);
	}, [submittedLeave, leaveSubmittedId, refetch]);

	useEffect(() => {
		if (isEmpty(leaveStatus?.employee_leave_status)) {
			return;
		}
		const statusOption = leaveStatus?.employee_leave_status
			.filter((element) => element?.value !== 'draft')
			.map((value) => {
				if (value?.value === 'submitted') {
					setLeaveStatusId(value?.id);
				}
				return {
					id: value?.id,
					name: value?.value === 'submitted' ? 'Pending' : value?.label,
				};
			});
		const sortedStatusOption = orderBy(statusOption, ['name'], ['asc']);
		setLeaveStatusDropDown(sortedStatusOption);
	}, [leaveStatus]);

	useEffect(() => {
		if (
			!(
				organisationLeaveCalenderYear &&
				organisationLeaveCalenderYear?.organization_by_pk
			)
		) {
			return;
		}
		const leaveCalenderData = organisationLeaveCalenderYear?.organization_by_pk;
		if (!leaveCalenderData) {
			return;
		}
		const calculatedDate = calculateCurrentLeaveCalenderYear({
			leave_calendar_year_start_month:
				leaveCalenderData.leave_calendar_year_start_month,
			leave_calendar_year_start_date:
				leaveCalenderData?.leave_calendar_year_start_date,
			leave_calendar_year_end_date:
				leaveCalenderData?.leave_calendar_year_end_date,
			leave_calendar_year_end_month:
				leaveCalenderData?.leave_calendar_year_end_month,
		});
		if (!calculatedDate) {
			return;
		}
		setCurrentLeaveYear({
			startDate: calculatedDate?.startDate || '',
			endDate: calculatedDate?.endDate || '',
		});
	}, [organisationLeaveCalenderYear]);

	const handleReject = async (notes?: string) => {
		const data = leaveStatus?.employee_leave_status?.find(
			(value) => value?.value === 'rejected'
		);
		const leaveRejectNotesMutationRes = await insertLeaveSubmissionNotes({
			variables: {
				data: uniq(leaveSubmittedId).map((leaveId: any) => ({
					note: notes,
					type: 'rejected',
					leave_submission_id: leaveId,
				})),
			},
		});

		if (leaveRejectNotesMutationRes.errors) {
			notify(FAILED_TO_ADD_REJECTION_NOTES);
			return;
		}
		const rejectLeave = await approveLeaveMutation({
			variables: {
				leaveData: {
					employee_leave_status_id: data?.id,
				},
				leaveId: uniq(leaveSubmittedId),
			},
		});
		if (rejectLeave) {
			setLeaveSubmittedId([]);
			refetchSubmittedLeave();
			setIsApproveRejectModalOpen(false);
			setIsApproveModalOpen(false);
			setIsApproveModalOpen(false);
			refetch();
			notify('Leave Rejected Successfully');
			return;
		}
	};

	const handleApprove = async () => {
		const data = leaveStatus?.employee_leave_status?.find(
			(value) => value?.value === 'approved'
		);
		const leaveApproval = await approveLeaveMutation({
			variables: {
				leaveData: {
					approver_id: userId,
					approved_by: userId,
					employee_leave_status_id: data?.id,
				},
				leaveId: uniq(leaveSubmittedId),
			},
		});
		if (leaveApproval) {
			setLeaveSubmittedId([]);
			refetchSubmittedLeave();
			setIsApproveRejectModalOpen(false);
			setIsApproveModalOpen(false);
			setIsApproveModalOpen(false);
			refetch();
			notify('Leave Approved Successfully');
			return;
		}
	};

	const checkSelectAllOptionHandler = () => {
		const selectedLeaveStatus = leaveStatus?.employee_leave_status?.find(
			(item) => item?.id === leaveStatusId
		);
		return selectedLeaveStatus &&
			(selectedLeaveStatus?.value === 'approved' ||
				selectedLeaveStatus?.value === 'rejected')
			? true
			: false;
	};

	const handleEditLeave = (leaveDetails: EmployeeLeavingMapping) => {
		if (!leaveDetails) {
			return;
		}

		const formValues = {
			from_date: leaveDetails?.fromDate,
			to_date: leaveDetails?.toDate,
			is_half_day: leaveDetails?.isHalfDay,
			leave_type_id: leaveDetails?.leaveTypeId,
			approver_id: leaveDetails?.leaveApproverId,
			reason: leaveDetails?.reason,
			id: leaveDetails?.id,
			leave_count: leaveDetails?.leaveCount,
			leaveBalance: 0,
			isNegativeBalanceAllowed: leaveDetails?.isNegativeBalanceAllowed,
			userId: leaveDetails?.userId || null,
		};
		setSelectedLeaveDetails(formValues);
		setSelectedResourceUserId(formValues?.userId || '');
	};
	const handleDeleteLeave = async () => {
		if (!selectedLeaveIdForDelete) {
			return;
		}
		const deletedLeaveStatus = await deleteAppliedLeaveMutation({
			variables: {
				id: selectedLeaveIdForDelete,
			},
		});
		if (deletedLeaveStatus) {
			if (deletedLeaveStatus?.errors) {
				notify('Leave deletion failed', 'warning');
				return;
			}
			setSelectedLeaveIdForDelete(null);
			refetchSubmittedLeave();
			setIsWarningModalShown(false);
			refetch();
			notify('Leave deleted successfully');
			setLeaveSubmittedId([]);
			return;
		}
	};
	return (
		<div>
			<Box
				display={'flex'}
				justifyContent='space-between'
				alignItems='center'
				mb={3}
			>
				<Box display={'flex'} justifyContent='space-between' width={'30%'}>
					<Box className={inlineCustomStyle.dropdown}>
						<Box
							display='flex'
							flexDirection={'column'}
							alignItems={'flex-start'}
						>
							<Box className={inlineCustomStyle?.label}>Leave Status</Box>
							<Form
								onSubmit={() => {}}
								initialValues={{ leaveStatus: leaveStatusId }}
							>
								{() => (
									<ThemeProvider theme={modalFormTheme}>
										<form>
											<SelectInput
												source={'leaveStatus'}
												label={''}
												choices={
													leaveStatusDropDown && leaveStatusDropDown?.length > 0
														? [
																{
																	id: 'All',
																	name: 'All',
																},
																...leaveStatusDropDown,
														  ]
														: []
												}
												onChange={(event) => {
													setLeaveStatusId(event?.target?.value);
													setLeaveSubmittedId([]);
													setSelectedUserId([]);
												}}
											/>
										</form>
									</ThemeProvider>
								)}
							</Form>
						</Box>
					</Box>
					<Box className={inlineCustomStyle.dropdown}>
						<Box
							display='flex'
							flexDirection={'column'}
							alignItems={'flex-start'}
						>
							<Box className={inlineCustomStyle?.label}>Employee Status</Box>
							<Form
								onSubmit={() => {}}
								initialValues={{ employeeStatus: employeeStatus }}
							>
								{() => (
									<ThemeProvider theme={modalFormTheme}>
										<form>
											<SelectInput
												source={'employeeStatus'}
												label={''}
												choices={userStatusOptions}
												onChange={(event) => {
													setEmployeeStatus(event?.target?.value);
												}}
											/>
										</form>
									</ThemeProvider>
								)}
							</Form>
						</Box>
					</Box>
				</Box>
				<Box display='flex' marginTop='13px'>
					<Button
						variant='contained'
						className={style?.rejectButton}
						disabled={!enableLeave || leaveSubmittedId?.length === 0}
						onClick={() => {
							setIsApproveRejectModalOpen(true);
						}}
						id='button_reject_leave'
					>
						Reject
					</Button>
					<Box marginLeft='20px'>
						<Button
							variant='contained'
							className={inlineCustomStyle.saveButton}
							disabled={!enableLeave || leaveSubmittedId?.length === 0}
							onClick={() => {
								setIsApproveModalOpen(true);
								setIsApproveRejectModalOpen(true);
							}}
							id='button_approve_leave'
						>
							Approve
						</Button>
					</Box>
				</Box>
			</Box>
			{submittedLeaveLoading ? (
				<Box>
					<Loading />
				</Box>
			) : (
				<Box>
					{submittedLeaves && submittedLeaves?.length > 0 ? (
						<div>
							<div>
								{submittedLeaves?.map((user: LeaveProps) => {
									return (
										<div className={style.leavesContainer} key={user?.user?.id}>
											<div className={style.avatarContainer}>
												<Avatar
													className={style.avatar}
													src={`${user.user?.profile_pic || ''}`}
												>
													{user?.user?.first_name?.charAt(0).toUpperCase() ||
														''}
													{user?.user?.last_name?.charAt(0).toUpperCase() || ''}
												</Avatar>
												<Typography className={style.employeeName}>
													{`${user.user?.full_name || ''} `}
												</Typography>
											</div>
											<Table>
												<TableHead>
													<TableRow>
														<CustomTableCell
															className={style.dataActionContainer}
														>
															<Checkbox
																className={style.checkBox}
																name='checkedB'
																color='primary'
																checked={selectedUserId?.includes(
																	user?.user?.id
																)}
																disabled={checkSelectAllOptionHandler()}
																onChange={(e: any) => {
																	const record = user?.employee_leaving_mappings
																		?.filter(
																			(value: any) =>
																				value.leaveStatus === 'Pending'
																		)
																		.map((id: any) => id?.id);
																	if (e.currentTarget.checked) {
																		setSelectedUserId([
																			...selectedUserId,
																			user?.user?.id,
																		]);
																		setLeaveSubmittedId([
																			...leaveSubmittedId,
																			...record,
																		]);
																	} else {
																		setSelectedUserId(
																			selectedUserId.filter(
																				(id: string) => id !== user?.user?.id
																			)
																		);
																		setLeaveSubmittedId(
																			leaveSubmittedId?.filter(
																				(id: string) => !record.includes(id)
																			)
																		);
																	}
																}}
															/>
														</CustomTableCell>
														<CustomTableCell>Leave Type</CustomTableCell>
														<CustomTableCell>Reason</CustomTableCell>
														<CustomTableCell>Date</CustomTableCell>
														<CustomTableCell>Status</CustomTableCell>
														<CustomTableCell>Number Of Days</CustomTableCell>
														<CustomTableCell>Note</CustomTableCell>
														<CustomTableCell></CustomTableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{user?.employee_leaving_mappings?.length > 0 &&
														user?.employee_leaving_mappings?.map(
															(leave: EmployeeLeavingMapping) => {
																return (
																	<TableRow
																		hover={true}
																		key={leave?.id}
																		className={inlineCustomStyle.container}
																	>
																		<CustomTableCell>
																			{leave?.leaveStatus === 'Approved' ? (
																				<CheckCircleIcon
																					className={style.approvedIcon}
																				/>
																			) : (
																				<Checkbox
																					className={style.checkBox}
																					name='checkedB'
																					color='primary'
																					disabled={
																						leave.leaveStatus === 'Rejected' ||
																						leave.leaveStatusValue ===
																							'cancelled'
																							? true
																							: false
																					}
																					checked={leaveSubmittedId?.includes(
																						leave?.id
																					)}
																					onChange={(event: any) => {
																						const record = [
																							...leaveSubmittedId,
																							leave?.id,
																						];
																						if (event?.currentTarget.checked) {
																							const records =
																								user?.employee_leaving_mappings
																									?.filter(
																										(value: any) =>
																											value.leaveStatus ===
																											'Pending'
																									)
																									.map((id: any) => id?.id);
																							setLeaveSubmittedId([
																								...leaveSubmittedId,
																								leave?.id,
																							]);
																							if (
																								records?.length ===
																								record?.length
																							) {
																								setSelectedUserId(
																									user?.user?.id
																								);
																							}
																						} else {
																							setSelectedUserId([]);
																							setLeaveSubmittedId(
																								leaveSubmittedId?.filter(
																									(id: any) => id !== leave?.id
																								)
																							);
																						}
																					}}
																				/>
																			)}
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={`${leave?.leaveType || '- -'}`}
																				placement='right'
																			>
																				<Box
																					className={`${style.leaveTYpe} ${styles.ellipsis}`}
																				>
																					{leave?.leaveType}
																				</Box>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={`${leave?.reason || '- -'}`}
																				placement='right'
																			>
																				<Box
																					className={`${style.reason} ${styles.ellipsis}`}
																				>
																					{leave?.reason}
																				</Box>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			<div className={style.dateContainer}>
																				<DateRangeIcon
																					className={style.calendarIcon}
																				/>
																				{`${dayjs(leave?.fromDate).format(
																					'DD MMM YYYY'
																				)}-${dayjs(leave?.toDate).format(
																					'DD MMM YYYY'
																				)}`}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell>
																			<div
																				className={`${style.statusContainer} ${
																					leave?.leaveStatus === 'Pending'
																						? style.submittedStatus
																						: leave?.leaveStatus === 'Approved'
																						? style.approvedStatus
																						: style?.rejectedStatus
																				}`}
																			>
																				{leave?.leaveStatus}
																			</div>
																		</CustomTableCell>
																		<CustomTableCell>
																			{leave?.leaveCount}
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={`${leave?.note || '- -'}`}
																				placement='right'
																			>
																				<Box
																					className={`${style.leaveTYpe} ${styles.ellipsis}`}
																				>
																					{leave?.note}
																				</Box>
																			</Tooltip>
																		</CustomTableCell>

																		<CustomTableCell>
																			{!isReportingManagerView &&
																			leave?.leaveStatusValue !== 'approved' &&
																			leave?.leaveStatusValue !== 'rejected' &&
																			leave?.leaveStatusValue !==
																				'cancelled' ? (
																				<Box
																					className={
																						inlineCustomStyle.listEditIconContainer
																					}
																				>
																					<Tooltip
																						title={'Edit'}
																						placement='top'
																					>
																						<IconButton
																							className={
																								inlineCustomStyle.listEditIconButton
																							}
																							onClick={() => {
																								setIsEditModalOpen(true);
																								handleEditLeave(leave);
																							}}
																							disabled={!enableLeave}
																						>
																							<EditIcon
																								className={
																									datagridStyles.editIcon
																								}
																								onClick={() => {
																									setIsEditModalOpen(true);
																									handleEditLeave(leave);
																								}}
																							/>
																						</IconButton>
																					</Tooltip>
																					<Tooltip
																						title={'Delete'}
																						placement='top'
																					>
																						<IconButton
																							className={
																								inlineCustomStyle.deleteIconContainer
																							}
																							onClick={() => {
																								setIsWarningModalShown(true);
																								setSelectedLeaveIdForDelete(
																									leave?.id
																								);
																							}}
																							disabled={!enableLeave}
																						>
																							<DeleteIcon
																								className={
																									datagridStyles.deleteIcon
																								}
																								onClick={() => {
																									setIsWarningModalShown(true);
																									setSelectedLeaveIdForDelete(
																										leave?.id
																									);
																								}}
																							/>
																						</IconButton>
																					</Tooltip>
																				</Box>
																			) : !isReportingManagerView &&
																			  leave?.leaveStatusValue ===
																					'approved' ? (
																				<Box
																					className={
																						inlineCustomStyle.listEditIconContainer
																					}
																				>
																					<Tooltip
																						title={'Delete'}
																						placement='top'
																					>
																						<IconButton
																							className={
																								inlineCustomStyle.deleteIconContainer
																							}
																							onClick={() => {
																								setIsWarningModalShown(true);
																								setSelectedLeaveIdForDelete(
																									leave?.id
																								);
																							}}
																							disabled={!enableLeave}
																						>
																							<DeleteIcon
																								className={
																									datagridStyles.deleteIcon
																								}
																								onClick={() => {
																									setIsWarningModalShown(true);
																									setSelectedLeaveIdForDelete(
																										leave?.id
																									);
																								}}
																							/>
																						</IconButton>
																					</Tooltip>
																				</Box>
																			) : (
																				<Box
																					className={
																						inlineCustomStyle.listEditIconContainer
																					}
																				></Box>
																			)}
																		</CustomTableCell>
																	</TableRow>
																);
															}
														)}
												</TableBody>
											</Table>
										</div>
									);
								})}
							</div>
						</div>
					) : (
						<Box textAlign='center'>
							<img src={no_data_found} alt='no_data_found' />
						</Box>
					)}
				</Box>
			)}
			<LeaveApproveRejectModal
				open={isApproveRejectModalOpen}
				onClose={() => {
					setIsApproveRejectModalOpen(false);
					setIsApproveModalOpen(false);
					//setIsApproveModalOpen(false);
				}}
				submit={isApproveModalOpen ? handleApprove : handleReject}
				leaveSubmittedId={leaveSubmittedId}
				message={
					isApproveModalOpen ? APPROVE_LEAVE_MESSAGE : REJECT_LEAVE_MESSAGE
				}
				loader={approveLeaveLoader}
				isApproveModalOpen={isApproveModalOpen || false}
				enableCommentModal={true}
			/>
			<ApplyLeaveForm
				open={isEditModalOpen}
				onClose={() => {
					setIsEditModalOpen(false);
					setSelectedLeaveDetails(null);
				}}
				userId={selectedResourceUserId}
				userLocationId={selectedResourceLocationId}
				currentFinancialYear={currentLeaveYear}
				refetchLeaveBalance={() => refetchSubmittedLeave()}
				editFormValues={selectedLeaveDetails}
				isEdit={true}
				isManagerView={true}
			/>
			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={() => setIsWarningModalShown(false)}
				warningMessage={ENABLE_OVERTIME_CONFIRMATION}
				onSubmit={() => handleDeleteLeave()}
				isLoading={handleDeleteLeaveLoader}
			/>
		</div>
	);
};

export default LeaveApproval;
