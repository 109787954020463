import React, { useState } from 'react';

import {
	Button,
	createTheme,
	Dialog,
	DialogContent,
	ThemeProvider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
	SelectInput,
	TextInput,
	useNotify,
	maxLength,
	CheckboxGroupInput,
	required,
} from 'react-admin';
import { modalFormTheme } from '../../../Layout/Theme.component';

import { Form } from 'react-final-form';
import { formStyle } from '../pms.styles';
import { useGetUserToSendReminderQuery } from '../../../generated/graphql';
import {
	REGEX_FAILED_ERROR_TEXT,
	regexNameValidation,
	BLUE_CIRCULAR_PROGRESS_LOADER,
	SEND_REMINDER_FAILED,
	SEND_REMINDER_SUCCESS,
	SEND_REMINDER_STATUS_OPTIONS,
} from './constant';

import Loader from '../SharedComponents/Loader.component';
import { useParams } from 'react-router-dom';
import { sendPmsReminder } from '../../../Api/restApi.config';
import { uniqBy } from 'lodash';

const checkboxTheme = createTheme({
	overrides: {
		MuiFormLabel: {
			root: {
				display: 'none',
			},
		},
		MuiTypography: {
			body1: {
				fontFamily: 'Manrope-medium',
				fontSize: '13px',
				fontWeight: 400,
				lineHeight: 1.5,
			},
		},
		MuiFormHelperText: {
			root: {
				display: 'none',
			},
		},
	},
});
interface Option {
	id: string;
	name: string;
}
interface FormValues {
	recipients: string[];
	email_body: string;
	status: string;
	subject: string;
}
interface GoalFormProps {
	onClose: () => void;
	open: boolean;
	statusOptions: Option[];
}

function SendReminderForm(props: GoalFormProps) {
	const { open, onClose, statusOptions } = props;
	const { id: appraisalCycleId }: { id: string } = useParams();

	const formStyles = formStyle();
	const notify = useNotify();

	// State
	const [isLoading, setIsLoading] = useState(false);
	const [selectedStatusId, setSelectedStatusId] = useState<string | null>();
	// API
	const { data: userList } = useGetUserToSendReminderQuery({
		variables: {
			where: {
				_and: [
					{
						appraisal_cycle_id: {
							_eq: appraisalCycleId,
						},
					},
					{ user_appraisal_status_id: { _eq: selectedStatusId } },
				],
			},
		},
		fetchPolicy: 'network-only',
		skip: !selectedStatusId,
	});
	const validateName = (value: string) => {
		if (!value) {
			return 'Required';
		}
		if (value.trim() === '') {
			return 'Required';
		}
		if (!regexNameValidation.test(value)) {
			return REGEX_FAILED_ERROR_TEXT;
		}
		return undefined;
	};

	const handleClose = () => {
		setSelectedStatusId(null);
		onClose();
	};
	const handleSubmit = (formValues: FormValues) => {
		if (!formValues) return;
		setIsLoading(true);
		const { subject, email_body, recipients = [] } = formValues;
		const data = {
			subject: subject?.trim() || '',
			body: email_body?.trim() || '',
		};
		const generateRecipients = (type: 'employees' | 'reporting_managers') => {
			return (
				userList?.pms_pms_appraisal_user_mapping?.map((user) => {
					const userData =
						type === 'employees' ? user?.user : user?.userByReviewer;
					return {
						full_name: userData?.full_name || '',
						email: userData?.email || '',
					};
				}) || []
			);
		};
		let recipientsList: { full_name: string; email: string }[] = [];
		if (recipients.includes('employees')) {
			recipientsList = [...recipientsList, ...generateRecipients('employees')];
		}
		if (recipients.includes('reporting_managers')) {
			recipientsList = [
				...recipientsList,
				...generateRecipients('reporting_managers'),
			];
		}
		const emailRecipientList = recipientsList.filter(
			(recipient) => recipient?.email?.trim() !== ''
		);
		const uniqueRecipients = uniqBy(emailRecipientList, 'email');
		sendPmsReminder({ ...data, recipients: uniqueRecipients })
			.then((res) => {
				if (!res?.data?.error) {
					notify(SEND_REMINDER_SUCCESS);
					handleClose();
				} else {
					notify(SEND_REMINDER_FAILED, 'warning');
				}
			})
			.catch(() => {
				notify(SEND_REMINDER_FAILED, 'warning');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={'sm'}
				disableBackdropClick
			>
				<div className={formStyles.container}>
					<div className={formStyles.headerContainer}>
						<p className={formStyles.heading}>Send Reminder</p>
						<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
					</div>
					<Form onSubmit={handleSubmit}>
						{({ handleSubmit, invalid, pristine }) => (
							<>
								<form onSubmit={handleSubmit}>
									<div>
										<DialogContent>
											<p className={formStyles.label}>Status *</p>
											<SelectInput
												source='status'
												label=''
												placeholder='Select status'
												choices={statusOptions}
												validate={required()}
												onChange={(event) => {
													setSelectedStatusId(event?.target?.value);
												}}
											/>
											<p className={formStyles.label}>Send To *</p>
											<ThemeProvider theme={checkboxTheme}>
												<CheckboxGroupInput
													source='recipients'
													choices={SEND_REMINDER_STATUS_OPTIONS}
													validate={required()}
												/>
											</ThemeProvider>
											<p className={formStyles.label}>Email Subject *</p>
											<TextInput
												label=''
												source='subject'
												validate={[validateName, maxLength(250)]}
											/>
											<p className={formStyles.label}>Email Description *</p>
											<TextInput
												source='email_body'
												fullWidth={true}
												label={''}
												multiline
												validate={[validateName]}
											/>
											<div className={formStyles.buttonContainer}>
												<Button
													className={formStyles.cancelButton}
													onClick={onClose}
												>
													Cancel
												</Button>
												<Button
													type='submit'
													disabled={invalid || pristine || isLoading}
													className={
														invalid || pristine || isLoading
															? formStyles.disabledButton
															: formStyles.saveButton
													}
													startIcon={
														isLoading && (
															<Loader color={BLUE_CIRCULAR_PROGRESS_LOADER} />
														)
													}
												>
													Send
												</Button>
											</div>
										</DialogContent>
									</div>
								</form>
							</>
						)}
					</Form>
				</div>
			</Dialog>
		</ThemeProvider>
	);
}

export default SendReminderForm;
