import React, { useState, useEffect, useContext } from 'react';
import {
	List,
	Datagrid,
	TextField,
	FunctionField,
	Loading,
	SelectInput,
	useNotify,
	useRefresh,
	ReferenceField,
} from 'react-admin';
import { Form } from 'react-final-form';
import { range, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { modalFormTheme } from '../../../Layout/Theme.component';

import SettingsDetailContainer from '../SettingsDetailContainer.component';
import { datagridStyle, modalFormStyle } from '../../../Layout/styles';
import {
	Button,
	Box,
	IconButton,
	Typography,
	makeStyles,
	ThemeProvider,
	TextField as TextComponent,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { settingsStyle } from '../settingsStyle';
import HolidayForm from './HolidayForm.component';
import EditIcon from '@material-ui/icons/Edit';
import { SearchIcon } from '../../../assets/SvgIcons';
import {
	useGetOrganiztionHolidayYearQuery,
	useDeleteOrgHolidayMutation,
	useGetLocationsByOrgIdQuery,
	useGetHolidaysForSettingsQuery,
} from '../../../generated/graphql';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import {
	HOLIDAY_EXPORT_COLUMN_WITHOUT_LOCATION,
	HOLIDAY_EXPORT_COLUMN_WITH_LOCATION,
} from './constant';
import { exportToCsv } from '../../../Utils/string.util';
import { UserProfileContext } from '../../../App';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';
import { searchBarTheme } from '../../../Layout/Theme.component';
interface Holiday {
	created_at?: Date;
	date: string;
	id: string;
	is_restricted?: boolean | null;
	name: string;
	org_id: string;
	updated_at?: Date;
	year?: string | null;
	location_id?: string;
}

interface YearProps {
	id: number;
	name: number;
}

const useStyles = makeStyles({
	toolBarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '16px 0px',
	},
	financialYear: {
		fontFamily: 'Manrope-bold',
		fontSize: '14px',
		color: '#292929',
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '90px',
	},
});

interface SearchProps {
	searchText: string;
	onSearch: (searchValue: string) => void;
}

const CustomSearch = (props: SearchProps) => {
	const { searchText, onSearch } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='250px'>
				<TextComponent
					placeholder='Search Holiday'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => onSearch(event?.target?.value)}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

const Holidays = () => {
	const classes = modalFormStyle();
	const styles = settingsStyle();
	const customStyles = useStyles();
	const notify = useNotify();
	const refresh = useRefresh();
	const datagridStyles = datagridStyle();
	const [isHolidayFormshown, setIsHolidayFormshown] = useState(false);
	const [isEditMutation, setIsEditMutation] = useState(false);
	const [yearDropDownOption, setYearDropDownOption] = useState<YearProps[]>();
	const [year, setYear] = useState<number | undefined>();
	const [selectedHoliday, setSelectedHoliday] = useState({});
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [selectedIdForDelete, setSelectedIdForDelete] = useState(null);
	const [searchPrefix, setSearchPrefix] = useState('');
	const [holidayTypeFilter, setHolidayTypeFilter] = useState<string>('All');

	const {
		dateFormat,
		noOfLocations,
		orgId: organizationId,
	} = useContext<any>(UserProfileContext);
	const [organizationHolidayDates, setOrganizationHolidayDates] = useState({
		startDate: '',
		endDate: '',
	});
	const [locationId, setLocationId] = useState<string | null>();
	const [organizationHolidayDate, setOrganizationDate] = useState<
		string[] | undefined
	>([]);
	const {
		data: holidays,
		loading,
		refetch: refetchHolidays,
	} = useGetHolidaysForSettingsQuery({
		variables: {
			filter:
				noOfLocations && noOfLocations > 0
					? {
						date: {
							_gte: organizationHolidayDates?.startDate,
							_lte: organizationHolidayDates?.endDate,
						},
						location_id: !locationId ? {} : { _eq: locationId },
						is_restricted:
							holidayTypeFilter === 'All'
								? {}
								: { _eq: holidayTypeFilter === 'true' ? true : false },
						name: searchPrefix ? { _ilike: `%${searchPrefix}%` } : {},

					}
					: {
						date: {
							_gte: organizationHolidayDates?.startDate,
							_lte: organizationHolidayDates?.endDate,
						},
					},
		},
	});

	const { data: orgLocationList } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
	});
	const { data: holiday } = useGetOrganiztionHolidayYearQuery({
		fetchPolicy: 'network-only',
	});
	const [deleteHoliday] = useDeleteOrgHolidayMutation();

	useEffect(() => {
		const year = dayjs().year();
		setYear(year);
	}, []);

	useEffect(() => {
		if (!year) {
			return;
		}
		const startDate = dayjs(year).startOf('month').format('MM-DD');
		const endDate = dayjs().endOf('year').format('MM-DD');
		setOrganizationHolidayDates({
			startDate: `${year}-${startDate}`,
			endDate: `${year}-${endDate}`,
		});
	}, [year]);

	useEffect(() => {
		if (isEmpty(holidays?.org_holidays)) {
			setOrganizationDate([]);
			return;
		}
		const orgHoliday = holidays?.org_holidays?.map(
			(value: Holiday) => value?.date
		);
		setOrganizationDate(orgHoliday);
	}, [holidays]);

	useEffect(() => {
		if (
			!holiday ||
			!holiday?.org_holidays_aggregate ||
			!holiday?.org_holidays_aggregate?.aggregate
		) {
			return;
		}
		const aggregate = holiday?.org_holidays_aggregate?.aggregate;
		const maxDate = dayjs(aggregate?.max?.date).year() + 1;
		const minDate = dayjs(aggregate?.min?.date).year();
		const yearOption = range(minDate, maxDate).map((value: number) => {
			return {
				id: value,
				name: value,
			};
		});
		setYearDropDownOption(yearOption);
	}, [holiday]);

	const handleOrgHolidayDelete = () => {
		if (!selectedIdForDelete) {
			return;
		}
		deleteHoliday({
			variables: {
				orgHolidayId: selectedIdForDelete,
			},
		})
			.then((response) => {
				if (!response.errors) {
					refresh();
					setSelectedIdForDelete(null);
					setIsWarningModalShown(false);
					notify('Holiday has been deleted successfully');
				}
			})
			.catch((error) => {
				if (error) {
					return;
				}
			});
	};

	const generateCSV = () => {
		if (holidays?.org_holidays?.length === 0) {
			return;
		}

		const holidayRow: any = holidays?.org_holidays?.map(
			(exportHoliday: any) => {
				return noOfLocations && noOfLocations > 0
					? [
						exportHoliday?.name || '--',
						exportHoliday?.date
							? dayjs(exportHoliday?.date).format(dateFormat)
							: '--',
						exportHoliday?.org_location?.name || '--',
						exportHoliday?.is_restricted ? 'Yes' : '--',
						exportHoliday?.year || '--',
						exportHoliday?.description || '--',
					]
					: [
						exportHoliday?.name || '--',
						exportHoliday?.date
							? dayjs(exportHoliday?.date).format(dateFormat)
							: '--',
						exportHoliday?.is_restricted ? 'Yes' : '--',
						exportHoliday?.year || '--',
						exportHoliday?.description || '--',
					];
			}
		);

		const holidayExportData = [
			noOfLocations && noOfLocations > 0
				? HOLIDAY_EXPORT_COLUMN_WITH_LOCATION
				: HOLIDAY_EXPORT_COLUMN_WITHOUT_LOCATION,
			...holidayRow,
		];
		exportToCsv('HolidayList.csv', holidayExportData);
	};

	const onHandleEditHoliday = (holidayId: string) => {
		if (isEmpty(holidays?.org_holidays)) {
			return;
		}
		const holiday = holidays?.org_holidays.find(
			(holiday: Holiday) => holiday.id === holidayId
		);
		if (holiday) {
			setSelectedHoliday(holiday);
			setIsEditMutation(true);
			setIsHolidayFormshown(true);
		}
	};

	return (
		<>
			<SettingsDetailContainer heading='Holidays'>
				<Box className={styles.content}>
					<Box className={customStyles.toolBarContainer}>
						<Typography className={customStyles.financialYear}>
							<Form
								onSubmit={() => {}}
								initialValues={{ location_id: locationId, name: year  }}
							>
								{() => (
									<ThemeProvider theme={modalFormTheme}>
										<form>
											<Box display='flex'>
											<Box display='block' width='250px'>
													<Box className={classes.label}>Search</Box>

													<CustomSearch
														searchText={searchPrefix}
														onSearch={(value: string) => setSearchPrefix(value)}
													/>
												</Box>
												<Box display='block' marginLeft='5px'>
													<Box className={classes.label}>Choose Year</Box>
													<SelectInput
														source='name'
														label=''
														choices={yearDropDownOption || []}
														defaultValue={year}
														onChange={(event: any) => {
															setYear(event?.target?.value);
														}}
													/>
												</Box>
												{noOfLocations && noOfLocations > 0 && (
													<Box marginLeft='5px'>
														<Box className={classes.label}>Choose location</Box>
														<SelectInput
															resettable
															fullWidth
															source='location_id'
															label={''}
															choices={orgLocationList?.org_location || []}
															onChange={(event: any) => {
																if (!event) {
																	setLocationId(null);
																	return;
																} else {
																	setLocationId(event?.target?.value);
																	return;
																}
															}}
														/>
													</Box>
												)}
												
												<Box display='block' marginLeft='5px'>
													<Box className={classes.label}>Holiday type</Box>
													<Box>
														<Form
															initialValues={{
																holiday_type: holidayTypeFilter,
															}}
															onSubmit={() => {}}
														>
															{() => (
																<form>
																	<ThemeProvider theme={modalFormTheme}>
																		<SelectInput
																			source='holiday_type'
																			label=''
																			choices={[
																				{ id: 'All', name: 'All' },
																				{
																					id: 'true',
																					name: 'Optional Holiday',
																				},
																				{
																					id: 'false',
																					name: 'Regular Holiday',
																				},
																			]}
																			onChange={(e: any) => {
																				setHolidayTypeFilter(e?.target?.value);
																			}}
																		/>
																	</ThemeProvider>
																</form>
															)}
														</Form>
													</Box>
												</Box>
											</Box>
										</form>
									</ThemeProvider>
								)}
							</Form>
						</Typography>

						<Box display={'flex'}>
							<Box marginRight={'10px'}>
								<ExportButtonWithLoader
									generateCSV={generateCSV}
									style={classes.exportButton}
									isDisabled={!holidays?.org_holidays?.length}
								/>
							</Box>
							<Button
								variant='contained'
								className={classes.saveButton}
								onClick={() => {
									setIsHolidayFormshown(true);
								}}
							>
								Add Holiday
							</Button>
						</Box>
					</Box>
					{loading ? (
						<Loading />
					) : (
						<List
							resource='org_holidays'
							basePath='/org_holidays'
							bulkActionButtons={false}
							pagination={false}
							actions={false}
							sort={{ field: 'date', order: 'ASC' }}
							filter={
								!locationId
									? {
										date: organizationHolidayDate,
										name: searchPrefix,
										is_restricted:
											holidayTypeFilter === 'All'
												? undefined
												: holidayTypeFilter === 'true'
													? true
													: false,
									}
									: {
										date: organizationHolidayDate,
										location_id: locationId,
										name: searchPrefix,
										is_restricted:
											holidayTypeFilter === 'All'
												? undefined
												: holidayTypeFilter === 'true'
													? true
													: false,
									}
							}
							title={' '}
							perPage={100}
						>
							<Datagrid rowClick={''}>
								<TextField source='name' label='NAME' />
								<FunctionField
									source='date'
									label='DATE'
									render={(holiday: any) =>
										dayjs(holiday.date).format(dateFormat)
									}
								/>
								<FunctionField
									source='day'
									label='DAY'
									sortable={false}
									render={(holiday: any) => dayjs(holiday.date).format('ddd')}
								/>
								{noOfLocations && noOfLocations > 0 && (
									<FunctionField
										label='LOCATION'
										render={(holiday: any) => {
											return holiday?.location_id ? (
												<ReferenceField
													source='location_id'
													label='LOCATION'
													reference='org_location'
													link={false}
												>
													<FunctionField
														render={(location: any) => {
															return (
																<Typography className={customStyles.title}>
																	{location?.name ? location?.name : '--'}
																</Typography>
															);
														}}
													/>
												</ReferenceField>
											) : (
												'--'
											);
										}}
									></FunctionField>
								)}

								<FunctionField
									source='is_restricted'
									label='OPTIONAL HOLIDAY'
									render={(holiday: any) =>
										holiday.is_restricted ? `Yes` : `No`
									}
								/>
								<FunctionField
									render={(holiday: any) => (
										<Box display='flex' alignItems='center'>
											<Box
												className={datagridStyles.listEditIconContainer}
												mr={1}
											>
												<IconButton
													className={datagridStyles.listEditIconButton}
													onClick={() => {
														onHandleEditHoliday(holiday?.id);
													}}
												>
													<EditIcon className={datagridStyles.editIcon} />
												</IconButton>
											</Box>
											<Box className={datagridStyles.listEditIconContainer}>
												<IconButton
													className={`${datagridStyles.listEditIconButton} ${customStyles.deleteIconButton}`}
													onClick={() => {
														setSelectedIdForDelete(holiday?.id);
														setIsWarningModalShown(true);
													}}
												>
													<DeleteIcon className={datagridStyles.editIcon} />
												</IconButton>
											</Box>
										</Box>
									)}
								/>
							</Datagrid>
						</List>
					)}
				</Box>
			</SettingsDetailContainer>
			<HolidayForm
				open={isHolidayFormshown}
				onClose={() => {
					setIsHolidayFormshown(false);
					setSelectedHoliday({});
					setIsEditMutation(false);
				}}
				initialValues={selectedHoliday}
				isEditMutation={isEditMutation}
				onSuccess={refetchHolidays}
			/>
			<DeleteModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
					setSelectedIdForDelete(null);
				}}
				confirmationMessage={'Do you really want to delete this holiday?'}
				onDelete={() => {
					handleOrgHolidayDelete();
				}}
			/>
		</>
	);
};

export default Holidays;
