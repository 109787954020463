import React, { useState, useContext } from 'react';
import { useNotify } from 'react-admin';
import {
	Button,
	Box,
	Dialog,
	Typography,
	makeStyles,
	CircularProgress,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Checkbox,
} from '@material-ui/core';
import { UserProfileContext } from '../../App';
import dayjs from 'dayjs';
import { useUpdateProjectAndResourceAllocationEndDateMutation } from '../../generated/graphql';
import {
	RESOURCE_END_DATE_CONFIRMATION_MESSAGE,
	RESOURCE_END_DATE_INFO,
	RESOURCE_END_DATE_WARNING_MESSAGE,
} from './constant';
import { endDateExtensionStyles } from './style';

const useStyles = makeStyles((theme) => ({
	successIcon: {
		color: theme.palette.success.main,
		fontSize: '40px',
		display: 'block',
		margin: '0 auto',
	},
	iconContainer: {
		backgroundColor: '#4CAF50', // Green background
		borderRadius: '50%',
		width: '50px',
		height: '50px',
		margin: '0 auto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dialogContent: {
		textAlign: 'center',
	},
	boldText: {
		fontSize: '14px',
		color: '#000',
	},
	warningText: {
		fontSize: '14px',
		color: '#000',
		marginTop: '10px',
	},
	actions: {
		justifyContent: 'center',
		marginBottom: '10px',
	},
	noteText: {
		color: '#f20808',
		marginTop: theme.spacing(2),
	},
	noteWarningText: {
		color: '#f20808',
	},
}));

interface Props {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	projectIds: string[];
	selectedDate: string | null;
}

const ResourceDateForm = (props: Props) => {
	const { open, onClose, onSuccess, projectIds, selectedDate } = props;
	const { dateFormat } = useContext<any>(UserProfileContext);
	const notify = useNotify();
	const [isChecked, setIsChecked] = useState(false);
	const [updateEndDate, { loading: isEndDateUpdateLoading }] =
		useUpdateProjectAndResourceAllocationEndDateMutation();
	const styles = endDateExtensionStyles();

	const handleClose = () => {
		setIsChecked(false);
		onClose();
	};
	const handleSubmit = () => {
		updateEndDate({
			variables: {
				where: { project: { id: { _in: projectIds } } },
				_set: { end_date: selectedDate || null },
			},
		})
			.then((res) => {
				if (!res.errors) {
					notify('Updated Successfully...');
					handleClose();
					onSuccess();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const classes = useStyles();
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>
				<Box className={classes.iconContainer}>
					<Box className={styles.icon}>✔</Box>
				</Box>
				<Typography variant='h6' align='center'>
					Success!
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<DialogContentText>
					<Typography className={classes.boldText}>
						End date of the <strong>{projectIds?.length || 0}</strong>{' '}
						project(s) changed to{' '}
						<strong>{dayjs(selectedDate).format(dateFormat)}</strong>
					</Typography>
				</DialogContentText>
				<DialogContentText>
					<Typography className={styles.description}>
						{RESOURCE_END_DATE_INFO}
					</Typography>
				</DialogContentText>
				<Typography variant='caption' className={classes.noteWarningText}>
					{RESOURCE_END_DATE_WARNING_MESSAGE}
				</Typography>
				<Box display='flex'>
					<Checkbox
						color='primary'
						checked={isChecked}
						onChange={() => setIsChecked(!isChecked)}
					/>
					<Typography className={classes.warningText}>
						{RESOURCE_END_DATE_CONFIRMATION_MESSAGE}
					</Typography>
				</Box>
			</DialogContent>
			<DialogActions className={classes.actions}>
				<Button onClick={() => onClose()} className={styles.cancelButton}>
					Cancel
				</Button>
				<Button
					className={
						!isChecked || isEndDateUpdateLoading
							? `${styles.disabledButton}`
							: `${styles.saveButton}`
					}
					disabled={!isChecked || isEndDateUpdateLoading}
					startIcon={
						isEndDateUpdateLoading && (
							<CircularProgress
								style={{
									height: '20px',
									width: '20px',
									color: '#4285F4',
								}}
							/>
						)
					}
					onClick={handleSubmit}
				>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ResourceDateForm;
