import React, { useEffect, useState } from 'react';

import { searchBarTheme } from '../../../Layout/Theme.component';
import {
	Box,
	Button,
	Tooltip,
	ThemeProvider,
	TextField as TextComponent,
	makeStyles,
	IconButton,
} from '@material-ui/core';
import { useNotify } from 'react-admin';

import EditIcon from '@material-ui/icons/Edit';

import { SearchIcon } from '../../../assets/SvgIcons';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { modalFormStyle } from '../../../Layout/styles';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { DataGridTheme } from '../pmsTheme';
import GoalForm from './GoalForm.component';
import {
	useGetMasterGoalsQuery,
	useDeletePmsMasterGoalMutation,
	useGetMasterGoalsForExportQuery,
} from '../../../generated/graphql';
import Loader from '../SharedComponents/Loader.component';
import { pmsDatagridStyle } from '../pms.styles';
import { GoalFormInitialValues, GoalType } from '../pms.module';
import {
	preventSubsequentClick,
	exportToCsv,
} from '../../../Utils/string.util';
import {
	GOAL_DELETE_ALERT_MSG,
	GOAL_DELETE_SUCCESS_MSG,
	GOAL_EXPORT_COLUMNS,
	GOAL_UNABLE_TO_DELETE_MSG,
	WHITE_CIRCULAR_PROGRESS_LOADER,
} from './constant';
import { isEmpty } from 'lodash';
import DeleteModal from '../../../SharedComponents/DeleteModal.component';
import WarningModal from '../../../SharedComponents/WarningModal.component';

const dataGridStyle = makeStyles(() => ({
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		minWidth: '60px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		color: 'grey',
		backgroundColor: 'lightgrey',
	},
	text: {
		fontFamily: 'Manrope-medium',
		fontSize: '11px',
		cursor: 'pointer',
		fontWeight: 400,
	},
	actionButtonWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '12px',
	},
}));

const CustomSearchRender = (props: any) => {
	const { searchText, setSearchedText } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Goals'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => {
						setSearchedText(event?.target?.value);
					}}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

function OrgGoalList() {
	const formStyles = modalFormStyle();
	const dataGridStyles = dataGridStyle();
	const listStyles = pmsDatagridStyle();
	const notify = useNotify();

	// STATE
	const [searchedText, setSearchedText] = useState<string>('');
	const [isGoalFormShown, setIsGoalFormShown] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [page, setPage] = useState<number>(0);
	const [data, setData] = useState<GoalType[]>([]);
	const [row, setRow] = useState<GoalFormInitialValues>({});
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isGoalMappedToUser, setIsGoalMappedToUser] = useState<boolean>(false);
	const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);
	const [isWarningModalShown, setIsWarningModalShown] =
		useState<boolean>(false);
	const [isDeleteDialogModalShown, setIsDeleteDialogModalShown] =
		useState<boolean>(false);
	const [goalId, setGoalId] = useState<string | null>(null);
	const [sortFilter, setSortFilter] = useState<any[]>([{ name: 'asc' }]);

	// API
	const {
		data: goals,
		refetch: refetchGoals,
		loading: isGoalLoading,
	} = useGetMasterGoalsQuery({
		variables: {
			searchText: `%${searchedText}%`,
			limit: rowsPerPage,
			offset: rowsPerPage * page,
			order: sortFilter,
		},
		fetchPolicy: 'network-only',
	});

	const { data: goalsListForExport, refetch: refetchGoalsForExport } =
		useGetMasterGoalsForExportQuery({
			variables: {
				searchText: `%${searchedText}%`,
				order: sortFilter,
			},
			fetchPolicy: 'network-only',
		});

	// Mutation
	const [deleteGoal, { loading: isDeleteOperationLoading }] =
		useDeletePmsMasterGoalMutation();

	// Handlers
	const checkGoalMappingWithUser = (
		selectedGoalId: string,
		mappedUserCount: number
	) => {
		if (mappedUserCount) {
			setIsDeleteDialogModalShown(true);
			return;
		}
		setGoalId(selectedGoalId);
		setIsWarningModalShown(true);
	};

	const handleDeleteGoal = () => {
		deleteGoal({
			variables: {
				goalId,
			},
		})
			.then((data: any) => {
				notify(GOAL_DELETE_SUCCESS_MSG, 'warning');
				setGoalId(null);
				setIsWarningModalShown(false);
				refetchGoals();
				refetchGoalsForExport();
			})
			.catch((error: any) => {
				notify(`${error.message}`, 'warning');
				setGoalId(null);
				setIsWarningModalShown(false);
			});
	};
	useEffect(() => {
		if (!goals) return;
		const data = goals.pms_pms_master_goal.map((goal) => ({
			id: goal.id,
			name: goal.name,
			category: goal.pms_goal_category?.name || '- -',
			description: goal.description,
			is_active: goal.is_active,
			mappedUserCount:
				goal?.pms_appraisal_goal_mappings_aggregate?.aggregate?.count || 0,
			action: {
				id: goal.id,
				name: goal.name,
				goal_category_id: goal.pms_goal_category?.id,
				description: goal.description,
				is_active: goal.is_active,
				mappedUserCount:
					goal?.pms_appraisal_goal_mappings_aggregate?.aggregate?.count || 0,
			},
		}));
		setData(data);
	}, [goals]);

	const columns = [
		{
			name: 'name',
			label: 'GOAL',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'is_active',
			label: 'IS ACTIVE',
			options: {
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const isActiveStatus = value ? 'Yes' : 'No';
					return (
						<Tooltip title={isActiveStatus}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{isActiveStatus}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'category',
			label: 'CATEGORY',
			options: {
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'description',
			label: 'DESCRIPTION',
			options: {
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Tooltip title={value}>
							<Box className={`${dataGridStyles.text} ${formStyles.ellipsis}`}>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'action',
			label: 'ACTION',
			options: {
				viewColumns: false,
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const { id: selectedGoalId, mappedUserCount, ...restValues } = value;
					return (
						<Box className={dataGridStyles.actionButtonWrapper}>
							<Box className={listStyles.listEditIconContainer}>
								<IconButton
									className={listStyles.listEditIconButton}
									onClick={() => {
										setRow({
											id: selectedGoalId,
											...restValues,
										});
										setIsEdit(true);
										setIsGoalFormShown(true);
										setIsGoalMappedToUser(!!mappedUserCount);
									}}
								>
									<EditIcon className={listStyles.editIcon} />
								</IconButton>
							</Box>
							<Box className={listStyles.listEditIconContainer}>
								<IconButton
									className={`${listStyles.listEditIconButton} ${listStyles.deleteIconButton}`}
									onClick={() => {
										checkGoalMappingWithUser(selectedGoalId, mappedUserCount);
									}}
								>
									<DeleteIcon className={listStyles.editIcon} />
								</IconButton>
							</Box>
						</Box>
					);
				},
			},
		},
	];

	const handleExport = () => {
		if (!goalsListForExport) {
			return;
		}
		const goalsData =
			goalsListForExport?.pms_pms_master_goal?.map((goal) => {
				return [
					goal?.name || '--',
					goal?.is_active ? 'Yes' : 'No',
					goal?.pms_goal_category?.name || '--',
					goal?.description || '--',
				];
			}) || [];

		const exportData = [GOAL_EXPORT_COLUMNS, ...[...(goalsData || [])]];
		exportToCsv('Goal.csv', exportData);
	};
	const disableButton = () => {
		setButtonDisabled(false);
	};
	const preventClick = preventSubsequentClick(disableButton, 1000);
	const exportWithLoader = () => {
		if (!isButtonDisabled) {
			setButtonDisabled(true);
			handleExport();
			preventClick();
		}
	};

	const options: MUIDataTableOptions = {
		filter: false,
		print: false,
		rowsPerPage: rowsPerPage,
		download: false,
		page: page,
		serverSide: true,
		rowsPerPageOptions: [5, 10, 20, 30],
		search: false,
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectableRowsHeader: false,
		textLabels: {
			body: {
				noMatch:isGoalLoading?(
					<Loader/>
				):(
				 'No data found'
				)
			},
		},
		count: goals?.pms_pms_master_goal_aggregate.aggregate?.count || 0,
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
		},
		onTableChange: (action: string, tableState: any) => {
			tableState.data.length === 0 ? setPage(0) : setPage(tableState.page);
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				default:
					return;
			}
		},
		onColumnSortChange: (changedColumn: string, direction: string) => {
			switch (changedColumn) {
				case 'name': {
					setSortFilter([{ name: direction }]);
					break;
				}

				default: {
					setSortFilter([]);
				}
			}
		},
	};

	return (
		<div>
			<div>
				<div>
					<Box
						display='flex'
						justifyContent='space-between'
						marginBottom='20px'
						marginTop='20px'
					>
						<Box display='flex' alignItems='center' sx={{ gridGap: '8px' }}>
							<Box>
								<CustomSearchRender
									searchText={searchedText}
									setSearchedText={setSearchedText}
								/>
							</Box>
							{isGoalLoading && (
								<Box>
									<Loader />
								</Box>
							)}
						</Box>
						<Box display='flex' sx={{ gridGap: '8px' }}>
							<Box>
								<Button
									className={
										isEmpty(data) || isButtonDisabled
											? dataGridStyles.disabledButton
											: dataGridStyles.exportButton
									}
									endIcon={<ArrowUpwardIcon />}
									onClick={exportWithLoader}
									disabled={isEmpty(data) || isButtonDisabled}
									startIcon={
										isButtonDisabled && (
											<Loader color={WHITE_CIRCULAR_PROGRESS_LOADER} />
										)
									}
								>
									Export
								</Button>
							</Box>
							<Box>
								<Button
									variant='contained'
									color='primary'
									className={formStyles.saveButton}
									startIcon={<AddIcon />}
									onClick={() => {
										setIsEdit(false);
										setIsGoalFormShown(true);
									}}
								>
									Create Goal
								</Button>
							</Box>
						</Box>
					</Box>
					<div>
						<ThemeProvider theme={DataGridTheme}>
							<MUIDataTable
								title='Goals'
								data={data}
								columns={columns}
								options={options}
							/>
						</ThemeProvider>
						<GoalForm
							open={isGoalFormShown}
							onClose={() => setIsGoalFormShown(false)}
							onSuccess={() => {
								refetchGoals();
								refetchGoalsForExport();
								setIsGoalFormShown(false);
							}}
							initialValues={isEdit ? row : null}
							hasMappedUser={isEdit ? isGoalMappedToUser : false}
							goalCategoryOptions={
								goals?.goalCategories.map((category) => ({
									id: category.id,
									name: category.name,
								})) || []
							}
						/>

						<DeleteModal
							open={isWarningModalShown}
							onClose={() => {
								setGoalId(null);
								setIsWarningModalShown(false);
							}}
							confirmationMessage={GOAL_DELETE_ALERT_MSG}
							onDelete={() => {
								handleDeleteGoal();
							}}
							isLoading={isDeleteOperationLoading}
							loaderColor={WHITE_CIRCULAR_PROGRESS_LOADER}
						/>
						<WarningModal
							open={isDeleteDialogModalShown}
							onClose={() => {
								setIsDeleteDialogModalShown(false);
							}}
							warningMessage={GOAL_UNABLE_TO_DELETE_MSG}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrgGoalList;
