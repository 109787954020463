import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	createTheme,
	makeStyles,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { BooleanInput, useNotify, useRefresh } from 'react-admin';
import { settingsStyle } from '../settingsStyle';
import { UserProfileContext } from '../../../App';

import WarningConfirmationModal from '../../../SharedComponents/WarningConfirmationModal.component';

import {
  ATTENDANCE_LABEL_MESSAGE,
	ENABLE_EMPID_LENGTH_SETTING_CONFIRMATION,
} from '../constant';
import {
  useGetAttendanceLogSettingsQuery,
	useUpdateAttendanceLogSettingsMutation,
} from '../../../generated/graphql';


const customStyle = makeStyles({
	label: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	description: {
		fontSize: '10px',
		fontFamily: 'Manrope-medium',
		color: '#6C6C6C',
		marginTop: '-20px',
	},
	container: {
		marginTop: '-10px',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
		marginTop: '5px',
	},

	editIcon: {
		width: '12px',
		height: '12px',
	},
});

const editableColumnsTheme = createTheme({
	overrides: {
		MuiTypography: {
			body1: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4',
		},
	},
});

const AttendanceLogSettings = () => {
	const { orgId: organizationId } = useContext<any>(UserProfileContext);
	const customStyles = customStyle();
	const settingsStyles = settingsStyle();
	const notify = useNotify();
	const refresh = useRefresh();
	const [isWarningModalShown, setIsWarningModalShown] =
		useState<boolean>(false);
	const [isSettingEnabledState, setIsSettingEnabled] = useState<boolean>(false);

	const [
		enableAttendanceRegularizationSettings,
		{ loading: enableEmployeeIdLengthLoading },
	] = useUpdateAttendanceLogSettingsMutation();

	const { data: attendanceRegularizationSettings } =
		useGetAttendanceLogSettingsQuery({
			variables: {
				organizationId,
			},
      fetchPolicy:'network-only'
		});

	useEffect(() => {
		if (!attendanceRegularizationSettings) {
			return;
		}

		setIsSettingEnabled(
			attendanceRegularizationSettings?.organization_by_pk
				?.enable_checkin_checkout || false
		);
	}, [attendanceRegularizationSettings]);

	const handleSave = () => {
		enableAttendanceRegularizationSettings({
			variables: {
				orgId: organizationId,
				data: {
					enable_checkin_checkout: isSettingEnabledState,
				},
			},
		})
			.then((res) => {
				if (!res.errors) {
					refresh();
					notify('Updated successfully');
					setIsWarningModalShown(false);
				}
			})
			.catch((err) => {
				notify('Updation failed', 'error');
			});
	};
	const handleChange = (value: boolean) => {
		setIsSettingEnabled(value);
		setIsWarningModalShown(true);
	};

	const onWarningModalClose = () => {
		setIsWarningModalShown((prevIsWarningModalShown) => {
			if (prevIsWarningModalShown) {
				setIsSettingEnabled(!isSettingEnabledState);
			}
			return false;
		});
	};

	return (
		<>
			<Form
				onSubmit={handleSave}
				initialValues={{
					enable_checkin_checkout: isSettingEnabledState,
				}}
			>
				{({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<ThemeProvider theme={editableColumnsTheme}>
							<div className={customStyles.container}>
								<Box
									className={settingsStyles.inputContainer}
									display='flex'
									flexDirection='column'
								>
									<>
										<div className={settingsStyles.inputContainer}>
											<BooleanInput
												label='Enable Attendance Check-in/Check-out'
												source='enable_checkin_checkout'
												onChange={(value) => handleChange(value)}
											/>
										</div>
										<Typography className={customStyles.description}>
											{ATTENDANCE_LABEL_MESSAGE}
										</Typography>
									</>
								</Box>
							</div>
						</ThemeProvider>
					</form>
				)}
			</Form>
			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={onWarningModalClose}
				warningMessage={ENABLE_EMPID_LENGTH_SETTING_CONFIRMATION}
				onSubmit={() => {
					handleSave();
				}}
				isLoading={enableEmployeeIdLengthLoading}
			/>
		</>
	);
};

export default AttendanceLogSettings;
