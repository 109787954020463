import React, { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import dayjs from 'dayjs';
import { Attendance } from './Attendance.model';
import { Style } from './Style';
import { UserProfileContext } from '../../App';
import {
	useGetAttendanceLogSettingsQuery,
	useGetEmployeeJoinDateByUserIdQuery,
	useUpsertAttendanceMutation,
} from '../../generated/graphql';
import { head } from 'lodash';

interface Props {
	refetchAttendance: () => void;
	refetchWorkHours: () => void;
	todaysAttendance: Attendance;
}

const CheckIn = (props: Props) => {
	const { refetchAttendance, refetchWorkHours, todaysAttendance } = props;
	const {
		permissions: permissionList,
		id: userId,
		orgId,
	} = useContext<any>(UserProfileContext);
	const { data: user, loading: joinDateLoader } =
		useGetEmployeeJoinDateByUserIdQuery({
			variables: { user_id: userId },
			fetchPolicy: 'network-only',
		});

	const { data: attendanceLogSettings } = useGetAttendanceLogSettingsQuery({
		variables: {
			organizationId: orgId,
		},
		fetchPolicy: 'network-only',
	});
	const userJoinDate =
		head(user?.user)?.user_type === 'employee'
			? head(user?.user)?.employee?.join_date || null
			: head(head(user?.user)?.contractors)?.join_date || null;
	const isCheckInCheckOutEnabled =
		attendanceLogSettings?.organization_by_pk?.enable_checkin_checkout || false;
	const currentDate = dayjs().format('YYYY-MM-DD');
	const disableCheckIn = currentDate < userJoinDate;
	const classes = Style();
	const [todaysWorkedHour, setTodaysWorkedHour] = useState('0');
	const [time, setTime] = useState(dayjs().format('HH:mm'));
	const [location, setLocation] = useState('');
	const [upsertAttendance] = useUpsertAttendanceMutation();

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(getPosition);
		const intervel = setInterval(() => {
			setTime(dayjs().format('HH:mm'));
		}, 60 * 100);
		return () => clearInterval(intervel);
	}, []);

	useEffect(() => {
		if (todaysAttendance?.id) {
			const inTime = dayjs(
				`${todaysAttendance?.date} ${todaysAttendance?.in_time}`
			);
			const outTime = dayjs(
				`${todaysAttendance?.date} ${todaysAttendance?.out_time}`
			);
			const diff = dayjs.duration(outTime.diff(inTime)).format('HH:mm');
			setTodaysWorkedHour(diff);
		}
	}, [todaysAttendance]);

	const getPosition = (position: GeolocationPosition) => {
		const latitude = position.coords.latitude;
		const longitude = position.coords.longitude;
		const location = `${latitude},${longitude}`;
		setLocation(location);
	};

	const onSave = (attendance: Attendance) => {
		if (permissionList?.appPermissions?.ui?.editOthersAttendance) {
			upsertAttendance({
				variables: {
					attendance: {
						user_id: userId,
						...attendance,
						location: location || null,
					},
				},
			}).then((data) => {
				refetchAttendance();
				refetchWorkHours();
			});
		} else {
			upsertAttendance({
				variables: {
					attendance: {
						...attendance,
						location: location || null,
						user_id: userId,
					},
				},
			}).then((data) => {
				refetchAttendance();
				refetchWorkHours();
			});
		}
	};

	const handleCheckout = () => {
		const checkOut = {
			...todaysAttendance,
			out_time: dayjs().format('HH:mm:ssZ'),
		};
		onSave(checkOut);
	};

	const handleCheckIn = () => {
		const checkIn = {
			in_time: dayjs().format('HH:mm:ssZ'),
			date: dayjs().format('YYYY-MM-DD'),
		};
		onSave(checkIn);
	};

	return (
		<Box>
			<Box className={classes.additionalInfoLabel} marginLeft={'20px'}>
				Mark today’s attendance
			</Box>
			<Box className={classes.attendanceDetails}>
				<Box display='flex' justifyContent={'normal'}>
					<Box className={classes.todaysDate}>
						{dayjs().format('DD MMMM,YYYY')}
					</Box>
					<Box className={classes.todaysDay} marginLeft={'8px'}>
						{dayjs().format('dddd')}
					</Box>
				</Box>
				<Box className={classes.addCheckButtonContainer} width='129px'>
					<Button
						className={
							joinDateLoader || disableCheckIn || !isCheckInCheckOutEnabled
								? classes.disableButton
								: !todaysAttendance?.in_time
								? classes.addCheckButton
								: classes.addCheckoutButton
						}
						onClick={handleCheckIn}
						disabled={
							joinDateLoader ||
							disableCheckIn ||
							!!todaysAttendance?.in_time ||
							!isCheckInCheckOutEnabled
						}
						id='button_check_in'
					>
						{!todaysAttendance?.in_time ? (
							`Check In ${time}`
						) : (
							<Box className={classes.checkedin}>
								<Box color='#34A853'>Checked In</Box>
								<Box color='#292929' marginLeft='5px'>
									{todaysAttendance?.in_time
										? dayjs(
												`${todaysAttendance?.date} ${todaysAttendance?.in_time}`
										  ).format('HH:mm')
										: time}
								</Box>
							</Box>
						)}
					</Button>
					<Button
						className={
							todaysAttendance?.out_time
								? classes.checkoutOut
								: todaysAttendance?.in_time
								? classes.addCheckButton
								: classes.addCheckoutButton
						}
						onClick={handleCheckout}
						id='button_check_out'
						disabled={
							!todaysAttendance?.in_time ||
							!!todaysAttendance?.out_time ||
							!isCheckInCheckOutEnabled
						}
					>
						{todaysAttendance?.out_time ? (
							<Box className={classes.checkedin}>
								<Box color='#888888'>Checked Out</Box>
								<Box color='#292929' marginLeft='5px'>
									{todaysAttendance?.out_time
										? dayjs(
												`${todaysAttendance?.date} ${todaysAttendance?.out_time}`
										  ).format('HH:mm')
										: time}
								</Box>
							</Box>
						) : todaysAttendance?.in_time ? (
							<Box>{`Check Out ${time}`}</Box>
						) : (
							<Box>Checkout</Box>
						)}
					</Button>
				</Box>
			</Box>
			{todaysAttendance?.out_time ? (
				<Box
					display='flex'
					justifyContent={'flex-end'}
					marginRight={'70px'}
					marginBottom={'10px'}
				>
					<Box className={classes.additionalInfoLabel}>
						Hours Worked : {todaysWorkedHour} hours
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default CheckIn;
