import React, { useState, useEffect, useContext } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
	createStyles,
	ThemeProvider,
	Box,
	IconButton,
	Tooltip,
	Avatar,
	Typography,
	makeStyles,
	TablePagination,
} from '@material-ui/core';
import { SelectInput, useNotify } from 'react-admin';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Form } from 'react-final-form';

import { head, isEmpty, orderBy, range } from 'lodash';
import {
	useDeleteAppliedLeaveMutation,
	useEmployeeLeaveStatusQuery,
	useGetEmployeeDetailsByUserIdQuery,
	useGetEmployeeLeaveQuery,
	useGetEmployeeJoiningDateQuery,
	useGetLeaveSettingsSubscription,
	useGetOrganisationLeaveCalenderYearQuery,
	useApproveLeaveMutation,
	useInsertLeaveSubmissionNotesMutation,
} from '../../generated/graphql';
import { modalFormTheme } from '../../Layout/Theme.component';
import { DropdownOption } from '../../SharedComponents/model';
import {
	LeaveProps,
	EmployeeLeavingMapping,
	ApplyLeaveFormValues,
	SelectedMonthDates,
} from './Attendance.model';
import DateRangeIcon from '@material-ui/icons/DateRange';
import dayjs from 'dayjs';
import { Style } from './Style';
import { datagridStyle, ellipsisStyle } from '../../Layout/styles';
import no_data_found from '../../assets/no_data_found.png';
import { UserProfileContext } from '../../App';
import { calculateCurrentFinancialYear } from '../../Reports/Constant';
import ApplyLeaveForm from './ApplyLeaveForm.component';
import WarningConfirmationModal from '../../SharedComponents/WarningConfirmationModal.component';
import { ENABLE_OVERTIME_CONFIRMATION } from '../Settings/constant';
import ConfirmationModal from '../../SharedComponents/ConfirmationModal.component';
import NotesCapture from '../../SharedComponents/NotesCapture.component';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

export const inlineStyle = makeStyles(() => ({
	dropDown: {
		background: '#ffffff',
		marginLeft: '36px',
	},
	container: {
		marginTop: '20px',
		'&:hover': {
			'& $listEditIconButton': {
				display: 'flex',
			},
			'& $deleteIconContainer': {
				display: 'flex',
			},
		},
	},
	label: {
		font: 'normal normal bold 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '10px !important',
	},
	listEditIconContainer: {
		display: 'flex',
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '4px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	deleteIconContainer: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		marginRight: '4px',
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
}));

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '12px',
			padding: '12px',
		},
	})
)(TableCell);

interface Props {
	userId: string;
	userLocationId: string | null | undefined;
	isReportingManagerView?: boolean | undefined;
	isUserView: boolean;
}

const MyLeaves = (props: Props) => {
	const {
		userId,
		userLocationId,
		isReportingManagerView,
		isUserView = false,
	} = props;

	const [hasOneFinancialYear, setHasOneFinancialYear] = useState(false);
	const [selectFinancialYear, setSelectFinancialYear] = useState<string>('');
	const [financialYearOption, setFinancialYearOption] =
		useState<DropdownOption[]>();
	const [leaveStatusOption, setLeaveStatusOption] =
		useState<DropdownOption[]>();
	const { orgId } = useContext<any>(UserProfileContext);
	const [leaveStatusId, setLeaveStatusId] = useState<string>();
	const [employeeLeaves, setEmployeeLeaves] = useState<LeaveProps[]>();
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedLeaveIdForDelete, setSelectedLeaveIdForDelete] = useState<
		string | null
	>();
	const [selectedLeaveDetails, setSelectedLeaveDetails] =
		useState<ApplyLeaveFormValues | null>();
	const [currentLeaveYear, setCurrentLeaveYear] = useState<SelectedMonthDates>({
		startDate: '',
		endDate: '',
	});
	const [
		isLeaveCancelConfirmationModalShown,
		setIsLeaveCancelConfirmationModalShown,
	] = useState(false);
	const [isCancelLeaveNotesCaptureShown, setIsCancelLeaveNotesCaptureShown] =
		useState(false);

	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);

	const notify = useNotify();
	const classes = Style();
	const style = inlineStyle();
	const styles = ellipsisStyle();
	const datagridStyles = datagridStyle();

	const { data: leaveSettings } = useGetLeaveSettingsSubscription({
		variables: {
			orgId,
		},
		fetchPolicy: 'network-only',
	});

	const enableLeave = leaveSettings?.organization_by_pk?.enable_leave ?? false;

	const { data: organisationLeaveCalenderYear } =
		useGetOrganisationLeaveCalenderYearQuery({
			variables: { organisationId: orgId },
			fetchPolicy: 'network-only',
		});

	const { data: employee } = useGetEmployeeDetailsByUserIdQuery({
		variables: { userId: userId },
		skip: !userId,
	});

	const [cancelLeaveRequest, { loading: isCancelLeaveRequestLoading }] =
		useApproveLeaveMutation();

	const [
		insertCancelReasonNotes,
		{ loading: isInsertCancelReasonNotesLoading },
	] = useInsertLeaveSubmissionNotesMutation();

	const [deleteAppliedLeaveMutation] = useDeleteAppliedLeaveMutation();

	const { data: employeeLeaveStatus } = useEmployeeLeaveStatusQuery();

	const { data: employeeLeave, refetch: refetchLeaveList } =
		useGetEmployeeLeaveQuery({
			variables: {
				userId,
				financialStartDate: currentLeaveYear?.startDate
					? `${dayjs(currentLeaveYear?.startDate)
							.year(Number(selectFinancialYear))
							.format('YYYY-MM-DD')}`
					: '',
				financialEndDate: currentLeaveYear?.endDate
					? `${dayjs(currentLeaveYear?.endDate)
							.year(Number(selectFinancialYear) + Number(!hasOneFinancialYear))
							.format('YYYY-MM-DD')}`
					: '',

				employeeLeaveStatusId:
					leaveStatusId === 'All' ? {} : { _eq: leaveStatusId },
				employeeId: head(employee?.employee)?.id,
			},
			fetchPolicy: 'network-only',
			skip: leaveStatusId === null || !userId,
		});

	const { data: employeeJoiningYear, refetch: refetchJoiningYear } =
		useGetEmployeeJoiningDateQuery({
			variables: { userId: head(employee?.employee)?.id },
			fetchPolicy: 'network-only',
		});

	const handleEditApplyLeaveForm = () => {
		setIsEditModalOpen(false);
		setSelectedLeaveDetails(null);
	};

	useEffect(() => {
		if (!employeeJoiningYear?.employee_leaving_mapping_aggregate) {
			return;
		}
		const empFirstAppliedLeave = dayjs(
			employeeJoiningYear?.employee_leaving_mapping_aggregate?.aggregate?.min
				?.from_date
		);
		const empLastAppliedLeave = dayjs(
			employeeJoiningYear?.employee_leaving_mapping_aggregate?.aggregate?.max
				?.to_date
		);

		const currentFinancialYearData =
			organisationLeaveCalenderYear?.organization_by_pk;

		const startOfCurrentFinancialYear = dayjs(
			`${dayjs(empLastAppliedLeave).year()}-${
				Number(currentFinancialYearData?.leave_calendar_year_start_month) + 1
			}-${Number(currentFinancialYearData?.leave_calendar_year_start_date)}`
		);
		const startDateOfCurrentFinancialYear = dayjs(
			`${dayjs(empFirstAppliedLeave).year()}-${
				Number(currentFinancialYearData?.leave_calendar_year_start_month) + 1
			}-${Number(currentFinancialYearData?.leave_calendar_year_start_date)}`
		);

		const showPreviousFinancialYear = empFirstAppliedLeave?.isBefore(
			startDateOfCurrentFinancialYear
		);

		const showNextFinancialYear = empLastAppliedLeave?.isAfter(
			startOfCurrentFinancialYear?.subtract(1, 'day')
		);

		const empCurrentYear = dayjs(empLastAppliedLeave).year();

		const empJoiningYear =
			!hasOneFinancialYear && showPreviousFinancialYear
				? empFirstAppliedLeave?.year() - 1
				: empFirstAppliedLeave?.year();

		const generateFinancialYears = (
			firstYear: number | undefined,
			lastYear: number | undefined
		) => {
			if (!firstYear || !lastYear) {
				return;
			}
			const allYears = range(firstYear, lastYear + 1);
			const financialYears = allYears.map((year) => {
				return {
					id: `${year}`,
					name: hasOneFinancialYear ? `${year}` : `${year}-${year + 1}`,
				};
			});

			return hasOneFinancialYear
				? financialYears
				: showNextFinancialYear
				? financialYears
				: financialYears?.slice(0, -1);
		};

		const sortedYears = orderBy(
			generateFinancialYears(empJoiningYear, empCurrentYear),
			['id'],
			['desc']
		);
		setFinancialYearOption(sortedYears);
	}, [
		employeeJoiningYear,
		setFinancialYearOption,
		hasOneFinancialYear,
		organisationLeaveCalenderYear,
	]);

	useEffect(() => {
		if (
			!(
				organisationLeaveCalenderYear &&
				organisationLeaveCalenderYear?.organization_by_pk
			)
		) {
			return;
		}
		const financialData = organisationLeaveCalenderYear?.organization_by_pk;
		if (!financialData) {
			return;
		}
		const calculatedDate = calculateCurrentFinancialYear({
			financial_year_start_month:
				financialData?.leave_calendar_year_start_month,
			financial_year_start_date: financialData?.leave_calendar_year_start_date,
			financial_year_end_date: financialData?.leave_calendar_year_end_date,
			financial_year_end_month: financialData?.leave_calendar_year_end_month,
		});
		if (!calculatedDate) {
			return;
		}
		setCurrentLeaveYear({
			startDate: calculatedDate?.startDate || '',
			endDate: calculatedDate?.endDate || '',
		});
		setHasOneFinancialYear(
			dayjs(calculatedDate?.startDate).year() ===
				dayjs(calculatedDate?.endDate).year()
		);
		setSelectFinancialYear(dayjs(calculatedDate?.startDate).year().toString());
	}, [organisationLeaveCalenderYear]);

	useEffect(() => {
		if (isEmpty(employeeLeaveStatus?.employee_leave_status)) {
			return;
		}
		const response = employeeLeaveStatus?.employee_leave_status
			.filter((element) => element?.value !== 'draft')
			?.map((value) => {
				if (value?.value === 'submitted') {
					setLeaveStatusId(value?.id);
				}
				return {
					id: value?.id,
					name: value?.value === 'submitted' ? 'Pending' : value?.label,
				};
			});
		const sortedResponse = orderBy(response, ['name'], ['asc']);
		setLeaveStatusOption(sortedResponse);
	}, [employeeLeaveStatus]);

	useEffect(() => {
		if (
			!(
				organisationLeaveCalenderYear &&
				organisationLeaveCalenderYear?.organization_by_pk
			)
		) {
			return;
		}
		const financialData = organisationLeaveCalenderYear?.organization_by_pk;
		if (!financialData) {
			return;
		}
		const calculatedDate = calculateCurrentFinancialYear({
			financial_year_start_month:
				financialData?.leave_calendar_year_start_month,
			financial_year_start_date: financialData?.leave_calendar_year_start_date,
			financial_year_end_date: financialData?.leave_calendar_year_end_date,
			financial_year_end_month: financialData?.leave_calendar_year_end_month,
		});
		if (!calculatedDate) {
			return;
		}
		setCurrentLeaveYear({
			startDate: calculatedDate?.startDate || '',
			endDate: calculatedDate?.endDate || '',
		});
	}, [organisationLeaveCalenderYear]);

	useEffect(() => {
		if (isEmpty(employeeLeave?.employee)) {
			return;
		}
		const response = employeeLeave?.employee
			?.filter((value) => value?.employee_leaving_mappings?.length > 0)
			.map((data) => ({
				...data,
				employee_leaving_mappings: data?.employee_leaving_mappings.map(
					(leave) => {
						return {
							leaveStatus:
								leave?.employee_leave_status?.label === 'Submitted'
									? 'Pending'
									: leave?.employee_leave_status?.label,
							fromDate: leave?.from_date,
							toDate: leave?.to_date,
							leaveCount: leave?.leave_count,
							reason: leave?.reason,
							leaveType: leave?.org_leave_type?.leave_type,
							id: leave?.id,
							userId: leave?.employee_id,
							leaveTypeId: leave?.org_leave_type?.id,
							isHalfDay: leave?.is_half_day,
							leaveStatusValue: leave?.employee_leave_status?.value,
							note: leave?.employee_leave_rejection_notes[0]?.note || '- -',
						};
					}
				),
			}));
		setEmployeeLeaves(response);
	}, [employeeLeave]);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const getLeaveCount = (typeId: string | null) => {
		if (!typeId) {
			return {
				balance: 0,
				isNegativeBalanceAllowed: false,
			};
		}
		const leave = head(employeeLeave?.leaveBalanceData)?.org_leave_types?.find(
			(leaveType) => leaveType?.id === typeId
		);
		if (!leave) {
			return {
				balance: 0,
				isNegativeBalanceAllowed: false,
			};
		}
		const accruedCount =
			leave?.accrued_leave_type?.length > 0
				? leave?.accrued_leave_type?.reduce((sum, number) => {
						return number?.count + sum;
				  }, 0)
				: 0;
		const employeeLeaveTypeCount =
			leave?.employee_leave_type_mappings?.length > 0
				? head(leave?.employee_leave_type_mappings)?.opening_balance
				: 0;
		const appliedLeaveCount = leave?.employee_leaving_mappings?.reduce(
			(sum, number) => {
				return number?.leave_count + sum;
			},
			0
		);
		const balance =
			accruedCount + employeeLeaveTypeCount - Number(appliedLeaveCount) || 0;

		const leaveBalance = {
			balance: balance || 0,
			isNegativeBalanceAllowed: leave?.is_negative_bal_allowed || false,
		};
		return leaveBalance;
	};
	const handleEditLeave = (leaveDetails: EmployeeLeavingMapping) => {
		if (!leaveDetails) {
			return;
		}
		const leaveBalanceData:
			| {
					balance: number;
					isNegativeBalanceAllowed: boolean;
			  }
			| undefined = getLeaveCount(leaveDetails?.leaveTypeId || null);
		const formValues = {
			from_date: leaveDetails?.fromDate,
			to_date: leaveDetails?.toDate,
			is_half_day: leaveDetails?.isHalfDay,
			leave_type_id: leaveDetails?.leaveTypeId,
			approver_id: leaveDetails?.leaveApproverId,
			reason: leaveDetails?.reason,
			id: leaveDetails?.id,
			leave_count: leaveDetails?.leaveCount,
			leaveBalance: leaveBalanceData?.balance || 0,
			isNegativeBalanceAllowed:
				leaveBalanceData?.isNegativeBalanceAllowed || false,
		};
		setSelectedLeaveDetails(formValues);
	};
	const handleDeleteLeave = async () => {
		if (!selectedLeaveIdForDelete) {
			return;
		}
		const deletedLeaveStatus = await deleteAppliedLeaveMutation({
			variables: {
				id: selectedLeaveIdForDelete,
			},
		});
		if (deletedLeaveStatus) {
			if (deletedLeaveStatus?.errors) {
				notify('Leave cancellation failed', 'warning');
				return;
			}
			setSelectedLeaveIdForDelete(null);
			refetchJoiningYear();
			refetchLeaveList();
			setIsWarningModalShown(false);
			notify('Leave deleted successfully');
			return;
		}
	};
	const handleCancelLeaveRequest = async (notes?: string) => {
		const data = employeeLeaveStatus?.employee_leave_status?.find(
			(value) => value?.value === 'cancelled'
		);
		const leaveRejectNotesMutationRes = await insertCancelReasonNotes({
			variables: {
				data: [
					{
						note: notes,
						type: 'cancelled',
						leave_submission_id: selectedLeaveIdForDelete,
					},
				],
			},
		});

		if (leaveRejectNotesMutationRes.errors) {
			notify('Failed');
			return;
		}
		const cancelledLeave = await cancelLeaveRequest({
			variables: {
				leaveData: {
					employee_leave_status_id: data?.id,
				},
				leaveId: selectedLeaveIdForDelete,
			},
		});
		if (cancelledLeave) {
			setIsCancelLeaveNotesCaptureShown(false);
			setSelectedLeaveIdForDelete(null);
			refetchLeaveList();
			setIsLeaveCancelConfirmationModalShown(false);
			notify('Leave Cancelled');
			return;
		}
	};

	return (
		<>
			<Box
				display={'flex'}
				justifyContent={'space-between'}
				alignItems={'center'}
				mb={3}
			>
				<Box className={style?.dropDown}>
					<Box
						display={'flex'}
						flexDirection={'column'}
						alignItems={'flex-start'}
					>
						<Box className={style?.label}> Leave Status </Box>
						<Form
							onSubmit={() => {}}
							initialValues={{ leavestatus: leaveStatusId }}
						>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<SelectInput
											source={'leavestatus'}
											label={''}
											choices={
												leaveStatusOption && leaveStatusOption?.length > 0
													? [
															{
																id: 'All',
																name: 'All',
															},
															...leaveStatusOption,
													  ]
													: []
											}
											onChange={(event) => {
												setLeaveStatusId(event?.target?.value);
												setPage(0);
											}}
										/>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>
				<Box className={style?.dropDown}>
					<Box
						display={'flex'}
						flexDirection={'column'}
						alignItems={'flex-start'}
					>
						<Box className={style?.label}> Financial Year </Box>
						<Form
							onSubmit={() => {}}
							initialValues={{ selectFinancialYear: selectFinancialYear }}
						>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<SelectInput
											source={'selectFinancialYear'}
											label={''}
											choices={
												financialYearOption && financialYearOption?.length > 0
													? [...financialYearOption]
													: []
											}
											onChange={(event) => {
												setSelectFinancialYear(event?.target?.value);
												setPage(0);
											}}
										/>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>
			</Box>

			{employeeLeaves && employeeLeaves?.length > 0 ? (
				<Box marginTop={'10px'} marginLeft={'15px'}>
					<div>
						{employeeLeaves?.map((user: LeaveProps) => {
							return (
								<div
									className={classes.leavesContainer}
									key={user?.user?.id}
									style={{ marginLeft: '13px', marginTop: '5px' }}
								>
									<div className={classes.avatarContainer}>
										<Avatar
											className={classes.avatar}
											src={`${user?.user?.profile_pic || ''}`}
										>
											{user?.user?.first_name?.charAt(0).toUpperCase() || ''}
											{user?.user?.last_name?.charAt(0).toUpperCase() || ''}
										</Avatar>
										<Typography className={classes.employeeName}>
											{`${user?.user?.full_name || ''}`}
										</Typography>
									</div>
									<Table>
										<TableHead>
											<TableRow>
												<CustomTableCell>Reason</CustomTableCell>
												<CustomTableCell>Date</CustomTableCell>
												<CustomTableCell>Status</CustomTableCell>
												<CustomTableCell>Number Of Days</CustomTableCell>
												<CustomTableCell>Leave Type</CustomTableCell>
												<CustomTableCell>Note</CustomTableCell>
												<CustomTableCell></CustomTableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{user?.employee_leaving_mappings?.length > 0 &&
												user?.employee_leaving_mappings
													?.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
													)
													.map((leave: EmployeeLeavingMapping) => {
														return (
															<TableRow
																hover={true}
																key={leave?.id}
																className={style.container}
															>
																<CustomTableCell>
																	<Tooltip
																		title={`${leave?.reason || '- -'}`}
																		placement='right'
																	>
																		<Box
																			className={`${classes.reason} ${styles.ellipsis}`}
																		>
																			{leave?.reason}
																		</Box>
																	</Tooltip>
																</CustomTableCell>
																<CustomTableCell>
																	<div className={classes.dateContainer}>
																		<DateRangeIcon
																			className={classes.calendarIcon}
																		/>
																		{`${dayjs(leave?.fromDate).format(
																			'DD MMM YYYY'
																		)}-${dayjs(leave?.toDate).format(
																			'DD MMM YYYY'
																		)}`}
																	</div>
																</CustomTableCell>
																<CustomTableCell>
																	<div
																		className={`${classes.statusContainer} ${
																			leave?.leaveStatus === 'Pending'
																				? classes.submittedStatus
																				: leave?.leaveStatus === 'Approved'
																				? classes.approvedStatus
																				: classes?.rejectedStatus
																		}`}
																	>
																		{leave?.leaveStatus}
																	</div>
																</CustomTableCell>
																<CustomTableCell>
																	{leave?.leaveCount}
																</CustomTableCell>
																<CustomTableCell>
																	<Tooltip
																		title={`${leave?.leaveType || '- -'}`}
																		placement='right'
																	>
																		<Box
																			className={`${classes.leaveTYpe} ${styles.ellipsis}`}
																		>
																			{leave?.leaveType}
																		</Box>
																	</Tooltip>
																</CustomTableCell>
																<CustomTableCell>
																	<Tooltip
																		title={`${leave?.note || '- -'}`}
																		placement='right'
																	>
																		<Box
																			className={`${classes.leaveTYpe} ${styles.ellipsis}`}
																		>
																			{leave?.note || '- -'}
																		</Box>
																	</Tooltip>
																</CustomTableCell>
																<CustomTableCell>
																	{!isReportingManagerView &&
																	leave?.leaveStatusValue === 'submitted' ? (
																		<Box
																			className={style.listEditIconContainer}
																		>
																			{!isUserView ? (
																				<>
																					<IconButton
																						className={style.listEditIconButton}
																						onClick={() => {
																							setIsEditModalOpen(true);
																							handleEditLeave(leave);
																						}}
																						disabled={!enableLeave}
																					>
																						<EditIcon
																							className={
																								datagridStyles.editIcon
																							}
																							onClick={() => {
																								setIsEditModalOpen(true);
																								handleEditLeave(leave);
																							}}
																						/>
																					</IconButton>

																					<IconButton
																						className={
																							style.deleteIconContainer
																						}
																						onClick={() => {
																							setIsWarningModalShown(true);
																							setSelectedLeaveIdForDelete(
																								leave?.id
																							);
																						}}
																						disabled={!enableLeave}
																					>
																						<DeleteIcon
																							className={
																								datagridStyles.deleteIcon
																							}
																							onClick={() => {
																								setIsWarningModalShown(true);
																								setSelectedLeaveIdForDelete(
																									leave?.id
																								);
																							}}
																						/>
																					</IconButton>
																				</>
																			) : (
																				<>
																					<IconButton
																						className={style.listEditIconButton}
																						onClick={() => {
																							setIsEditModalOpen(true);
																							handleEditLeave(leave);
																						}}
																						disabled={!enableLeave}
																					>
																						<EditIcon
																							className={
																								datagridStyles.editIcon
																							}
																							onClick={() => {
																								setIsEditModalOpen(true);
																								handleEditLeave(leave);
																							}}
																						/>
																					</IconButton>

																					<IconButton
																						className={
																							style.deleteIconContainer
																						}
																						onClick={() => {
																							setIsLeaveCancelConfirmationModalShown(
																								true
																							);
																							setSelectedLeaveIdForDelete(
																								leave?.id
																							);
																						}}
																						disabled={!enableLeave}
																					>
																						<CancelOutlinedIcon />
																					</IconButton>
																				</>
																			)}
																		</Box>
																	) : (
																		<Box
																			className={style.listEditIconContainer}
																		></Box>
																	)}
																</CustomTableCell>
															</TableRow>
														);
													})}
										</TableBody>
									</Table>
									<Box display='flex' justifyContent='flex-end'>
										<TablePagination
											rowsPerPageOptions={[5, 10, 25]}
											count={user?.employee_leaving_mappings?.length || 0}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</Box>
								</div>
							);
						})}
					</div>
				</Box>
			) : (
				<Box textAlign='center'>
					<img src={no_data_found} alt='no_data_found' />
				</Box>
			)}
			<ApplyLeaveForm
				open={isEditModalOpen}
				onClose={() => {
					handleEditApplyLeaveForm();
				}}
				userId={userId}
				userLocationId={userLocationId}
				currentFinancialYear={currentLeaveYear}
				refetchLeaveBalance={() => {
					refetchJoiningYear();
					refetchLeaveList();
				}}
				editFormValues={selectedLeaveDetails}
				isEdit={isEditModalOpen}
			/>
			<WarningConfirmationModal
				open={isWarningModalShown}
				onClose={() => setIsWarningModalShown(false)}
				warningMessage={ENABLE_OVERTIME_CONFIRMATION}
				onSubmit={() => handleDeleteLeave()}
			/>
			<ConfirmationModal
				open={isLeaveCancelConfirmationModalShown}
				onClose={() => {
					setIsLeaveCancelConfirmationModalShown(false);
				}}
				confirmationMessage={'This leave request will be cancelled'}
				confirmationContext='Cancel Leave Request'
				cancelButtonLabel='Not now'
				submitButtonLabel='Cancel Leave'
				isWarningConfirmation={true}
				onSubmit={() => {
					if (!selectedLeaveIdForDelete) {
						return;
					}
					setIsCancelLeaveNotesCaptureShown(true);
				}}
				loading={isCancelLeaveRequestLoading}
				size='sm'
			/>
			<NotesCapture
				open={isCancelLeaveNotesCaptureShown}
				onClose={() => {
					setIsCancelLeaveNotesCaptureShown(false);
				}}
				onSubmit={(value) => {
					handleCancelLeaveRequest(value?.notes || '');
				}}
				okButtonLabel={'Save'}
				isLoading={isInsertCancelReasonNotesLoading}
			/>
		</>
	);
};

export default MyLeaves;
